import moment from 'moment';
import React, { useContext, useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon ,Modal } from "semantic-ui-react";
import { toLocalTime } from '../../components/Helpers';
import { PrepModal as AcceptButton } from "../../components/PrepModal";
import { GroceryItemUpdateModal } from '../../components/GroceryItemUpdateModal';
import PRing from '../../components/PRing';
import { status } from '../../constants/orderStatus';
import paymentMethods from '../../constants/paymentMethods';
import { delivery, pickUp } from '../../constants/serviceType';
import { Context as orderManagementContext } from '../../context/orderManagement';
import LoadingOverlay from '../LoadingOverlay';
import WebOrderDetails from '../WebOrderDetails';
import PrintOrder from '../PrintOrderView';
import './index.css';
import { KATCH_RIDER_BLACK } from '../../assets/images';

const {
    pending,
    accepted,
    declined,
    ready,
    completed,
    incomplete,
    enRoute,
    changeToMashkor,
} = status;

const buttonTexts = {
    [accepted]: 'accept',
    [declined]: 'decline',
    [ready]: 'ready',
    [enRoute]: 'enRoute',
    [completed]: 'completed',
    [incomplete]: 'incomplete',
    [changeToMashkor]: 'changeToMashkor'
}

const orderButtons = {
    [pickUp]: {
        [pending]: [accepted, declined],
        [accepted]: [ready, completed, incomplete],
        [ready]: [completed, incomplete]
    },
    [delivery]: {
        [pending]: [accepted, declined],
        [accepted]: {
            self: {
                [accepted]: [ready, enRoute, completed, changeToMashkor],
                [enRoute]: [completed],
                [ready]: [enRoute, completed]
            },
            mashkor: {
                [accepted]: [ready, enRoute, completed],
                [enRoute]: [completed],
                [ready]: [enRoute, completed]
            }
        },
    }
}

const WebOrderRow = (props) => {
    const isMounted = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const { actions, multipleOutlet } = useContext(orderManagementContext);
    const [eta, setEta] = useState(null);
    const { t } = useTranslation(['common', 'modal', 'orderDetails']);

    useEffect(() => {
        isMounted.current = true;
        return () => isMounted.current = false;
    },[]);

    const {
        order,
        activeDropDown,
        setActiveDropDown,
        index,
    } = props;

    const onClick = async (event) => {
        try{
            setIsLoading(true);
            await actions.onChangeOrderStatus(event);
            if(isMounted.current){
                setIsLoading(false);
            }
        } catch {
            setIsLoading(false);
        }
    }

    const renderButtons = (buttons, orderType) => buttons && buttons.map(button => {
        if (button === accepted && !order.groceryOnly) {
            return <AcceptButton
                key={button}
                name={button}
                orderId={order._id}
                orderNum={order.orderNumber}
                onChangeOrderStatus={onClick}
                showDeliveryOptions={orderType === delivery}
                deliveryVendor={order.vendor}
                availableVendors={order.availableVendors}
            />
        }

        if(button === ready && order.groceryOnly === true){
          return <GroceryItemUpdateModal order={order} onClick={onClick}/>
        }

        const buttonText = orderType === delivery && order.vendor === 'mashkor' && button === enRoute ? "Picked Up" : buttonTexts[button];

        if (orderType === delivery && order.vendor === 'mashkor' && (button === enRoute || button === completed)) {
            return (
              <Modal
                size="tiny"
                key={button}
                trigger={<Button>{t(buttonText)}</Button>}
                header="Alert!"
                content="The delivery is handled by Katch, are you sure you want to change the status manually ?"
                actions={[
                  {
                    key: "no",
                    content: "No",
                  },
                  {
                    key: "yes",
                    content: "Yes",
                    positive: true,
                    onClick: () =>
                      onClick({
                        target: {
                          name: button,
                          dataset: {
                            orderId: order._id,
                            orderNum: order.orderNumber,
                          },
                        },
                      }),
                  },
                ]}
              />
            );
          } else if (button === changeToMashkor) {
              if(!order.availableVendors.includes('mashkor')) return null;
              return (
                <ShowConfirmDialog
                  header={t("modal:katchSwitch.title")}
                  content={t("modal:katchSwitch.body")}
                  button={button}
                  buttonText={t(buttonText)}
                  onClick={() =>
                    onClick({
                      target: {
                        name: button,
                        dataset: {
                          orderId: order._id,
                          orderNum: order.orderNumber,
                        },
                      },
                    })
                  }
                />
              );
        } else {
            return (
              <Button
                key={button}
                name={button}
                data-order-id={order._id}
                data-orde-num={order.orderNumber}
                onClick={onClick}
              >
                {t(buttonText)}
              </Button>
            );
          }
    });

    const renderButtonsPickUpButton = (orderType, orderStatus) => {
        if (orderType !== pickUp) return null;
        return renderButtons(orderButtons[orderType][orderStatus], orderType);
    }

    const renderButtonsDeliveryButton = (orderType, orderStatus, vendor) => {
        if (orderType !== delivery) return null;

        let buttonsList;

        if (orderStatus === pending) {
            buttonsList = orderButtons[orderType][orderStatus];
        } else {
            buttonsList = orderButtons[orderType][accepted][vendor][orderStatus];
        }

        return renderButtons(buttonsList, orderType);
    }

    const onExpand = (event) => {
        const tagName = event.target.tagName;
        if(tagName === 'BUTTON') return;
        if(index === activeDropDown){
            setActiveDropDown(-1);
        } else {
            setActiveDropDown(index);
        }
    }

    const isCashPayment = order.paymentMethod === paymentMethods.CASH;
    const isVendorCollectable = order.deliveryVendor !== 'TOOKAN';
    const orderDate = toLocalTime(order.timeStamp, true);
    const orderDateFormated = moment(orderDate).local().format('HH:mm');
    const didAccpetOrder = order.orderStatus === status.accepted;

    const orderDate2 = moment(parseFloat(order.timeStamp)).format('DD-MM-YYYY');

    let deliveryDate;
    if (!order?.asapDelivery) {
      if (order?.preferredDeliverySlot?.day === 'today') {
        deliveryDate = orderDate2;
      } else {
        const nextDay = moment(parseFloat(order.timeStamp)).add(1, 'day');
        deliveryDate = nextDay.format('DD-MM-YYYY');
      }
    }

    return (<li className="web-order-table-row">
        <LoadingOverlay active={isLoading}>
        <div className="web-order-row-container">
            <div className="row-order-info" onClick={onExpand}>
                <div className="row-order-info-1">
                    <p className={`row-order-number ${order.orderStatus === status.pending ? "flash" : eta === 0 && didAccpetOrder? "flash-alert":""}`}><Icon name={activeDropDown === index? "chevron circle up":"chevron circle down"} size='large'/>   {t('orderNo')}: {order.orderNumber}</p>
                    {order.groceryOnly ? <span className="row-order-user-name" style={{color: 'red'}}>{t('orderDetails:grocery')}</span> : null}
                    <span className="row-order-user-name">{t('customerName')}: <p>{order.userInfo.name}</p></span>
                    <span className="row-order-user-number">{t('phoneNo')}:<p>{order.userInfo.phoneNumber?.replace(/\+965(.+)/, '$1')}</p></span>
                    <span className="row-order-time">{t('time')}: <p>{orderDateFormated}</p></span>
                    {multipleOutlet && <span className="store-name">{order.storeName}</span>}
                    {didAccpetOrder && <span className="timer-container">
                        <PRing orderEta={order.timeStampEta} eta={eta} setEta={setEta}/>
                    </span>}
                </div>
                <div className="row-order-info-2">
                    <span className="row-order-type">{t('type')}: <p>{order.orderType}</p></span>
                    {
                        order.orderType === 'Delivery' && order.orderStatus !== 'Pending' && <span className="row-order-type capitalize">{t('deliveryVendor')}: <p>{order.vendor === 'mashkor' ? 'Katch' : order.vendor} - {order.deliveryVendor}</p></span>
                    }
                    {order.paymentMethod && (
                      <span> {t('paymentMethod')}: {" "}<p>{order.paymentMethod}</p></span>
                    )}
                    <span className="row-order-payment">{t('orderValue')}: <p>{order.total} KD</p></span>
                    <span className="row-order-type">{t('orderStatus')}: <p>{order.orderStatus}</p></span>
                    {order.orderType && <span className="row-order-type">{t('orderType')}: <p>{order.orderType.toUpperCase()}</p></span>}
                    {order.walletdec && <span className="row-order-type">{t('walletdec')}: <p>{order.walletdec}</p></span>}
                    {isCashPayment && didAccpetOrder && parseFloat(order.total) !== 0 && isVendorCollectable && <span className="row-cash-tag">{t('collectCash')}</span>}
                </div>
            </div>
            {order?.groceryOnly ? <div className="row-order-info-2">
              {order?.preferredDeliverySlot?.startTime || order?.asapDelivery ? <span className="row-order-payment">{t('deliveryTime')}: <p>{order?.asapDelivery ? "ASAP" : `${order?.preferredDeliverySlot?.startTime} - ${order?.preferredDeliverySlot?.endTime}`}</p></span> : null}
              {!order?.asapDelivery ? <span className="row-order-payment">{t('deliveryDate')}: <p>{deliveryDate}</p></span> : null}
            </div> : null}
            <div className="row-order-buttons">
                <PrintOrder order={order} />
                {renderButtonsPickUpButton(order.orderType, order.orderStatus)}
                {renderButtonsDeliveryButton(order.orderType, order.orderStatus, order.vendor)}
            </div>
        </div>
        {activeDropDown === index && <WebOrderDetails order={order} />}
         </LoadingOverlay>
    </li>);
}

const ShowConfirmDialog = (props) => {
    const {button, content, header, onClick} = props;

    return (
      <Modal
        size="tiny"
        key={button}
        trigger={
          <Button className="katch-rider-button">
            <span className="katch-rider-text">Use</span>
            <img
              src={KATCH_RIDER_BLACK}
              alt={"Katch Rider"}
              className="katch-rider-image"
            />
          </Button>
        }
        header={header}
        content={content}
        actions={[
          {
            key: "no",
            content: "No",
          },
          {
            key: "yes",
            content: "Yes",
            positive: true,
            onClick: onClick,
          },
        ]}
      />
    );
}

export default WebOrderRow;