// @ts-nocheck
import React, { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/react-hooks";
import { GET_ORDERS_TIMESTAMP } from "../../components/GraphQL";
import { Accordion, Grid, Icon,Header } from "semantic-ui-react";
import { Dimmer } from "semantic-ui-react";
import { OrderTableDesktop } from "./components";
import { store } from "react-notifications-component";
import ProgressLoading from "../../components/Loading/ProgressLoading";
import {useTranslation} from 'react-i18next';

const OrdersPage = ({activeIndex,setActiveIndex,history}) => {
  const [getOrders, { loading, data, error }] = useLazyQuery(
    GET_ORDERS_TIMESTAMP
  );
  const [tableData, setTableData] = useState([]);

  const handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  };

  useEffect(() => {
    getOrders({
      variables: {
        status: [
          "Completed",
          "Incomplete",
          "Declined",
          "Cancelled",
          "Refunded",
        ],
      },
    });
  }, [getOrders]);

  //Process Data
  useEffect(() => {
    if (data) {
      const { getSellerOrderDates } = data;
      if (getSellerOrderDates) {
        setTableData(getSellerOrderDates);
      }
    }
  }, [data]);

  //Process Error
  useEffect(() => {
    if (error) {
      store.addNotification({
        title: "error",
        message: `${error}`,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
        },
      });
    }
  }, [error]);
  const {t} = useTranslation(['orders'])

  if (loading) {
    return (
      <Dimmer active inverted>
        <ProgressLoading />
      </Dimmer>
    );
  }

  return (
    <Grid container style={{ marginTop: "2em" }}>
      <Grid.Row>
        <Header as='h4' dividing>
          {t('orderhistory')}
        </Header>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <Accordion fluid styled>
            {tableData.map(({ _id }, index) => {
              return (
                <React.Fragment key={_id}>
                  <Accordion.Title
                    active={activeIndex === index}
                    index={index}
                    onClick={handleClick}
                  >
                    <Icon name="dropdown" />
                    {_id}
                  </Accordion.Title>
                  <Accordion.Content active={activeIndex === index}>
                    <OrderTableDesktop
                      active={activeIndex === index}
                      date={_id}
                      history={history}
                    />
                  </Accordion.Content>
                </React.Fragment>
              );
            })}
          </Accordion>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default OrdersPage;
