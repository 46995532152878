export const status = {
  pending: "Pending",
  accepted: "Accepted",
  declined: "Declined",
  ready: "Ready",
  completed: "Completed",
  incomplete: "Incomplete",
  cancelled: "Cancelled",
  refunded: "Refunded",
  enRoute: 'EnRoute',
  pickedUp: 'PickedUp',
  changeToMashkor: 'Change To Mashkor'
};
