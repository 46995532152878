export const HOME = "/";
export const SIGN_UP = "/signup";
export const SIGN_IN = "/signin";
export const ORDER_HISTORY = "/orders";
export const ORDER_DETAILS = "/orders/:orderID";
export const STORE_MENU = "/menu";
export const STATS = "/stats";
export const TRACK_EDITS = "/TrackEdits";
export const REQUEST_DRIVER = '/requestDriver';
export const REQUEST_DRIVER_v2 = '/requestDriverv2';
export const KMARTORDERS = "/kmart/orders";
export const KMARTITEMS = "/kmart/items";
export const PRODUCT_MANAGEMENT = "/productManagement";

