import { status as constantOrderStatus } from "../../constants/orderStatus";
import constantPaymentMethods from "../../constants/paymentMethods";

const {
  pending,
  accepted,
  declined,
  ready,
  completed,
  incomplete,
  cancelled,
  refunded,
} = constantOrderStatus;
const { KNET, CASH, CREDIT_CARD } = constantPaymentMethods;

const common = {
  busy: "مشغول",
  available: "متاح",
  total: "المجموع",
  open: "مفتوح",
  close: "مغلق",
  refund: "استرجاع",
  more: "زيادة",
  order: "طلب",
  eta: "ETA",
  notes: "ملاحضات",
  name: "الاسم",
  phone: "رقم الهاتف",
  price: "السعر",
  qty: "الكمية",
  qtyShort: "الكمية",
  back: "الكمية",
  status: "الحالة",
  paymentMethod: "طريقة الدفع",
  time: "الوقت",
  AreYouSure: "هل انت متأكد ؟",
  ok: "اوك",
  cancel: "الغاء",
  accept: "موافق",
  decline: "رفض",
  ready: "جاهز",
  enRoute:"الطلب مع السائق ",
  completed: "كامل",
  incomplete: "غير كامل",
  collectCash: "الاستلام نقدا",
  mins: "min دقيقة",
	prepTime: "مدة التحضير",
  viewDetails: "عرض التفاصيل",
  deliveryOptions: "خيارات التوصيل",
  mashkorPhone: "خدمة عملاء مشكور",
  orderValue: "قيمة الطلب",
  type: "نوع",
  orderNo: "رقم الطلب",
  customerName: "اسم العميل",
  phoneNo: "رقم الهاتف",
  address: "العنوان",
  print: "طباعة",
  deliveryVendor: "جهة التوصيل",
  deliveryID: "رقم الطلب ",
  orderStatus: "حاله الطلب",
  changeToMashkor: "يرجى اتصال سائق كاتش",
  walletdec: "استخدام المحفظة",
  description: "الوصف",
  allTime: 'المجموع',
  startDate: 'تاريخ البدء',
  endDate: 'تاريخ الانتهاء',
  submit: 'منقي',
};

const orderDetails = {
  customerDetails: "تفاصيل العميل",
  driverDetails: "تفاصيل السائق",
  items: "الطلب",
  orderNotes: "ملاحظات الطلب",
  deliveryCharge: "رسوم التوصيل",
  promoCode: "كود خصم",
  cashback: "الباقي",
  freeDelivery: "توصيل مجاني",
  cartTotal: "اجمالي الفاتوره",
  coupons: "كوبونات",
  updateQuantity: "تحديث الكمية",
  save: "حفظ",
  reset: "إعادة تعيين",
  canceItem: "إلغاء العنصر",
  markedCanceled: "ملغى",
  itemCanceled: "تم إلغاء العنصر",
  grocery: "متجر",
  orderConfirmation: "تآكيد الطلب"
}

const orders = {
  orderhistory: "بيانات الطلب",
  orderNum: "رقم الطلب",
};

const ordersInfo = {
  customerDetails: "بيانات العميل",
  overview: "نظرة عامة",
  orderRating: "تقييم الطلب",
  orderedItems: "طلباتك",
  orderType: "نوع الطلب",
  deliveryCharge: "رسوم التوصيل",
  freeDelivery: "توصيل مجاني",
  deliveryVendor: "جهة التوصيل",
  customerAddress: "عنوان العميل"
};

const menu = {
  dashboard: "السلة",
  orders: "الطلبات",
  menu: "القائمة",
  settings: 'اعدادات',
  salesStatistics: 'إحصائيات البيع',
  orderStatistics: 'إحصائيات الطلبات',
  signOut: "تسجيل الخروج",
  checkUpdate: 'فحص التحديثات',
  productManagement : 'إدارة المنتج',
  deliveryDisabled: 'خدمة التوصيل غير مفعلة',
  deliveryEnabled: 'خدمة التوصيل مفعلة',
  trackEdits: 'متابعة التعديلات',
  requestDriver: 'طلب سائق',
};

const storeMenu = {
  storeMenu: "قائمة العرض",
  allAvailable: "متوفر جميعا",
  available: "متوفر",
  downloadMenu:'تحميل'
};

const dashboard = {
  allOrders: "جميع الطلبات",
  pendingOrders: "قيد الانتظار",
  activeOrders: "الطلبات القائمه",
}

const orderStatus = {
  [pending]: "قيد الانتظار",
  [accepted]: "مقبول",
  [declined]: "مرفوض",
  [ready]: "جاهزة",
  [completed]: "كامل",
  [incomplete]: "غير كامل",
  [cancelled]: "لاغي",
  [refunded]: "استرجاع",
  TSA: "إجمالي المبيعات",
  TSM: "إجمالي المبيعات الفائتة",
};

const paymentMethods = {
  [KNET]: "KNET",
  [CASH]: "كاش",
  [CREDIT_CARD]: "VISA/MASTER",
};

const accountSetting = {
  storeName: "اسم المتجر",
  accountSettings: 'اعدادات الحساب',
  enabled: 'تفعيل',
  disabled: 'تعطيل',
  fullScreen: 'تكبير الشاشه',
  store: 'متجر',
  delivery: 'توصيل',
}

const modal = {
  update: {
    title: "تحديث جديد",
    body: "هناك تحديث جديد للتطبيق. انقر \"موافق\" للحصول على أحدث الميزات والتحسينات وإصلاحات الأخطاء."
  },
  katchSwitch: {
    title: "تحذير",
    body: ".هل انت ماكد بانك تريد تغيير توصيل الى كاتش ؟ وبعد لن تتمكن من التراجع عن هاذالفعل"
  }
}

const salesStat = {
  sales : "المبيعات",
  weekly: "أسبوعي",
  monthly: "شهري",
  annually: "سنوي",
  pickup: "استلام",
  delivery: "توصيل",
  KDCurrency: "دينار كويتي",
}

const requestDriver = {
  RequestADriver: "اطلب سائق",
  OrderNumber: "رقم الطلب",
  CustomerName: "اسم العميل",
  CustomerNumber: "رقم العميل",
  Zone: "المنطقة",
  AdditionalAddress: "العنوان الإضافي",
  CollectCash: "تحصيل النقد",
  Cash:'تحصيل',
  From: "من",
  To: "ل",
  Total: "الإجمالي",
  Status: "الحالة",
  RequestedAt: "تاريخ الطلب",
  CompletedAt: "تاريخ الاكتمال",
  showOnlyActive: "الطلبات الفعالة فقط",
  showAll: "اظهار الجميع",
  cancel: "إلغاء",
  DriverNumber: "رقم السائق",
  DriverName: "اسم السائق",
  DriverLocation: "موقع السائق",
  StatusLog: "تفصيل الحالة",
  specialNotes: "تعليقات",
  ShowOnlyActive: 'إظهار الطلبات الفعالة فقط',
  Refresh: 'تحديث الحالة',
  edit: 'تعديل',
};

export default {
  translation: {
    loginTitle: "الدخول الى حسابك",
    changeTo: "en",
    changeLang: 'تغيير اللغة',
    newOrders: "طلبات جديدة",
    pending: "معلق",
    others: "اشياء اخرى",
    orderToPickUp: "طلبات للاستلام",
    login: "تسجيل الدخول",
    busyMode: "حالة المطعم"
  },
  common,
  menu,
  modal,
  orders,
  ordersInfo,
  storeMenu,
  dashboard,
  orderStatus,
  paymentMethods,
  orderDetails,
  accountSetting,
  salesStat,
  requestDriver,
};
