import React, { useEffect, useState } from "react";
import { Button, Modal } from "semantic-ui-react";
import { useTranslation } from 'react-i18next';
import { ADJUST_PRICING, UPDATE_ORDER_ITEM } from "../GraphQL/mutations";
import { useMutation } from "@apollo/react-hooks";
import { status } from "../../constants";
import './styles.css';
import { Table, Checkbox, Dropdown } from 'semantic-ui-react';

const OrderItemsTable = ({ items, handleAvailabilityToggle=()=>{}, handleQuantityChange=()=>{} }) => {
  return (
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Available</Table.HeaderCell>
          <Table.HeaderCell>Un-Available</Table.HeaderCell>
          <Table.HeaderCell>Quantity</Table.HeaderCell>
          <Table.HeaderCell>Price (KD)</Table.HeaderCell>
          <Table.HeaderCell>Item Name</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {items.map((item) => (
          <Table.Row key={item._id}>
            <Table.Cell>
              <Checkbox
                checked={item.markedAvailable === true}
                onChange={() => {
                  if(parseInt(item.quantity) === 0) {
                    handleAvailabilityToggle(item._id, undefined)
                  } else {
                    handleAvailabilityToggle(item._id, true)
                  }
                }}
              />
            </Table.Cell>
            <Table.Cell>
              <Checkbox
                checked={item.markedAvailable === false}
                onChange={() => handleAvailabilityToggle(item._id, false)}
              />
            </Table.Cell>
            <Table.Cell>
              <Dropdown
                selection
                value={parseInt(item.quantity)}
                text={`${item.quantity}`}
                onChange={(e, data) => handleQuantityChange(item._id, parseInt(data.value))}
                options={[...Array(parseInt(item.originalQuantity || item.quantity) + 1).keys()].map((value) => ({
                  key: value,
                  value: value,
                  text: value.toString(),
                }))}
              />
            </Table.Cell>
            <Table.Cell>
              {parseInt(item.quantity) > 1 ?
                <p style={{ color: 'black' }}>{(item.price * parseInt(item.quantity)).toFixed(3)} KD <small> ({item.price} KD)</small></p>
                : <p style={{ color: 'black' }}>{item.price} KD</p>}
            </Table.Cell>
            <Table.Cell>
              <p style={{color: item.markedAvailable === false || parseInt(item.quantity) === 0 ? 'red' : "unset",  textDecoration: item.markedAvailable === false || item.quantity === 0 ? 'line-through' : 'none' }}>{item.name}</p>
              <div className="dish-options-container">
                {item.options.map((option, index) => (
                  <div key={option.category} className="options">
                    <span>
                      {option.category}:{" "}
                      <p className="options-selections-name">
                        {option.optionsList
                          .map((list) => list.name)
                          .join(", ")}
                      </p>
                    </span>
                  </div>
                ))}
              </div>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

export const GroceryItemUpdateModal = (props) => {
  const {
    order,
    onClick
  } = props;

  const [modalOpen, setModalOpen] = useState(false);

  const handleAvailabilityToggle = (itemId, markedAvailable) => {
    setItemsCopy((prev) =>
      prev.map((item) =>
        item._id === itemId ? { ...item, markedAvailable: markedAvailable === item.markedAvailable ? undefined : markedAvailable } : item
      )
    );
    if(markedAvailable === false) {
      handleAction(itemId, 'cancel');
    } else if (markedAvailable === true) {
      handleAction(itemId, 'makeAvailable');
    }
  };
  
  const handleQuantityChange = (itemId, quantity) => {
    if(parseInt(quantity) === 0) {
      handleAvailabilityToggle(itemId, undefined);
    }
    setItemsCopy((prev) =>
      prev.map((item) =>
        item._id === itemId
          ? { ...item, quantity: parseInt(quantity), originalQuantity: item.originalQuantity || parseInt(item.quantity) }
          : item
      )
    );
    handleAction(itemId, 'quantity', quantity);
  };

  const onCancel = () => {
    setModalOpen(false);
  };

  const { t } = useTranslation(['common', 'oderDetails']);

  // -----------

  const { ready } = status;
  const [updateOrderItem] = useMutation(UPDATE_ORDER_ITEM);
  const [adjustPricing] = useMutation(ADJUST_PRICING);
  const [itemsCopy, setItemsCopy] = useState(order?.items);
  

  const [updatedItems, setUpdatedItems] = useState([]);

  const [sendButtonDisabled, setSendButtonDisabled] = useState(false);
  const [cancelButtonDisabled, setCancelButtonDisabled] = useState(false);
  const onSaveItems = async (event) => {
    event.persist();
    setCancelButtonDisabled(true);
    setSendButtonDisabled(true);
    try {
      if (updatedItems.length > 0) {
        const data = await updateOrderItem({
          variables: {
            orderId: order._id,
            items: updatedItems,
          },
        }).then(async () => {
          await adjustPricing({
            variables: {
              orderId: order._id,
            }
          }).then(async () => {
            await onClick(event);
          })
        });
      } else {
        await onClick(event);
      }
    } catch (error) {
      window.alert('failed to save');
    }
    setCancelButtonDisabled(false);
    setSendButtonDisabled(false);
  }

  const handleAction = (itemId, action, quantity) => {
    if (action === 'quantity') {
      const existingItemIndex = updatedItems.findIndex(item => item._id === itemId);
      if (existingItemIndex === -1) {
        setUpdatedItems([...updatedItems, { _id: itemId, quantity}]);
      } else {
        const updatedItemsData = updatedItems.map(item => {
          if (item._id === itemId) {
              return { ...item, quantity};
          }
          return item;
        });
        setUpdatedItems(updatedItemsData);
      }
    } else if (action === 'cancel') {
      const existingItemIndex = updatedItems.findIndex(item => item._id === itemId);
      if (existingItemIndex === -1) {
        setUpdatedItems([...updatedItems, { _id: itemId, available: false }]);
      } else {
        const updatedItemsData = updatedItems.map((item, index) => {
          if (index === existingItemIndex) {
            return { ...item, available: false };
          }
          return item;
        });
        setUpdatedItems(updatedItemsData);
      }
    } else if (action === 'makeAvailable') {
      const existingItem = updatedItems.find(item => item._id === itemId);
      if (existingItem) {
        const updatedItemsData = updatedItems.filter(item => {
          if (item._id === itemId && !item.quantity) {
            return false;
          }
          return true;
        }).map(item => {
          if (item._id === itemId) {
            return { ...item, available: true };
          }
          return item;
        });
        setUpdatedItems(updatedItemsData);
      }
    }
  };

  useEffect(() => {
    if(itemsCopy.some(x => x.markedAvailable === undefined )){
      setSendButtonDisabled(true)
    } else {
      setSendButtonDisabled(false)
    }
  }, [itemsCopy])
  // -----------
  
  return (
    <Modal
      trigger={<Button className="accept-button" color="orange" onClick={() => setModalOpen(true)}>{t('ready')}</Button>}
      open={modalOpen}
      onClose={() => setModalOpen(false)}
      size="large"
    >
      <Modal.Header>{t('orderDetails:orderConfirmation')}</Modal.Header>
      <Modal.Content>
        <OrderItemsTable items={itemsCopy} handleAvailabilityToggle={handleAvailabilityToggle} handleQuantityChange={handleQuantityChange} />
      </Modal.Content>
      <div style={{ justifyContent: 'space-around', marginTop: '10px'}}>
        <Button
          className="confirm-button"
          disabled={sendButtonDisabled}
          key={ready}
          name={ready}
          data-order-id={order._id}
          data-orde-num={order.orderNumber}
          onClick={onSaveItems}
        >
          {t('Ready')}
        </Button>
        <Button className="cancel-button" onClick={onCancel} disabled={cancelButtonDisabled}>{t('cancel')}</Button>
      </div>
    </Modal>
  );
};
