import React from "react";

import { TASK_STATUS_COLOR_MAP } from "../constants/ui";

/**
 * Retrieves the color associated with a given status from the STATUS_MAP.
 *
 * @param {string} status - The status key used to retrieve the color from STATUS_MAP.
 * @returns {string} - The color associated with the status, or "gray" if the status is not found.
 */
export const getCellTheme = (status) => {
  const statusInfo = TASK_STATUS_COLOR_MAP[status] || { color: "gray", label: "N/A" };
  return statusInfo.color;
};

/**
 * Returns a JSX element that displays the status label with an associated color and an icon.
 *
 * @param {string} status - The status key used to retrieve the label and color from STATUS_MAP.
 * @returns {JSX.Element} - A span element with the status color and label, including a circle icon.
 */
export const getStatusMappedCell = (status) => {
  const statusInfo = TASK_STATUS_COLOR_MAP[status] || { color: "gray", label: "N/A" };

  return (
    <span style={{ color: statusInfo.color }}>
      <i className="circle icon"></i> {statusInfo.label}
    </span>
  );
};
