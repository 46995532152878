// @ts-nocheck
import React, { useContext, useEffect, useRef, useState, memo } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/react-hooks";
import { DebounceInput } from "react-debounce-input";
import {
  GET_STORE_MENU,
  CHANGE_PRODUCT_PRICE,
  CHANGE_PRODUCT_AVAILABILITY,
  CHANGE_CATEGORY_AVAILABILITY,
  GET_ALL_TAGS,
  GET_ALL_SELLER_CATEGORIES,
  UPDATE_MENU_ITEM,
} from "../../components/GraphQL";
import {
  Segment,
  Dimmer,
  Header,
  Item,
  Container,
  Icon,
  Label,
  Checkbox,
  Grid,
  Form,
  Confirm,
  Button,
  Dropdown,
  Table,
  Input,
  Modal,
} from "semantic-ui-react";
import ProgressiveImage from "react-progressive-image";
import defaultDish from "../../assets/dishes/default-dish-img.jpg";
import ChangePriceModal from "./changePriceModal";
import ProgressLoading from "../../components/Loading/ProgressLoading";
import { store } from "react-notifications-component";
import { Context } from "../../context/notifications";
import { useTranslation } from "react-i18next";
import "./index.css";
import { saveAs } from 'file-saver';
import ExcelJS from 'exceljs';


const StoreMenu = () => {
  const [getStoreMenu, { loading, data, error, refetch }] = useLazyQuery(GET_STORE_MENU);
  const [openModal, setOpenModal] = React.useState(false);
  const [showConfirm, setShowConfirm] = React.useState(false);
  const [showCatConfirm, setShowCatConfirm] = React.useState(false);
  const [changeProductPrice] = useMutation(CHANGE_PRODUCT_PRICE);
  const [changeProductAvailability] = useMutation(CHANGE_PRODUCT_AVAILABILITY);
  const [changeCategoryAvailability] = useMutation(CHANGE_CATEGORY_AVAILABILITY);
  const [loadingProductID, setLoadingProductID] = useState(-1);
  const [loadingCatIndex, setLoadingCatIndex] = useState(-1);
  const {actions} = useContext(Context);
  const [showMenu, setShowMenu] = useState(false);
  const editedProdutID = useRef(null);
  const editedProdutAvailability = useRef(null);
  const catEditedIndex = useRef(null);
  const catEditedAvailability = useRef(null);
  const [filter, setFilter] = useState('');
  const [productList, setProductList] = useState([])
  const [allTags, setAllTags] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [storeIds,setStoreIds] = useState()
  const [linkedOutlets,setLinkedOutlest]= useState([])
  
  const { t } = useTranslation("storeMenu");

  useEffect(() => {
    getStoreMenu({variables:{hideUnavailableProducts:false,id : storeIds}});
  }, [getStoreMenu,storeIds]);

  useQuery(GET_ALL_TAGS, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setAllTags(data.getAllProductTags);
    },
    onError: (err) => {console.log("tags", err)}
  });

  useQuery(GET_ALL_SELLER_CATEGORIES, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setAllCategories(data.getProductCategoriesBySeller);
    },
    onError: (err) => {
      console.log("seller category error", err);
    },
  });

  useEffect(() => {
    if (data && data.getStore) {
      const { products } = JSON.parse(JSON.stringify(data.getStore));
      if(data?.getStore?.mainOutlet){
        const options = data?.getStore?.linkedOutletsName?.map((id, index) => ({
          key: index + 1,
          text: id.shopName,
          value: id._id
        }));
        setLinkedOutlest(options)
      }

      setProductList(
        products
          .map((x) => {
            x.products = x.products.filter((y) =>
              y.name.toLowerCase().includes(filter.toLowerCase())
            );
            return x;
          })
          .filter((y) => y?.products?.length > 0)
      );
    }
  }, [data, filter]);

  //Process Error
  useEffect(() => {
    if (error) {
      store.addNotification({
        title: "error",
        message: `${error}`,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
        },
      });
    }
  }, [error]);

  const onChangePriceClicked = (productID) => {
    editedProdutID.current = productID;
    setOpenModal(true);
  };

  const onPriceChange = async (price) => {
    setLoadingProductID(editedProdutID.current);
    setOpenModal(false);
    try {
      await changeProductPrice({
        variables: {
          changeProductPriceInput: {
            productID: editedProdutID.current,
            price: parseFloat(price),
          },
        },
      });
    } catch (e) {
      store.addNotification({
        title: "error",
        message: `${e}`,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
        },
      });
    } finally {
      setLoadingProductID(-1);
    }
  };

  const onAvailabilityConfirm = () => {
    _changeProductAvailability(
      editedProdutID.current,
      editedProdutAvailability.current
    );
  };

  const _changeProductAvailability = async (productID, available) => {
    setLoadingProductID(editedProdutID.current);
    setShowConfirm(false);
    try {
      await changeProductAvailability({
        variables: {
          changeProductAvailabilityInput: {
            productID: productID,
            available: available,
          },
        },
      });
      await refetch();
    } catch (e) {
      store.addNotification({
        title: "error",
        message: `${e}`,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
        },
      });
    } finally {
      setLoadingProductID(-1);
    }
  };

  const onAvailabilityToggled = (productID, availability) => {
    editedProdutID.current = productID;
    editedProdutAvailability.current = availability;
    setShowConfirm(true);
  };

  const onCatAvailabilityToggled = (index, availability) => {
    catEditedIndex.current = index;
    catEditedAvailability.current = availability;
    setShowCatConfirm(true);
  };

  const onAvailabilityCatConfirm = async () => {
    const category = data.getStore.products[catEditedIndex.current];
    setShowCatConfirm(false);
    setLoadingCatIndex(catEditedIndex.current);
    try {
      if (category) {
        await changeCategoryAvailability({
          variables: {
            changeCategoryAvailabilityInput: {
              categoryName: category._id,
              available: catEditedAvailability.current,
            },
          },
        });
      }
    } catch (e) {
      actions.localNotify({
        title: "error",
        message: e.message,
        type: "danger",
        soundType: null,
      });
    } finally {
      setLoadingCatIndex(-1);
    }
  };

  //Process Error
  useEffect(() => {
    if (error) {
      store.addNotification({
        title: "error",
        message: `${error}`,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
        },
      });
    }
  }, [error]);

  const displayMenu = () => {
    setShowMenu(true);
  };

  const hideMenu = () => {
    setShowMenu(false);
  };

  const navigateToCategory = (id) => {
    if (document.getElementById(id)) {
      document
        .getElementById(id)
        .scrollIntoView({ behavior: "smooth", block: "start" });
    }
    hideMenu();
  };

  if (loading) {
    return (
      <Dimmer active inverted>
        <ProgressLoading />
      </Dimmer>
    );
  }

  const handleDropdownChange = (e, { value }) => {
    setStoreIds(value)
  };

  const handleDownloadExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Orders');
  
    // Add static columns
    const columns = [
      { header: 'Category', key: 'category' },
      { header: 'Product', key: 'name' },
      { header: 'Price', key: 'price' },
      { header: 'Options', key: 'options' }
    ];
  
    // Set the worksheet columns
    worksheet.columns = columns;
  
    // Add rows with data
    productList.forEach((category) => {
      category.products.forEach((product) => {
        if (product.options && product.options.length > 0) {
          product.options.forEach((option, optionIndex) => {
            const row = {
              category: optionIndex === 0 ? product.category : '', // Only add category for the first option row
              name: optionIndex === 0 ? product.name : '', // Only add product name for the first option row
              price: optionIndex === 0 ? `KWD ${product.price}` : '', // Only add product price for the first option row
              options: option.optionsList.map(opt => opt.name).join(', ')
            };
  
            worksheet.addRow(row);
          });
        } else {
          const row = {
            category: product.category,
            name: product.name,
            price: `KWD ${product.price}`,
            options: '-' // Placeholder for products without options
          };
  
          worksheet.addRow(row);
        }
      });
    });
  
    // Generate and save the Excel file
    const currentDate = new Date().toISOString().slice(0, 10);
    const fileName = `menu-${currentDate}.xlsx`;
  
    workbook.xlsx.writeBuffer().then(buffer => {
      const excelData = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(excelData, fileName);
    });
  };


  if (data && data.getStore) {
    const { shopName, _id } = data.getStore;
    const imageURLBase = `${process.env.REACT_APP_IMAGEURL_S3}${_id}`;

    return (
      <Container style={{ marginTop: "2em" }}>
        <DebounceInput
          minLength={1}
          debounceTimeout={1000}
          type="text"
          placeholder="Search Products ..."
          onChange={(e) => setFilter(e.target.value)}
          className="filter-input"
        />
        
        {data?.getStore?.mainOutlet ? (
          <Dropdown
            placeholder="Select Restaurant"
            fluid
            selection
            onChange={handleDropdownChange}
            options={linkedOutlets}
            style={{ width: "250px", fontSize: "12px" }}
          />
        ) : null}

        <Header as="h3" dividing style={{ marginRight: "10px" }}>
          Store Menu - {shopName}
        </Header>
        <div
          className={showMenu ? "modal-blur" : null}
          onClick={() => hideMenu()}
        >
          {productList.map((category, index) => {
            return (
              <Category
                productCategory={category}
                key={index}
                index={index}
                imageURLBase={imageURLBase}
                onChangePrice={onChangePriceClicked}
                onAvailabilityToggled={onAvailabilityToggled}
                loadingProductID={loadingProductID}
                onCatAvailabilityToggled={onCatAvailabilityToggled}
                loadingCatIndex={loadingCatIndex}
                allTags={allTags}
                allCategories={allCategories}
              />
            );
          })}
        </div>

        {!showMenu ? (
          <div className="menuButton" onClick={() => displayMenu()}>
            Menu
          </div>
        ) : (
          <div className="menuButton" onClick={() => hideMenu()}>
            Close
          </div>
        )}

        {showMenu ? (
          <div className="categoryListMenu" onClick={() => hideMenu()}>
            {data.getStore.products.map((_category) => {
              return (
                <div
                  className="menu-container"
                  style={{ display: "flex" }}
                  onClick={() => navigateToCategory(_category._id)}
                >
                  <span className="categoryName">{_category._id}</span>
                  <span className="productLength">
                    {_category.products.length}
                  </span>
                </div>
              );
            })}
          </div>
        ) : null}

        <ChangePriceModal
          open={openModal}
          setOpen={setOpenModal}
          onPriceChange={onPriceChange}
        />
        <Confirm
          open={showConfirm}
          onCancel={() => setShowConfirm(false)}
          onConfirm={onAvailabilityConfirm}
        />
        <Confirm
          open={showCatConfirm}
          onCancel={() => setShowCatConfirm(false)}
          onConfirm={onAvailabilityCatConfirm}
        />
      </Container>
    );
  } else {
    return null;
  }
};

export default StoreMenu;

const Category = ({
  productCategory,
  imageURLBase,
  onChangePrice,
  onAvailabilityToggled,
  onCatAvailabilityToggled,
  loadingProductID,
  index,
  loadingCatIndex,
  allTags,
  allCategories,
}) => {
  const { _id, products } = productCategory;
  const [categoryAvailable, setCategoryAvailable] = useState(true);

  useEffect(() => {
    if (loadingCatIndex !== index) {
      setCategoryAvailable(
        products.every((product) => {
          return product.available;
        })
      );
    }
  }, [products, loadingCatIndex, index]);


  return (
    <Segment loading={loadingCatIndex === index} id={_id}>
      <Header as="h3" dividing>
        <Grid columns={2}>
          <Grid.Row>
            <Grid.Column>{_id}</Grid.Column>
            {/* <Grid.Column style={{ textAlign: "end" }}>
              <Form>
                <Form.Field>
                  <Checkbox
                    toggle
                    label={t('allAvailable')}
                    checked={categoryAvailable}
                    onChange={() =>
                      onCatAvailabilityToggled(index, !categoryAvailable)
                    }
                  />
                </Form.Field>
              </Form>
            </Grid.Column> */}
          </Grid.Row>
        </Grid>
      </Header>
      <Item.Group divided>
        {products.map((product, index) => {
          return (
            <Product
              product={product}
              key={index}
              imageURLBase={imageURLBase}
              onChangePrice={onChangePrice}
              onAvailabilityToggled={onAvailabilityToggled}
              loadingProductID={loadingProductID}
              allTags={allTags}
              allCategories={allCategories}
            />
          );
        })}
      </Item.Group>
    </Segment>
  );
};

const CategoryDropdown = ({
  categories,
  handleCategoryChange,
  selectedCategory,
  setSelectedCategory,
  handleErrors,
}) => {
  const handleDropdownChange = (e, { value }) => {
    setSelectedCategory(value);
    handleCategoryChange(value);
    handleErrors({ name: "Category", value });
  };

  return (
    <Dropdown
      placeholder={selectedCategory || "Select or type category"}
      search
      selection
      allowAdditions
      options={categories.map((category) => ({
        key: category,
        text: category,
        value: category,
      }))}
      value={selectedCategory}
      onAddItem={handleDropdownChange}
      onChange={handleDropdownChange}
    />
  );
};

const TagSelector = ({ allTags, selectedTags, setSelectedTags }) => {
  const handleTagClick = (tagName) => {
    const selectedTag = allTags.find((tag) => tag.name === tagName);
    if (selectedTags.some((tag) => tag.name === tagName)) {
      setSelectedTags(selectedTags.filter((tag) => tag.name !== tagName));
    } else {
      setSelectedTags([...selectedTags, selectedTag]);
    }
  };

  return (
    <Grid.Row style={{ marginTop: 10, marginBottom: 10 }}>
      <Tags
        tags={allTags.map((tag) => tag.name)}
        handleTagClick={handleTagClick}
        selectedTags={selectedTags}
        active
      />
    </Grid.Row>
  );
};

const OptionsTable = ({ options, onOptionsChange, isEditMode }) => {
  const [newCategoryOption, setNewCategoryOption] = useState({
    name: "",
    price: "",
  });

  const handleInputChange = (value, optionIndex, optionProp) => {
    const newOptions = [...options];
    newOptions[optionIndex][optionProp] = value;
    onOptionsChange(newOptions);
  };

  const handleOptionInputChange = (
    event,
    optionIndex,
    optionListIndex,
    optionProp
  ) => {
    const newOptions = [...options];
    newOptions[optionIndex].optionsList[optionListIndex][optionProp] =
      event.target.value;
    onOptionsChange(newOptions);
  };

  const handleAddOption = () => {
    onOptionsChange([
      ...options,
      { category: "", optionsList: [], minSelect: -1, maxSelect: -1 },
    ]);
  };

  const handleNewOption = (optionIndex) => {
    const newOptions = [...options];
    newOptions[optionIndex].optionsList.push(newCategoryOption);
    onOptionsChange(newOptions);
    setNewCategoryOption({ name: "", price: "" });
  };

  const handleRemoveOption = (optionIndex, optionListIndex) => {
    const newOptions = [...options];
    newOptions[optionIndex].optionsList.splice(optionListIndex, 1);
    onOptionsChange(newOptions);
  };

  const handleRemoveCategory = (optionIndex) => {
    const newOptions = [...options];
    newOptions.splice(optionIndex, 1);
    onOptionsChange(newOptions);
  };

  return options.length > 0 || isEditMode ? (
    <div style={{ overflow: "auto" }}>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Category</Table.HeaderCell>
            <Table.HeaderCell>Options</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Min. Select</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Max. Select</Table.HeaderCell>
            {isEditMode && (
              <Table.HeaderCell textAlign="center">
                Remove Category
              </Table.HeaderCell>
            )}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {options.map((option, optionIndex) => (
            <Table.Row key={optionIndex}>
              <Table.Cell>
                {isEditMode ? (
                  <Form>
                    <Form.Input
                      value={option.category}
                      onChange={(e) =>
                        handleInputChange(
                          e.target.value,
                          optionIndex,
                          "category"
                        )
                      }
                      error={option.category.trim() === ""}
                      placeholder={"Category Name"}
                    />
                  </Form>
                ) : (
                  option.category
                )}
              </Table.Cell>
              <Table.Cell>
                {isEditMode && (
                  <div>
                    {option.optionsList.map((opt, optionListIndex) => (
                      <div
                        key={optionListIndex}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <Form
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <Form.Input
                            value={opt.name}
                            onChange={(e) =>
                              handleOptionInputChange(
                                e,
                                optionIndex,
                                optionListIndex,
                                "name"
                              )
                            }
                            placeholder="Option Name"
                            error={opt.name.trim() === ""}
                          />
                          <Form.Input
                            type="number"
                            value={opt.price}
                            onChange={(e) =>
                              handleOptionInputChange(
                                e,
                                optionIndex,
                                optionListIndex,
                                "price"
                              )
                            }
                            placeholder="Option Price"
                          />
                        </Form>
                        <Button
                          icon="remove"
                          onClick={() =>
                            handleRemoveOption(optionIndex, optionListIndex)
                          }
                          color="red"
                        />
                      </div>
                    ))}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        paddingTop: 10,
                        paddingBottom: 10,
                      }}
                    >
                      <Button
                        icon="add"
                        content="Add Option"
                        onClick={() => handleNewOption(optionIndex)}
                      />
                    </div>
                  </div>
                )}
                {!isEditMode &&
                  option.optionsList.map((opt, optionListIndex) => (
                    <p key={optionListIndex}>
                      {opt.name} {opt.price && `(${opt.price})`}
                    </p>
                  ))}
              </Table.Cell>
              <Table.Cell textAlign="center">
                {isEditMode ? (
                  <Input
                    value={option.minSelect}
                    type={"number"}
                    onChange={(e) =>
                      handleInputChange(
                        parseInt(e.target.value),
                        optionIndex,
                        "minSelect"
                      )
                    }
                  />
                ) : (
                  option.minSelect
                )}
              </Table.Cell>
              <Table.Cell textAlign="center">
                {isEditMode ? (
                  <Input
                    value={option.maxSelect}
                    type={"number"}
                    onChange={(e) =>
                      handleInputChange(
                        parseInt(e.target.value),
                        optionIndex,
                        "maxSelect"
                      )
                    }
                  />
                ) : (
                  option.maxSelect
                )}
              </Table.Cell>
              {isEditMode && (
                <Table.Cell>
                  <Button
                    icon="remove"
                    onClick={() => handleRemoveCategory(optionIndex)}
                    color="red"
                  />
                </Table.Cell>
              )}
            </Table.Row>
          ))}
        </Table.Body>
        {isEditMode && (
          <Table.Footer>
            <Table.Row>
              <Table.Cell colSpan={4}>
                <Button
                  icon="add"
                  content="Add Category"
                  onClick={handleAddOption}
                />
              </Table.Cell>
            </Table.Row>
          </Table.Footer>
        )}
      </Table>
    </div>
  ) : (
    <></>
  );
};

const Product = ({
  product,
  imageURLBase,
  onChangePrice,
  onAvailabilityToggled,
  loadingProductID,
  allTags,
  allCategories,
}) => {
  const {
    image,
    name,
    price,
    tags,
    description,
    _id,
    available,
    options,
    category,
  } = product;
  const { t } = useTranslation("storeMenu");
  const [isEditMode, setIsEditMode] = useState(false);
  const tagsWithIds = tags.map((tag) => {
    const matchingTag = allTags.find((t) => t.name === tag);
    if (matchingTag) {
      return { name: matchingTag.name, id: matchingTag.id };
    }
    return { name: tag };
  });
  const [selectedTags, setSelectedTags] = useState(tagsWithIds);
  const [updatedName, setUpdatedName] = useState(name);
  const [updatedPrice, setUpdatedPrice] = useState(price);
  const [updatedDescription, setUpdatedDescription] = useState(description);
  const [selectedCategory, setSelectedCategory] = useState(category);
  const [updatedOptions, setUpdatedOptions] = useState(options);
  const [updateMenuItem] = useMutation(UPDATE_MENU_ITEM);
  const [formErrors, setFormErrors] = useState({});
  const isFormValid = Object.keys(formErrors).length === 0;

  const cleanOptionsList = () => {
    const filteredOptions = updatedOptions?.filter((option) => {
      return (
        option.category.trim() !== "" &&
        option.optionsList.some((option) => option.name.trim() !== "")
      );
    });
    filteredOptions.forEach((option) => {
      option.optionsList = option.optionsList.filter(
        (option) => option.name.trim() !== ""
      );
      delete option._id;
    });

    const updatedMenuItem = {
      itemId: _id,
      options: filteredOptions,
      tags: selectedTags.map((tag) => {
        if (tag) return tag.id;
      }),
      name: updatedName,
      price: updatedPrice,
      category: selectedCategory,
      description: updatedDescription,
    };

    return updatedMenuItem;
  };

  const onOptionsChange = (newOptions) => {
    setUpdatedOptions(newOptions);
  };

  const handleEdit = () => {
    setIsEditMode(true);
  };

  const handleCancel = () => {
    setIsEditMode(false);
    setUpdatedOptions(options);
  };

  const handleSave = async () => {
    setIsEditMode(false);
    try {
      const data = await updateMenuItem({
        variables: {
          updateItemInput: cleanOptionsList(),
        },
      });

      // FIXME: SHOW SUCCESS MESSAGE AND WHERE THEY CAN TRACK
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleErrors = ({ name, value }) => {
    const errors = { ...formErrors };
    if (!value) {
      errors[name] = "Field cannot be empty";
    } else {
      delete errors[name];
    }
    setFormErrors(errors);
  };

  return (
    <Item key={name} as={Segment} loading={loadingProductID === _id}>
      <Grid.Column style={{ marginRight: 15 }}>
        <ProgressiveImage
          src={`${imageURLBase}/productImages/${image}`}
          placeholder={defaultDish}
        >
          {(src) => (
            <Item.Image
              className="store-menu-items-image"
              size="small"
              src={src}
              alt={`dish image - ${name}`}
            />
          )}
        </ProgressiveImage>
        <Grid.Row style={{ marginTop: 10, marginBottom: 10 }}>
          {!isEditMode && (
            <Form.Field
              control={Button}
              onClick={handleEdit}
              style={{ width: "100%" }}
            >
              Edit
            </Form.Field>
          )}
          {isEditMode && (
            <>
              <Modal
                size="tiny"
                trigger={
                  <Form.Field
                    control={Button}
                    secondary
                    style={{ marginBottom: "10px", width: "100%" }}
                    disabled={!isFormValid}
                  >
                    Save
                  </Form.Field>
                }
                header="Save Confirmation"
                content="Are you sure you want to save the edits?"
                actions={[
                  {
                    key: "no",
                    content: "No",
                  },
                  {
                    key: "yes",
                    content: "Yes",
                    positive: true,
                    onClick: handleSave,
                  },
                ]}
              />
              <Modal
                size="tiny"
                trigger={
                  <Form.Field control={Button} style={{ width: "100%" }}>
                    Cancel
                  </Form.Field>
                }
                header="Cancel Confirmation"
                content="Are you sure you want to cancel the edits? All your progress will be lost."
                actions={[
                  {
                    key: "no",
                    content: "No",
                  },
                  {
                    key: "yes",
                    content: "Yes",
                    positive: true,
                    onClick: handleCancel,
                  },
                ]}
              />
            </>
          )}
        </Grid.Row>
      </Grid.Column>
      <Item.Content>
        <Item.Header style={{ width: "100%" }}>
          <Grid columns={2}>
            <Grid.Row>
              <Grid.Column>
                {isEditMode ? (
                  <Form>
                    <Form.Input
                      value={updatedName}
                      placeholder={"Name"}
                      onChange={(e) => {
                        setUpdatedName(e.target.value);
                        handleErrors({ name: "Name", value: e.target.value });
                      }}
                      error={updatedName.trim() === ""}
                    />
                  </Form>
                ) : (
                  name
                )}
              </Grid.Column>
              <Grid.Column style={{ textAlign: "end" }}>
                <Form>
                  {!isEditMode && (
                    <Form.Field>
                      <Checkbox
                        toggle
                        label={t("available")}
                        checked={available}
                        onChange={() => onAvailabilityToggled(_id, !available)}
                      />
                    </Form.Field>
                  )}
                </Form>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Item.Header>
        <Item.Meta>
          {isEditMode ? (
            <>
              {/* FIXME: Style */}
              <Grid.Row style={{ marginTop: 10, marginBottom: 10 }}>
                <TagSelector
                  allTags={allTags}
                  selectedTags={selectedTags}
                  setSelectedTags={setSelectedTags}
                />
              </Grid.Row>
              <Grid.Row style={{ marginTop: 10, marginBottom: 10 }}>
                <CategoryDropdown
                  categories={allCategories}
                  handleCategoryChange={(category) =>
                    setSelectedCategory(category)
                  }
                  selectedCategory={selectedCategory}
                  setSelectedCategory={setSelectedCategory}
                  handleErrors={handleErrors}
                />
              </Grid.Row>
            </>
          ) : (
            <Tags tags={tags} />
          )}
        </Item.Meta>
        <Item.Description>
          {isEditMode ? (
            <Form.TextArea
              value={updatedDescription}
              placeholder={"Description"}
              onChange={(e) => setUpdatedDescription(e.target.value)}
              style={{ width: "60%" }}
            />
          ) : (
            description
          )}
        </Item.Description>
        <Item.Extra>
          <Label>
            <Icon name="money" />
            {isEditMode ? (
              <Form.Input
                value={updatedPrice}
                placeholder={"Price"}
                type={"number"}
                onChange={(e) => {
                  setUpdatedPrice(e.target.value);
                  handleErrors({ name: "Price", value: e.target.value });
                }}
              />
            ) : (
              price + "KD"
            )}
          </Label>
          {/* 
            Uncomment this to enable change Price
            <Button primary floated="right" onClick={() => onChangePrice(_id)}>
            Change Price
            <Icon name="right chevron" />
          </Button> */}
        </Item.Extra>
        <OptionsTable
          options={updatedOptions}
          onOptionsChange={onOptionsChange}
          isEditMode={isEditMode}
        />
      </Item.Content>
    </Item>
  );
};

const colorPallate = [
  "orange",
  "yellow",
  "olive",
  "green",
  "teal",
  "blue",
  "violet",
  "purple",
  "pink",
  "brown",
  "grey",
  "black",
];

const Tags = memo(
  ({ tags, handleTagClick = () => {}, selectedTags = [], active = false }) => {
    return tags.map((tag) => {
      const isTagSelected = selectedTags.some(
        (selectedTag) => selectedTag.name === tag
      );
      const color = !active
        ? colorPallate[Math.floor(Math.random() * colorPallate.length)]
        : isTagSelected
        ? "green"
        : null;
      return (
        <Label
          className="store-menu-items-tags"
          key={tag}
          color={tag === "Best Selling" ? "red" : color}
          onClick={() => handleTagClick(tag)}
        >
          {tag}
        </Label>
      );
    });
  }
);
