import React, { createContext, useEffect, useState } from "react";
import { useClearCache } from "react-clear-cache";
import { useTranslation } from "react-i18next";
import { Button, Modal } from "semantic-ui-react";
import semver from "semver";
import packageJson from "../../package.json";

export const Context = createContext();

const Provider = (props) => {
  const { children } = props;
  const { t } = useTranslation(["common", "modal"]);

  const scheduleTime = 5 * 60 * 1000; // time in milli sec

  const checkForUpdate = async (noCache = false) => {
    try {
      const period = 60 * 10;

      const myHeaders = new Headers();
      if (noCache) {
        myHeaders.append("pragma", "no-cache");
        myHeaders.append("cache-control", "no-cache");
      } else {
        myHeaders.append("Cache-Control", `public, max-age=${period}`);
      }
      const options = {
        method: "GET",
        headers: myHeaders,
      };
      const url = "/config.json";
      const res = await fetch(url, options);
      const data = await res.json();
      return data;
    } catch (ignored) {}
  };

  const [isNewVersionUp, setIsNewVersionUp] = useState(null);
  const [isForceUpdate, setIsForceUpdate] = useState(null);
  const { emptyCacheStorage } = useClearCache();

  const onFetch = (res) => {
    if (res) {
      const serverVersion = res.appVersion.version;
      const webVersion = packageJson.version;
      console.debug(webVersion, serverVersion);
      const isNewVersion = semver.gt(serverVersion, webVersion);
      setIsNewVersionUp(isNewVersion);
      setIsForceUpdate(res.appVersion.force);
    }
  };

  useEffect(() => {
    if (packageJson) {
      checkForUpdate().then(onFetch);
    }
  }, []);

  const checkNewversion = async (noCache = false) => {
    const _response = await checkForUpdate(noCache);
    onFetch(_response);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isNewVersionUp) {
        checkForUpdate().then(onFetch);
      }
    }, scheduleTime);
    return () => clearInterval(interval);
  }, [scheduleTime, isNewVersionUp]);

  const onYes = () => {
    emptyCacheStorage();
  };

  const onCancel = () => {
    setIsNewVersionUp(false);
  };

  const value = {
    isForceUpdate,
    isNewVersionUp,
    checkNewversion,
  };

  return (
    <Context.Provider value={value}>
      <Modal open={isNewVersionUp} style={{ overflow: "hidden" }}>
        <Modal.Header>{t("modal:update.title")}</Modal.Header>
        <Modal.Content>{t("modal:update.body")}</Modal.Content>
        <Modal.Actions>
          {isForceUpdate ? null : (
            <Button onClick={onCancel} color="red">
              {t("cancel")}
            </Button>
          )}
          <Button onClick={onYes} color="green">
            {t("ok")}
          </Button>
        </Modal.Actions>
      </Modal>
      {children}
    </Context.Provider>
  );
};

export default Provider;
