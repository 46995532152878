import React from "react";
import { AiOutlineFullscreenExit, AiOutlineFullscreen } from "react-icons/ai";
import './index.css';

const FullScreenToggle = (props) => {
  const { isFullScreen, setIsFullScreen } = props;
  const openFullscreen = () => {
    const elem = document.documentElement;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      /* Firefox */
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE/Edge */
      elem.msRequestFullscreen();
    }
    setIsFullScreen(true);
  };

  /* Close fullscreen */
  const closeFullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      /* Firefox */
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      /* Chrome, Safari and Opera */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      /* IE/Edge */
      document.msExitFullscreen();
    }
    setIsFullScreen(false);
  };

  const Icon  = isFullScreen ? AiOutlineFullscreenExit : AiOutlineFullscreen;

  return <Icon className="fullscreen-icon" onClick={isFullScreen ? closeFullscreen : openFullscreen} />
  
};

export default FullScreenToggle;
