import React, { useState, useEffect } from "react";
import firebase from "firebase";
import { useLazyQuery } from "@apollo/react-hooks";
import { GET_VENDOR, GET_KMART_ORDERITEM, GET_VENDOR_ITEMS } from "../components/GraphQL";
export const Context = React.createContext(null);

export const Provider = (props) => {
    const currentUser = firebase.auth().currentUser;
    const [vendor, setVendor]= useState({
        _id: null
    });
    const [orders, setOrders] = useState([]);
    const [items, setItems] = useState([]);
    const [showPending, setShowPending] = useState(true);
    const [getVendor] = useLazyQuery(GET_VENDOR, {
        fetchPolicy: 'no-cache',
        variables: {
            uid: currentUser.uid,
        },
        onCompleted: (data) => {
            setVendor(data.getVendor);
        }
    });

    const [getVendorOrder, {loading: orderLoading}] = useLazyQuery(GET_KMART_ORDERITEM, {
        fetchPolicy: 'no-cache',
        variables: {
            vendorId: vendor._id,
            pending: showPending,

        },
        onCompleted: (data) => {
            setOrders(data.getOrderItems);
        }
    })

    
    const [getVendorItems, {loading: itemsLoading}] = useLazyQuery(GET_VENDOR_ITEMS, {
        fetchPolicy: 'no-cache',
        variables: {
            pagination: {
                limit: 100,
            },
            filter: {
                vendorId: vendor._id,
            }
        },
        onCompleted: (data) => {
            setItems(data.getProducts);
        }
    })

    useEffect(() => {
    if(vendor && vendor._id) {
        getVendorOrder();
        getVendorItems();
    }
    }, [vendor])

    useEffect(() => {
        getVendor();
    }, []);


    const togglePendingCompleted = (flag) => {
        setShowPending(flag);
    }

    useEffect(() => {
        getVendorOrder();
    }, [showPending]);


  const value = {
    allKmartOrders: () => alert("Asd"),
    allKmartItems: () => alert("xyz"),
    vendor,
    orders,
    getVendorOrder,
    orderLoading,
    getVendorItems,
    items,
    itemsLoading,
    togglePendingCompleted,
    showPending,
  };
  return <Context.Provider value={value}>{props.children}</Context.Provider>;
};

export default Provider;
