import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon, Modal } from "semantic-ui-react";
import { PrepModal as AcceptButton } from "../../components/PrepModal";
import PRing from '../../components/PRing';
import { status } from '../../constants/orderStatus';
import { delivery, pickUp } from '../../constants/serviceType';
import { Context as orderManagementContext } from '../../context/orderManagement';
import LoadingOverlay from '../LoadingOverlay';
import MobileOrderDetails from '../MobileOrderDetails';
import PrintOrder from '../../components/PrintOrderView';
import paymentMethods from '../../constants/paymentMethods';

import './index.css'
import { KATCH_RIDER_BLACK } from '../../assets/images';
import moment from 'moment';
import { GroceryItemUpdateModal } from '../GroceryItemUpdateModal';

const {
    pending,
    accepted,
    declined,
    ready,
    completed,
    incomplete,
    enRoute,
    changeToMashkor,
} = status;

const buttonTexts = {
    [accepted]: 'accept',
    [declined]: 'decline',
    [ready]: 'ready',
    [enRoute]: 'enRoute',
    [completed]: 'completed',
    [incomplete]: 'incomplete',
    [changeToMashkor]: 'changeToMashkor',
}

const orderButtons = {
    [pickUp]: {
        [pending]: [accepted, declined],
        [accepted]: [ready, completed, incomplete],
        [ready]: [completed, incomplete]
    },
    [delivery]: {
        [pending]: [accepted, declined],
        [accepted]: {
            self: {
                [accepted]: [ready,enRoute, completed, changeToMashkor],
                [ready]: [enRoute, completed],
                [enRoute]: [completed],
            },
            mashkor: {
                [accepted]: [ready, enRoute, completed],
                [ready]: [enRoute, completed],
                [enRoute]: [completed],
            }
        },
    }
}


const MobileOrderRow = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const { actions, multipleOutlet } = useContext(orderManagementContext);

    const {
        order,
        activeDropDown,
        setActiveDropDown,
        index,
    } = props;
    const [eta, setEta] = useState(null);
    const { t } = useTranslation(['common', 'modal', 'orderDetails']);

    const onClick = async (event) => {
        try{
            setIsLoading(true);
            await actions.onChangeOrderStatus(event);
            setIsLoading(false);
        } catch {
            setIsLoading(false);
        }
    }

    const renderButtons = (buttons, orderType) => buttons && buttons.map(button => {
        if (button === accepted) {
            return <AcceptButton
                key={button}
                name={button}
                className={"mobile-acceptd-button"}
                orderId={order._id}
                orderNum={order.orderNumber}
                onChangeOrderStatus={onClick}
                showDeliveryOptions={orderType === delivery}
                deliveryVendor={order.vendor}
                availableVendors={order.availableVendors}
            />
        }

        if(button === ready && order.groceryOnly === true){
          return <GroceryItemUpdateModal order={order} onClick={onClick}/>
        }
        const buttonText = orderType === delivery && order.vendor === 'mashkor' && button === enRoute ? "Picked Up" : buttonTexts[button];

        if (orderType === delivery && order.vendor === 'mashkor' && (button === enRoute || button === completed)) {
          return (
            <Modal
              size="tiny"
              key={button}
              trigger={<Button>{t(buttonText)}</Button>}
              header="Alert!"
              content="The delivery is handled by Katch, are you sure you want to change the status manually?"
              actions={[
                {
                  key: "no",
                  content: "No",
                },
                {
                  key: "yes",
                  content: "Yes",
                  positive: true,
                  onClick: () =>
                    onClick({
                      target: {
                        name: button,
                        dataset: {
                          orderId: order._id,
                          orderNum: order.orderNumber,
                        },
                      },
                    }),
                },
              ]}
            />
          );
        } else if (button === changeToMashkor) {
            if(!order.availableVendors.includes('mashkor')) return null;
            return (
              <ShowConfirmDialog
                header={t("modal:katchSwitch.title")}
                content={t("modal:katchSwitch.body")}
                button={button}
                buttonText={t(buttonText)}
                onClick={() =>
                  onClick({
                    target: {
                      name: button,
                      dataset: {
                        orderId: order._id,
                        orderNum: order.orderNumber,
                      },
                    },
                  })
                }
              />
            );
      }  else {
          return (
            <Button
              key={button}
              name={button}
              data-order-id={order._id}
              data-orde-num={order.orderNumber}
              onClick={onClick}
            >
              {t(buttonText)}
            </Button>
          );
        }
    });

    const renderButtonsPickUpButton = (orderType, orderStatus) => {
        if (orderType !== pickUp) return null;
        return renderButtons(orderButtons[orderType][orderStatus], orderType);
    }

    const renderButtonsDeliveryButton = (orderType, orderStatus, vendor) => {
        if (orderType !== delivery) return null;

        let buttonsList;

        if (orderStatus === pending) {
            buttonsList = orderButtons[orderType][orderStatus];
        } else {
            buttonsList = orderButtons[orderType][accepted][vendor][orderStatus];

        }

        return renderButtons(buttonsList, orderType);
    }

    const onExpand = (event) => {
        const tagName = event.target.tagName
        if(tagName === 'BUTTON') return
        if(index === activeDropDown){
            setActiveDropDown(-1)
        } else {
            setActiveDropDown(index)
        }
    }
    // const isCashPayment = order.paymentMethod === paymentMethods.CASH; 
    const didAccpetOrder = order.orderStatus === status.accepted;
    
    const orderDate = moment(parseFloat(order.timeStamp)).format('DD-MM-YYYY');

    let deliveryDate;
    if (!order?.asapDelivery) {
      if (order?.preferredDeliverySlot?.day === 'today') {
        deliveryDate = orderDate;
      } else {
        const nextDay = moment(parseFloat(order.timeStamp)).add(1, 'day');
        deliveryDate = nextDay.format('DD-MM-YYYY');
      }
    }
    const isCashPayment = order.paymentMethod === paymentMethods.CASH;
    const isVendorCollectable = order.deliveryVendor !== 'TOOKAN';

    return (<li className="web-order-table-row">
        <LoadingOverlay active={isLoading}>
        <div className="web-order-row-container" onClick={onExpand}>
            <div className="row-order-info">
                <div className="row-order-info-1">
                <p className={`row-order-number ${order.orderStatus === status.pending ? "flash" : eta === 0 && didAccpetOrder? "flash-alert":""}`}><Icon name={activeDropDown === index? "chevron circle up":"chevron circle down"} size='large'/>   {t('orderNo')}: {order.orderNumber}</p>
                    {order.groceryOnly ? <span className="row-order-user-name" style={{color: 'red'}}>{t('orderDetails:grocery')}</span> : null}
                    <span className="row-order-user-name">{t('customerName')}: <p>{order.userInfo.name}</p></span>
                    <span className="row-order-user-number">{t('phoneNo')}: <p>{order.userInfo.phoneNumber?.replace(/\+965(.+)/, '$1')}</p></span>
                    {didAccpetOrder && <span className="timer-container">
                        <PRing orderEta={order.timeStampEta} eta={eta} setEta={setEta} />
                    </span>}
                    {multipleOutlet && <span className="store-name">{order.storeName}</span> }
                    
                </div>
                <div className="row-order-info-2">
                    <span className="row-order-type">{t('type')}: <p>{order.orderType}</p></span>
                    {
                        order.orderType === 'Delivery' && order.orderStatus !== 'Pending' && <span className="row-order-type capitalize">{t('deliveryVendor')}: <p>{order.vendor === 'mashkor' ? 'Katch' : order.vendor} - {order.deliveryVendor}</p></span>
                    }
                    {order.paymentMethod && (
                      <span> {t('paymentMethod')}: {" "}<p>{order.paymentMethod}</p></span>
                    )}
                     <span className="row-order-payment">{t('orderValue')}: <p>{order.total} KD</p></span>
                    <span className="row-order-type">{t('orderStatus')}: <p>{order.orderStatus}</p></span>
                    {order.walletdec && <span className="row-order-type">{t('walletdec')}: <p>{order.walletdec}</p></span>}
                    {isCashPayment && didAccpetOrder && parseFloat(order.total) !== 0 && isVendorCollectable && <span className="row-cash-tag">{t('collectCash')}</span>}
                </div>
            {order?.groceryOnly ? <div className="row-order-info-2">
              {order?.preferredDeliverySlot?.startTime || order?.asapDelivery ? <span className="row-order-payment">{t('deliveryTime')}: <p>{order?.asapDelivery ? "ASAP" : `${order?.preferredDeliverySlot?.startTime} - ${order?.preferredDeliverySlot?.endTime}`}</p></span> : null}
              {!order?.asapDelivery ? <span className="row-order-payment">{t('deliveryDate')}: <p>{deliveryDate}</p></span> : null}
            </div> : null}
                <div className="mobile-row-order-buttons">
                    <PrintOrder order={order} />
                    {renderButtonsPickUpButton(order.orderType, order.orderStatus)}
                    {renderButtonsDeliveryButton(order.orderType, order.orderStatus, order.vendor)}
                </div>
            </div>
        </div>
        {activeDropDown === index && <MobileOrderDetails order={order} />}
         </LoadingOverlay>
    </li>);
}


const ShowConfirmDialog = (props) => {
    const {button, content, header, onClick} = props;

    return <Modal
    size="tiny"
    key={button}
    trigger={
      <Button className="katch-rider-button">
        <span className="katch-rider-text">Use</span>
        <img
          src={KATCH_RIDER_BLACK}
          alt={"Katch Rider"}
          className="katch-rider-image"
        />
      </Button>
    }
    header={header}
    content={content}
    actions={[
      {
        key: "no",
        content: "No",
      },
      {
        key: "yes",
        content: "Yes",
        positive: true,
        onClick: onClick,
      },
    ]}
  />
}

export default MobileOrderRow;