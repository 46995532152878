import gql from 'graphql-tag';


export const CHANGE_ORDER_STATUS = gql`
    mutation ChangeOrderStatus($OrderStatusInput: OrderStatusInput, $staffId: String){
        changeOrderStatus(OrderStatusInput: $OrderStatusInput, staffId: $staffId)
    }

`;

export const TOGGLE_STORE = gql`
    mutation ToggleStore($status: Boolean, $service: String){
        toggleStore(status: $status, service: $service)
    }
`;

export const TOGGLE_BUSY = gql`
mutation ToggleBusy($status: Boolean, $expiry: String){
    toggleBusy(status: $status, expiry: $expiry)
}
`;

export const CHANGE_PRODUCT_PRICE = gql `
    mutation ChangeProductPrice($changeProductPriceInput: ChangeProductPriceInput) {
        changeProductPrice(changeProductPriceInput: $changeProductPriceInput) {
            _id
            products {
                _id,
                price
            }
        }
    }
`;

export const CHANGE_PRODUCT_AVAILABILITY = gql `
    mutation changeProductAvailability($changeProductAvailabilityInput: ChangeProductAvailabilityInput) {
        changeProductAvailability(changeProductAvailabilityInput: $changeProductAvailabilityInput) {
            _id
            products {
                _id,
                available
            }
        }
    }
`;

export const CHANGE_CATEGORY_AVAILABILITY = gql `
    mutation changeCategoryAvailability($changeCategoryAvailabilityInput: ChangeCategoryAvailabilityInput) {
        changeCategoryAvailability(changeCategoryAvailabilityInput: $changeCategoryAvailabilityInput) {
            _id
            products {
                _id,
                available
            }
        }
    }
`;

export const REGISTER_TOKEN = gql`
    mutation RegisterFcmToken($fcmToken: String){
        registerFcmToken(fcmToken: $fcmToken)
    }
`;

export const SWITCH_TO_MASHKOR = gql`
  mutation switchSelfToMashkor($orderId: ID, $staffId: String){
    switchSelfToMashkor(orderId: $orderId, staffId: $staffId) {
      _id
      orderType
      vendor
    }
  }
`;


export const CHANGE_STATUS_KMART_ITEM = gql`
    mutation updateKmartOrderItem($itemUpdateInput: ItemUpdateInput!) {
        updateKmartOrderItem(itemUpdateInput: $itemUpdateInput)
}
`;


export const UPDATE_KMART_PRODUCT = gql`
    mutation updateProduct($productId: String!, $updateProductInput: UpdateProductInput!) {
        updateProduct(productId: $productId, updateProductInput: $updateProductInput)
}
`;

export const UPDATE_MENU_ITEM = gql`
    mutation updateMenuItem($updateItemInput: UpdateItemInput!) {
        updateMenuItem(updateItemInput: $updateItemInput)
    }
`;

export const BULK_UPDATE_MENU_PRICES = gql`
    mutation bulkUpdateProductPrices($updates: [ProductPriceUpdateInput]) {
        bulkUpdateProductPrices(updates: $updates)
    }
`;

export const CREATE_A_TASK = gql`
    mutation createTask($taskInput: TaskInput!){
        createTask(taskInput: $taskInput) {
            id
        }
    }
`;

export const CANCEL_TASK = gql`
    mutation updateTaskStatus($id: ID!) {
        updateTaskStatus(id: $id, status: "cancelled"){
            id
        }
    }
`;

export const EDIT_A_TASK = gql`
    mutation editTask($id: ID!, $updatedTask: TaskInput){
        editTask(id: $id, updatedTask: $updatedTask) {
            id
        }
    }
`;

export const UPDATE_ORDER_ITEM = gql`
    mutation updateOrderItem($orderId: ID, $items: [OrderItemsInputs]){
        updateOrderItem(orderId: $orderId, items: $items) {
            _id
            items {
            _id
            name
            oldQuantity
            available
            quantity
            }
        }
    }
`;

export const ADJUST_PRICING = gql`
    mutation adjustOrderPricing($orderId: ID){
        adjustOrderPricing(orderId: $orderId) {
            _id
        }
    }
`;
