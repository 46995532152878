import gql from "graphql-tag";

export const NEW_ORDER = gql`
  subscription NewOrder($storeId: String) {
    newOrder(storeId: $storeId) {
      _id
      timeStamp
      timeStampEta
      storeId
      storeLogo
      storeName
      customerId
      total
      additionalPricingInfo
      orderNumber
      orderNotes
      groceryOnly
      preferredDeliverySlot
      asapDelivery
      orderStatus
      paymentMethod
      orderRating
      items {
        _id
        name
        quantity
        price
        category
        productId
        variantId
        options {
          category
          optionsList {
            name
            price
          }
        }
        oldQuantity
        available
      }
      itemsCoupons {
        _id
        name
        description
        price
        quantity
      }
      customerDeliveryCharge
      orderType
      userAddress {
        addressType
        area
        block
        street
        building
        floor
        apartmentNo
        houseNo
        office
        landmark
        label
        location {
          coordinates
        }
      }
      vendor
      promoCode
      promoInfo {
        isCashback
        value
      }
      userInfo {
        uid
        name
        phoneNumber
      }
      walletdec
      availableVendors
    }
  }
`;

export const ORDER_STATUS_CHANGE = gql`
  subscription OrderStatusChanged($customerId: String, $status: [String], $staffId: String) {
    orderStatusChanged(customerId: $customerId, status: $status, staffId: $staffId) {
      _id
      orderNumber
      orderStatus
      deliveryInfo{
        deliveryOrderStatus{
          status
        }
      }
      deliveryOrderID
    }
  }
`;

export const UPDATE_TASK = gql`
    subscription updateTask($sellerId: String!) {
        updateTask(sellerId: $sellerId){
          id
          orderNumber
          customerName
          customerNumber
          deliveryZone
          additionalAddressDetail
          collectCash
          totalAmount
          specialNotes
          status
          driver {
            name
            phone
            location
          }
          statusHistory {
            status
            timestamp
          }
          completedTime
          deliveryTime
        }
    }
`;
