import React, { useState, useContext } from "react";
import { Button, Modal, Form } from "semantic-ui-react";
import {useTranslation} from 'react-i18next';
import { Context as orderManagementContext } from "../../context/orderManagement";
import './index.css';
import {KATCH_RIDER_BLACK, SELF_DELIVERY_BLACK, KATCH_RIDER_WHITE, SELF_DELIVERY_WHITE} from '../../assets/images';

export const PrepModal = (props) => {
  const {
    onChangeOrderStatus,
    orderNum,
    orderId,
    showDeliveryOptions = false,
    deliveryVendor,
    name,
    availableVendors,
  } = props;

  const [modalOpen, setModalOpen] = useState(false);
  const [isConfirmEnabled, setisConfirmEnabled] = useState(false);
  const [timeSelected, setTimeSelected] = useState(0);

  const onAccept = (event) => {
    setModalOpen(false);
    onChangeOrderStatus(event);
  };
  const onCancel = () => {
    setModalOpen(false);
    setTimeSelected(0);
    setisConfirmEnabled(false)
  };

  const onPrepTimeSelected = (event) => {
    setTimeSelected(event.target.dataset.eta);
    // if(deliveryVendor || !showDeliveryOptions){
    //   setisConfirmEnabled(true);
    // }
      setisConfirmEnabled(true);
  };

  const {t} = useTranslation('common');

  return (
    <Modal
      trigger={<Button className="accept-button" onClick={() => setModalOpen(true)}>{t('accept')}</Button>}
      open={modalOpen}
      onClose={() => setModalOpen(false)}
      style={{ overflow: "hidden" }}
    >
      {showDeliveryOptions?<DeliveryOptions deliveryVendor={deliveryVendor} availableVendors={availableVendors} orderId={orderId}/>:null}
      <Modal.Header>{t('prepTime')}</Modal.Header>
      <Modal.Content className="eta-container">
        {[5, 10, 15, 20, 25, 30, 35, 40].map((time) => (
          <Button
            key={time}
            data-eta={time}
            onClick={onPrepTimeSelected}
            style={
              time === parseInt(timeSelected) ? {backgroundColor: '#00b800', color: '#fff'} : {}
            }
          >
            {t('mins').replace('min', time)}
          </Button>
        ))}
      </Modal.Content>
      <div className="mobile-row-order-buttons">
        <Button 
            className="confirm-button" 
            disabled={!isConfirmEnabled} 
            key={timeSelected}
            name={name}
            data-eta={timeSelected}
            data-order-num={orderNum}
            data-order-id={orderId}
            onClick={onAccept}>
              {t('accept')}
         </Button>
      <Button className="cancel-button" onClick={onCancel}>{t('cancel')}</Button>
      </div>
    </Modal>
  );
};

const DeliveryOptions = ({deliveryVendor,orderId,availableVendors}) =>{
  const { actions } = useContext(
    orderManagementContext
  );
  const {t} = useTranslation('common');
  return availableVendors && <>
    <Modal.Header>{t('deliveryOptions')}</Modal.Header>
    <Modal.Content>
    <Form className="vendor-container">
        {
           availableVendors.map(_vendor => {
             const image = {
               self: SELF_DELIVERY_BLACK,
               mashkor: KATCH_RIDER_BLACK,
             }

             const selectedImage = {
              self: SELF_DELIVERY_WHITE,
              mashkor: KATCH_RIDER_WHITE,
            }

             const buttonText = {
              self: 'Self',
              mashkor: " ",
             }

             const isSelected = deliveryVendor === _vendor ? "selected" : "";

             const onClick = () => actions.setOrderDeliveryOption({orderId:orderId, deliveryVendor:_vendor})

             return (
              <Form.Field key={_vendor}>
                <Button 
                  onClick={onClick}
                  className={`vendor-button ${_vendor} ${isSelected}`}>
                  {image[_vendor] && <img className="vendor-image" src={isSelected ? selectedImage[_vendor] : image[_vendor]} alt="vendor img"/>}
                  <p className="vendor-text">{buttonText[_vendor] ? buttonText[_vendor] : _vendor}</p>
                </Button>
              </Form.Field>
             )
           })
        }
      </Form>
    </Modal.Content>
  </>
}