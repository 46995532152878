import React from 'react';
import Lottie from 'react-lottie';
import {Loading} from '../../assets/Lottie';
import './index.css';

const ProgressLoading = () => {
  
  const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: Loading,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    
      <div className="progress-loading-container">
        <div className="loading-container">
         <Lottie 
          options={defaultOptions}
          height={40}
          width={40}
        />
        </div>
      </div>
    
  );
};

export default ProgressLoading;
