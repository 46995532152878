import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { Button } from "semantic-ui-react";
import moment from 'moment';
import { toLocalTime } from '../Helpers';
import { delivery } from '../../constants/serviceType';
import { katchLogoBase64 } from '../../assets/images/logobase64.json';
import "./index.css";

const PrintOrder = ({ order }) => {
  const orderRef = useRef(undefined);
  const [printing,setPrinting] = useState(false);
  const { userInfo, userAddress } = order ? order : {};
  const { t } = useTranslation(["common", "ordersInfo","orderDetails", "paymentMethods","orderDetails"]);

  const orderDate = toLocalTime(order?.timeStamp, true);
  const orderDateFormated = moment(orderDate).local().format('DD/MM/YY HH:mm');

  const printOrder = () => {
    setPrinting(true);
  };

  useEffect(()=>{
    if(orderRef && orderRef.current && printing){
        document.getElementById("printRoot").innerHTML = orderRef.current.innerHTML;
        window.print();
        setPrinting(false);
    }
  },[orderRef,printing])

  if (order) {
    return (
      <>
        <Button
              content={t("print")}
              icon="print"
              onClick={printOrder}
              size="mini"
              positive
              className="ui button accept-button"
              labelPosition="left"
            />
            {/* Render the componenent only when print is clicked */}
            {printing && <div className="order-info-container hide-details" style={{flex:1,backgroundColor:'white'}} ref={orderRef}>
              <div className="order-info-header">
                <div style={{display:'flex',justifyContent:'center'}}>
                  {/* Image doesn't show up in print sometimes. so using base64 image instead */}
                  <img src={katchLogoBase64} alt="logo" id="print-logo"/>
                </div>
                <div className="order-num-timer-container" style={{marginLeft:'0.8em',marginRight:'0.8em'}}>
                  <span
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "baseline",
                    }}
                  >
                    <p>{t("order")} # </p>
                    <p>&nbsp;{order.orderNumber}</p>
                    </span>
                  <span style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "baseline",
                    }}>
                    <p>Date: </p>
                    <p>&nbsp;{orderDateFormated}</p>
                  </span>
                </div>
                <div className="order-customer-details">
                  <span>
                    <p>{t("orderDetails:customerDetails")}</p>
                    {userInfo ? (
                      <>
                        <p>{userInfo.name}</p>
                        <p dir="ltr">{userInfo.phoneNumber}</p>
                      </>
                    ) : (
                      null
                    )}
                  </span>
                </div>
                {order.orderType === "Delivery" && userAddress? (
                  <div className="order-customer-address" >
                    <AddressDetails address={userAddress} />
                  </div>
                ) : null}
              </div>
              <div className="order-info-items  black bold printerfont">
              {order.itemsCoupons && order.itemsCoupons.map((itemsCoupon,index) => (
                  <div key={index} className="order-items">
                    <span
                      style={{
                        flexDirection:
                          i18next.dir() === "rtl" ? "row-reverse" : "row",
                      }}
                    >
                      <p>{itemsCoupon.quantity}x</p>
                      <p>{itemsCoupon.name}</p>
                      <p>{itemsCoupon.price} KD</p>
                    </span>
                    <span>
                      <p/>
                      <p>{itemsCoupon.description}</p>
                    </span>
                  </div>
                ))}
                {order.items.map((item,index) => (
                  <div key={index} className="order-items">
                    <span
                      style={{
                        flexDirection:
                          i18next.dir() === "rtl" ? "row-reverse" : "row",
                      }}
                    >
                      <p>{item.quantity}x</p>
                      <p>{item.name}</p>
                      {item.quantity > 1 ? 
                        <p>{(item.price * item.quantity).toFixed(3) } KD <small> ({item.price} KD)</small></p>
                        : <p>{item.price} KD</p>}
                    </span>

                    <div className="dish-options-container">
                      {item.options.map((option, index) => (
                        <div key={option.category+index} className="options">
                          <p className="options-category">{option.category}</p>
                          {option.optionsList.map((list) => (
                            <p
                              key={list.name + list.price}
                              className="options-selections"
                            >
                              {list.name}
                            </p>
                          ))}
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
              <div className="order-info-footer">
                <span className="order-notes">
                  {order.orderNotes !== "" && (
                    <>
                      <p>{t("notes")}</p>
                      <p>{order.orderNotes}</p>
                    </>
                  )}
                </span>
                <div className="payment-line-container black bold">
                  <span  className="payment-line black">
                      <p>{t("paymentMethod")}:</p>
                      <p>{t(`paymentMethods:${order.paymentMethod}`)}</p>
                  </span>
                  {order.orderType && 
                      <span className="payment-line">
                      <p>{t("ordersInfo:orderType")}:</p>
                      <p>{order.orderType.toUpperCase()}</p>
                    </span>}
                  {order.promoCode && 
                      <span className="payment-line">
                      <p>{t("orderDetails:promoCode")}:</p>
                      <p>{order.promoCode}</p>
                    </span>}
                  {order.walletdec && 
                      <span className="payment-line">
                      <p>{t("walletdec")}:</p>
                      <p>{order.walletdec} KD</p>
                    </span>}
                  {order.orderType === delivery && (
                          parseFloat(order?.additionalPricingInfo?.totalAddition || 0) > 0 ?
                          <span className="payment-line">
                            <p className="order-customer-address-delivery-title">{t('orderDetails:deliveryCharge')}:</p>
                            <p>{(parseFloat(order?.additionalPricingInfo?.totalAddition) || 0).toFixed(3)} KD</p>
                          </span>:
                           <span className="payment-line">
                            <p className="order-customer-address-delivery-title">{t('orderDetails:deliveryCharge')}:</p>
                            <p className="order-customer-address-delivery-title">{t('orderDetails:freeDelivery')}</p>
                          </span>
                      )}
                  <span className="payment-line">
                    <p>{t("total")}:</p>
                    <p>{order.total} KD</p>
                  </span>
                </div>
              </div>
              <div style={{paddingTop:'20px',paddingBottom:'10px',backgroundColor:"white",textAlign:'center',fontSize:"1.2rem"}}>
                  {`Thank you for ordering from ${order?.storeName} via Katch!`}
              </div>
            </div>}
      </>
    );
  } else {
    return null;
  }
};

const AddressDetails = ({ address }) => {
  const { t } = useTranslation(["common", "ordersInfo"]);
  
  const addressInfo = {
    house: `${address.area}, Block: ${address.block}, Street: ${address.street}, House No.: ${address.houseNo}`,
    apartment: `${address.area}, Block: ${address.block}, Street: ${address.street}, Build: ${address.building}\nFloor: ${address.floor}, Apartment No.: ${address.apartmentNo}`,
    office: `${address.area}, Block: ${address.block}, Street: ${address.street}, Build: ${address.building}\nFloor: ${address.floor}, Office: ${address.office}`,
  };

  return (
    <span>
      <p>{t("ordersInfo:customerAddress")}</p>
      <p>{addressInfo[address?.addressType.toLowerCase()]}</p>
      <p>{address?.landmark?"Landmark : "+address.landmark:""}</p>
    </span>
  );
};


export default PrintOrder;
