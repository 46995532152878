import React, { useState } from "react";
import { Icon, Menu, Sidebar, Button, Image } from "semantic-ui-react";
import MashkorCard from "../../components/MashkorCard";
import { NavLink } from "react-router-dom";
import logoImage from "../../assets/logo/logo.png";
import {useTranslation} from 'react-i18next';
import SettingsButton from "../../components/SettingsButton";
import packageJson from '../../../package.json';
import "./main-layout.css";
import { GET_STORE } from "../../components/GraphQL";
import { useQuery } from "@apollo/react-hooks";
import serviceType from "../../constants/serviceType";
import { useContext } from "react";
import { Context as AppContext } from "../../context/appSettings";

const CustomMenu = ({ to, children, setVisible }) => {
  return (
    <Menu.Item
      as={NavLink}
      to={to}
      exact
      onClick={() => {
        setVisible(false);
      }}
    >
      {children}
    </Menu.Item>
  );
};

const VerticalSidebar = (props) => {
  const { animation, visible, isMobile } = props;
  const {t, i18n} = useTranslation('menu');
  const {isInCollection} = useContext(AppContext);

  return (
    <Sidebar
      className="sidebar"
      as={Menu}
      animation={animation}
      vertical
      direction={i18n.dir() === 'rtl' ? 'right' : 'left'}
      visible={visible}
      width={isMobile ? "wide" : null}
    >
      <CustomMenu to="/" {...props}>
        <Icon name="dashboard" />
        {t('dashboard')}
      </CustomMenu>
      <CustomMenu to="/orders"  {...props}>
        <Icon name="ordered list" />
        {t('orders')}
      </CustomMenu>
      <CustomMenu to="/menu"  {...props}>
        <Icon name="food" />
        {t('menu')}
      </CustomMenu>
      <CustomMenu to="/TrackEdits"  {...props}>
        <Icon name="edit" />
        {t('trackEdits')}
      </CustomMenu>
      {/* {isInCollection && <CustomMenu to="/RequestDriver"  {...props}>
        <Icon name="car" />
        {t('requestDriver')}
      </CustomMenu>} */}
      {isInCollection && <CustomMenu to="/RequestDriverv2"  {...props}>
        <Icon name="car" />
        {t('requestDriver')} 
        {/* <span style={{"padding":"1px 6px","borderRadius":"5px","color":"#fff","background":"#F08080","marginRight":"5px"}}>New </span> */}
      </CustomMenu>}
      <CustomMenu to="/stats"  {...props}>
        <Icon name="clipboard" />
        {t('salesStatistics')}
      </CustomMenu>
      <CustomMenu to="/productManagement"  {...props}>
        <Icon name="product hunt" />
        {t('productManagement')}
      </CustomMenu>

      <SettingsButton {...props}/>
      <MashkorCard/>
      <span id="version-num">v{packageJson.version}</span>
    </Sidebar>
  );
};

const MainLayout = ({ children, signOut }) => {
  const context = useContext(AppContext);
  const [visible, setVisible] = React.useState(false);
  const [store, setStore] = useState(null);
  const {t} = useTranslation('menu');
  useQuery(GET_STORE, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setStore(data.getStore);
    },
    onError: (err) => {console.log("Store error", err)}
  });
  const closeSideMenu = () => {
    setVisible(!visible)
  }

  const childrenWithProps = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
        return React.cloneElement(child, { isSideMenuOpen: visible , closeSideMenu: closeSideMenu });
    }
    return child;
  });


  return (
    <div className="main">
      <nav id="top-nav">
        <Button icon id="menu-hamburger" onClick={() => setVisible(!visible)}>
          <Icon name="bars" />
        </Button>
        <div style={{ margin: 3, fontSize: 18, fontWeight: 600, paddingInlineStart: 12 }}>{store?.shopName}</div>
        <Image id="logo" src={logoImage} size="small" alt="katch-logo" />
        {store && (
          <div style={{ margin: 3, fontSize: 14, fontWeight: 600, paddingInlineEnd: 12 }}>
            {context.deliveryStatus ? <p style={{ color: "green" }}>{t("deliveryEnabled")}</p> : <p style={{ color: "red" }}>{t("deliveryDisabled")}</p>}
          </div>
        )}
      </nav>
      <Sidebar.Pushable>
        <VerticalSidebar visible={visible} animation="overlay" setVisible={setVisible} signOut={signOut} />
        <Sidebar.Pusher className="main-content">
          <div className="content">{childrenWithProps}</div>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    </div>
  );
};

export default MainLayout;
