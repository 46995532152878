import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { GET_APP_PROPERTIES } from "../../components/GraphQL";
import {useTranslation} from 'react-i18next';

const MashkorCard = () => {
  const { data } = useQuery(GET_APP_PROPERTIES);
  const {t} = useTranslation(['common','mashkorPhone']);
  if (data?.getAppProperties?.mashkorPhoneNumber) {
    return (
      <div className="lang-toggle-mashkor">
        <p>{t('mashkorPhone')}</p>
        <span>
          <p>{data?.getAppProperties?.mashkorPhoneNumber}</p>
        </span>
      </div>
    );
  }else{
    return null;
  }
};

export default MashkorCard;
