import { useQuery } from "@apollo/react-hooks";
import React, { useState } from "react";
import { GET_ITEM_EDITS } from "../../components/GraphQL";
import { Table } from "semantic-ui-react";

const TrackEdits = () => {
  const [items, setItems] = useState([]);
  useQuery(GET_ITEM_EDITS, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setItems(data.getPendingItemsBySeller);
      console.log(data);
    },
    onError: (err) => {
      console.log("Store error", err);
    },
  });
  return (
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Item Name</Table.HeaderCell>
          <Table.HeaderCell>Description</Table.HeaderCell>
          <Table.HeaderCell>Category</Table.HeaderCell>
          <Table.HeaderCell>Price</Table.HeaderCell>
          <Table.HeaderCell>Options</Table.HeaderCell>
          <Table.HeaderCell>Approval Status</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {items.map((item) => (
          <Table.Row key={item._id}>
            <Table.Cell>{item.name}</Table.Cell>
            <Table.Cell>{item.description}</Table.Cell>
            <Table.Cell>{item.category}</Table.Cell>
            <Table.Cell>{item.price}</Table.Cell>
            <Table.Cell>
              {item.options.length > 0 ? (
                <ul>
                  {item.options.map((option) => (
                    <li key={option.category}>
                      <strong>{option.category}</strong>:
                      <ul>
                        {option.optionsList.map((opt) => (
                          <li key={opt._id}>
                            {opt.name} ({opt.price})
                          </li>
                        ))}
                      </ul>
                    </li>
                  ))}
                </ul>
              ) : (
                "N/A"
              )}
            </Table.Cell>
            <Table.Cell>
              {item.approved === "Approved" ? (
                <span style={{ color: "green" }}>
                  <i className="circle icon"></i> Approved
                </span>
              ) : item.approved === "Pending" ? (
                <span style={{ color: "orange" }}>
                  <i className="circle icon"></i> Pending
                </span>
              ) : item.approved === "Rejected" ? (
                <span style={{ color: "red" }}>
                  <i className="circle icon"></i> Rejected
                </span>
              ) : (
                <span>N/A</span>
              )}
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};
export default TrackEdits;
