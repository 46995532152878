import React from "react";
import { useTranslation } from "react-i18next";
import "./index.css";

const StatTile = (props) => {
  const {
    title,
    count,
    altText,
  } = props;

  const { t } = useTranslation(["orderStatus"]);

  return (
    <div className={`generic-b tile-border ${title}-b`}>
      <div className={"inner-tile"}>
        <div className={`generic ${title}`} />
        <div className={`textStyle`}>
          <span className={`generic-t ${title}-t`}>{altText? t(altText) : t(title)}:</span> {count}
        </div>
      </div>
    </div>
  );
};

export default StatTile;
