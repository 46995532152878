import React, { useState } from "react";
import { Input } from "semantic-ui-react";

const EditableInput = ({
  content,
  color,
  icon,
  type = "string",
  placeHolder,
  size,
  defaultValue,
  min,
  max,
  onUpdate,
  labelPosition,
}) => {
  const [value, setValue] = useState(defaultValue);

  const handleInputChange = (e) => {
    setValue(e.target.value);
  };
  return (
    <Input
      action={{
        color,
        content,
        icon,
        labelPosition,
        onClick: () => onUpdate(value),
      }}
      placeHolder={placeHolder}
      size={size}
      defaultValue={defaultValue}
      type={type}
      min={min}
      max={max}
      onChange={handleInputChange}
    />
  );
};

export default EditableInput;
