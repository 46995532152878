import React from "react";
import { Form } from 'semantic-ui-react';

export const Filter = ({ column }) => {
  return (
    <div style={{ marginTop: 5 }}>
      {column.canFilter && column.render("Filter")}
    </div>
  );
};

export const DefaultColumnFilter = ({
    column: {
      filterValue,
      setFilter,
      preFilteredRows: { length },
    },
  }) => {
  return (
    <input
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      placeholder={`search...`}
      className={'default-filter'}
    />
  );
};

export const DateFilter = ({
  column: {
    filterValue,
    setFilter,
    preFilteredRows: { length },
  },
  }) => {
  return (
    <input
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      type="date"
      placeholder={`search...`}
      className={'default-filter'}
    />
  );
};


export const SelectColumnFilter = ({
    column: { filterValue, setFilter, preFilteredRows, id, possibleFilters },
  }) => {

  const options = React.useMemo(() => {
    const options = new Set();
    if(possibleFilters && possibleFilters.length){
      possibleFilters.forEach(filter => options.add(filter))
    }else{
      preFilteredRows.forEach((row) => {
        options.add(row.values[id]);
      });
    }
    return [...options.values()];
  }, [id, preFilteredRows, possibleFilters]);

  return (
    <Form.Control as="select"
                  id="custom-select"
                  type="select"
                  value={filterValue}
                  onChange={(e) => {
                    setFilter(e.target.value || undefined);
                  }}
    >
      <option value="">All</option>
      {options.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </Form.Control>
  );
};
