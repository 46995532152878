import { status as constantOrderStatus } from "../../constants/orderStatus";
import constantPaymentMethods from "../../constants/paymentMethods";

const {
  pending,
  accepted,
  declined,
  ready,
  completed,
  incomplete,
  cancelled,
  refunded,
} = constantOrderStatus;
const { KNET, CASH, CREDIT_CARD } = constantPaymentMethods;

const common = {
  busy: "Busy",
  available: "Available",
  total: "Total",
  open: "Open",
  close: "Close",
  refund: "Refund",
  more: "More",
  order: "Order",
  eta: "Eta",
  notes: "Notes",
  name: "Name",
  phone: "Phone number",
  price: "Price",
  qty: "Quantity",
  qtyShort: "QTY",
  back: "Back",
  status: "Status",
  paymentMethod: "Payment Method",
  time: "Time",
  AreYouSure: "Are you sure ?",
  ok: "OK",
  cancel: "Cancel",
  accept: "Accept",
  decline: "Decline",
  ready: "Ready",
  enRoute: "En Route",
  completed: "Completed",
  incomplete: "Incomplete",
  collectCash: "Collect cash",
  mins: "min mins",
  prepTime: "Preparation time",
  viewDetails: "View Details",
  deliveryOptions: "Delivery Options",
  mashkorPhone: "Katch Customer Care",
  orderValue: "Order Value",
  type: "Type",
  orderNo: "Order No",
  customerName: "Customer Name",
  phoneNo: "Phone No",
  address: "Address",
  print: "Print",
  deliveryVendor: "Delivery Vendor",
  deliveryID: "Delivery ID",
  orderStatus: "Order Status",
  changeToMashkor: "Call Katch Driver",
  walletdec: "Wallet Deduction",
  description: "Description",
  allTime: 'Total',
  startDate: 'Start Date',
  endDate: 'End Date',
  submit: 'Submit',
};

const orderDetails = {
  customerDetails: "Customer Details",
  driverDetails: "Driver Details",
  items: "Items",
  orderNotes: "Order Notes",
  deliveryCharge: "Delivery Charge",
  promoCode: "Promo Code",
  cashback: "Cashback",
  freeDelivery:"Free Delivery",
  cartTotal: "Cart Total",
  coupons: "Coupons",
  updateQuantity: "Update Quantity",
  save: "Save",
  reset: "Reset",
  canceItem: "Cancel Item",
  markedCanceled: "Marked Cancelled",
  itemCanceled:"Item Cancelled",
  grocery: "Grocery",
  orderConfirmation: "Order Confirmation"
}

const orders = {
  orderhistory: "Order History",
  orderNum: "Order #",
};

const ordersInfo = {
  customerDetails: "Customer Details",
  overview: "Overview",
  orderRating: "Order Rating",
  orderedItems: "Ordered Items",
  orderType: "Order Type",
  deliveryCharge: "Delivery Charge",
  freeDelivery: "Free Delivery",
  deliveryVendor: "Delivery Vendor",
  customerAddress: "Customer Address"
};

const menu = {
  dashboard: "Dashboard",
  orders: "Orders History",
  menu: "Store Menu",
  signOut: "sign out",
  settings: 'Settings',
  salesStatistics: 'Sales Statistics',
  productManagement: 'Product Management',
  orderStatistics: 'Order Statistics',
  checkUpdate: "Check for update",
  deliveryDisabled: 'Delivery is disabled',
  deliveryEnabled: 'Delivery is Enabled',
  trackEdits: 'Track Edits',
  requestDriver: 'Request Driver',
  requestDriverV2: 'New Request Driver',
};

const salesStat = {
  sales : "Sales",
  weekly: "Weekly",
  monthly: "Monthly",
  annually: "Annually",
  pickup: "Pickup",
  delivery: "Delivery",
  KDCurrency: "KD",
}

const storeMenu = {
  storeMenu: "Store Menu",
  allAvailable: "All Available",
  available: "available",
  downloadMenu:'Download Menu',
};

const dashboard = {
  allOrders:"All Orders",
  pendingOrders:" Pending Orders",
  activeOrders: "Active Orders",
};

const orderStatus = {
  [pending]: "Pending",
  [accepted]: "Accepted",
  [declined]: "Declined",
  [ready]: "Ready",
  [completed]: "Completed",
  [incomplete]: "Incomplete",
  [cancelled]: "Cancelled",
  [refunded]: "Refunded",
  TSA: "Total Sales Amount",
  TSM: "Total Sales Missed",
};

const paymentMethods = {
  [KNET]: "KNET",
  [CASH]: "CASH",
  [CREDIT_CARD]: "VISA/MASTER",
};

const accountSetting = {
  storeName: "Store Name",
  accountSettings: 'Account Settings',
  enabled: 'Enabled',
  disabled: 'Disabled',
  fullScreen: 'Full screen',
  store: 'Store',
  delivery: 'Delivery',
}

const modal = {
  update: {
    title: "NEW UPDATE !",
    body: "There is a new update of the application. Click ok to get the latest features, improvements and bug fixes."
  },
  katchSwitch: {
    title: "Alert",
    body: "Are you sure you want to change the delivery to Katch ? You will not be able to revert this action."
  }
}

const requestDriver = {
  RequestADriver: "Request a Driver",
  OrderNumber: "Order Number",
  CustomerName: "Customer Name",
  CustomerNumber: "Customer Number",
  Zone: "Zone",
  AdditionalAddress: "Additional Address",
  CollectCash: "Collect Cash",
  Cash: "Cash",
  Total: "Total",
  Status: "Status",
  From: "From",
  To: "To",
  RequestedAt: "Requested At",
  CompletedAt: "Completed At",
  showOnlyActive: "Show only active",
  showAll: "Show all",
  cancel: "cancel",
  DriverNumber: "Driver Number",
  DriverName: "Driver Name",
  DriverLocation: "Driver Location",
  StatusLog: "Status Log",
  specialNotes: 'Special Notes',
  ShowOnlyActive: 'Show only Active',
  Refresh: 'Refresh',
  edit: 'Edit',
  cashToBeCollected:'Cash to be collected',
  createdAt:'Created At'
};

export default {
  translation: {
    loginTitle: "Log-in to your account",
    changeTo: "ar",
    changeLang: 'Change language',
    newOrders: "New Orders",
    pending: 'Pending',
    others: "Others",
    orderToPickUp: "Orders to pick up",
    login: "login",
    busyMode: "Busy Mode"
  },
  common,
  modal,
  menu,
  orders,
  ordersInfo,
  storeMenu,
  dashboard,
  orderStatus,
  paymentMethods,
  orderDetails,
  accountSetting,
  salesStat,
  requestDriver,
};
