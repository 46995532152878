import moment from "moment";
import {useLayoutEffect,useState} from 'react'
export const capitalizeFirstLetter = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const toLocalTime = (date, returnUtcDtate = false) => {
  let ISODate;
  const isNotNumber = isNaN(date);
  if (!isNotNumber) {
    ISODate = new Date(parseInt(date)).toISOString();
  } else {
    let parsed = Date.parse(date);
    ISODate = new Date(parseInt(parsed)).toISOString();
  }
  const utcDate = moment.utc(ISODate).toDate();
  const local = moment(utcDate).local().format("DD/MM/YYYY  HH:mm");
  return returnUtcDtate ? utcDate : local;
};

export const timeDifference = (futureDateAndTime) => {

  let difference = futureDateAndTime - new Date().getTime();
  
  const daysDifference = Math.floor(difference/1000/60/60/24);
  
  difference -= daysDifference*1000*60*60*24;

  const hoursDifference = Math.floor(difference/1000/60/60);
  
  difference -= hoursDifference*1000*60*60;

  const minutesDifference = Math.floor(difference/1000/60);

  difference -= minutesDifference*1000*60;

  const secondsDifference = Math.floor(difference/1000);


  if(daysDifference < 0){
    return false
  }else{
    return {
      day:daysDifference,
      hour:hoursDifference,
      min:minutesDifference,
      sec:secondsDifference,
    }
  }
}


export const getPercentage = (value,outOff) => (value * 100) / outOff;

export const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    const updateSize = () => {
      setSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", updateSize);

    updateSize();

    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return size;
};

export const getSelectedLang = (defaultLang = 'en') => {
  const lang = localStorage.getItem('language')
  return lang ? lang : defaultLang;
}


export const errorType = (message) => message.replace(/^.+\[([A-Za-z ]+)\/ .+$/,'$1').trim();

export const parseError = (error) => {
  if (error?.graphQLErrors[0]?.code){
    return error?.graphQLErrors[0]?.message
  }else{
    return false;
  }
}

export const isInStandaloneMode = () =>
  (window.matchMedia('(display-mode: standalone)').matches) || (window.navigator.standalone) || document.referrer.includes('android-app://');