import React from 'react';
import './index.css';

const EmptyMessage = (props) => {
    const {title = "", message = ""} = props;

    return (
        <div className="web-order-table-empty">
            <p className="table-empty-title">{title}</p>
            <p className="table-empty-message">{message}</p>
        </div>       
    );
}

export default EmptyMessage;