// @ts-nocheck
import React, { useEffect, useRef, useState, useContext } from "react";
import {
  Table,
  Dimmer,
  Pagination,
  Grid,
  Icon,
  Button,
} from "semantic-ui-react";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import {
  GET_ORDERS_WITH_PAGINATION,
  CHANGE_ORDER_STATUS,
  GET_ORDER_BY_ID,
} from "../../../components/GraphQL";
import moment from "moment";
import { Confirm } from "semantic-ui-react";
import { store } from "react-notifications-component";
import { useWindowSize } from "../../../components/Helpers";
import ProgressLoading from "../../../components/Loading/ProgressLoading";
import { Context } from "../../../context/notifications";
import { status, PaymentMethods } from "../../../constants";
import {useTranslation} from 'react-i18next';

const isLessThan24Hours = (date) => {
  const hours = moment().diff(new Date(parseInt(date, 10)), "hours");
  return hours < 24;
};

const OrderTable = ({ active, date, history }) => {
  const [getOrders, { loading, data, error }] = useLazyQuery(
    GET_ORDERS_WITH_PAGINATION
  );
  const [changeStatus] = useMutation(CHANGE_ORDER_STATUS);
  const [screenWidth] = useWindowSize();
  const [activePage, setActivePage] = useState(1);
  const [confirmRefund, setConfirmRefund] = useState(false);
  const redundOrderDetails = useRef(null);
  const { actions } = useContext(Context);
  const [refundProgress, setRefundProgress] = useState(false);

  useEffect(() => {
    if (active) {
      getOrders({
        variables: {
          status: [
            "Completed",
            "Incomplete",
            "Declined",
            "Cancelled",
            "Refunded",
          ],
          date: date,
          options: {
            page: activePage,
            limit: 10,
            sort: {
              sortBy: "orderNumber",
              order: -1,
            },
          },
        },
      });
    }
  }, [active, date, activePage, getOrders]);

  //Process Error
  useEffect(() => {
    if (error) {
      store.addNotification({
        title: "error",
        message: `${error}`,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
        },
      });
    }
  }, [error]);
  const handlePaginationChange = (e, { activePage }) => {
    setActivePage(activePage);
  };

  const onRefundInitiated = (order) => {
    redundOrderDetails.current = order;
    setConfirmRefund(true);
  };

  const onRefundConfirmed = async () => {
    setRefundProgress(true);
    try {
      if (redundOrderDetails.current) {
        setConfirmRefund(false);
        const { _id, timeStampEta } = redundOrderDetails.current;
        const OrderStatusInput = {
          orderId: _id,
          status: status.refunded,
          eta: timeStampEta,
        };
        await changeStatus({
          variables: { OrderStatusInput },
          refetchQueries: [
            { query: GET_ORDER_BY_ID, variables: { orderID: _id } },
          ],
        });
      }
    } catch (e) {
      actions.localNotify({
        title: "error",
        message: e.message,
        type: "danger",
        soundType: null,
      });
    } finally {
      setRefundProgress(false);
      redundOrderDetails.current = null;
    }
  };

  const showRefundButton = (order) => {
    return false;
    return (
      order.paymentMethod.toUpperCase() !== PaymentMethods.CASH &&
      (order.orderStatus === status.incomplete ||
        order.orderStatus === status.completed) &&
      isLessThan24Hours(order.timeStamp)
    );
  };
  const {t} = useTranslation(['orders', 'common', 'paymentMethods', 'orderStatus'])

  if (loading || refundProgress) {
    return (
      <Dimmer active inverted>
        <ProgressLoading />
      </Dimmer>
    );
  }

  if (data) {
    const { getSellerOrdersWithPagination } = data;
    const { totalPages, page, docs } = getSellerOrdersWithPagination;
    return (
      <Grid columns={1}>
        <Grid.Column>
          <Table unstackable compact={screenWidth <= 800}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>{t('orderNum')}</Table.HeaderCell>
                <Table.HeaderCell>{t('common:status')}</Table.HeaderCell>
                <Table.HeaderCell>{t('common:time')}</Table.HeaderCell>
                {screenWidth > 800 ? (
                  <>
                    <Table.HeaderCell>{t('common:paymentMethod')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('common:total')}</Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                  </>
                ) : null}
                <Table.HeaderCell></Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {docs.map((order) => {
                return (
                  <Table.Row
                    key={order._id}
                    onClick={() => {
                      if (screenWidth <= 800) {
                        history.push(`/orders/${order._id}`);
                      }
                    }}
                  >
                    <Table.Cell>{order.orderNumber}</Table.Cell>
                    <Table.Cell>
                      {t(`orderStatus:${order.orderStatus}`)}
                      {screenWidth < 800 && showRefundButton(order) ? (
                        <Button
                          compact
                          size="tiny"
                          primary
                          onClick={(event) => {
                            event.stopPropagation();
                            onRefundInitiated(order);
                          }}
                          style={{ display: "block" }}
                        >
                          {t('common:refund')}
                        </Button>
                      ) : null}
                    </Table.Cell>
                    <Table.Cell>
                      {moment(new Date(parseInt(order.timeStamp, 10))).format(
                        "DD/MM/YY hh:mm:ss A"
                      )}
                    </Table.Cell>
                    {screenWidth > 800 ? (
                      <>
                        <Table.Cell> {t(`paymentMethods:${order.paymentMethod}`)}</Table.Cell>
                        <Table.Cell>{order.total}</Table.Cell>
                        <Table.Cell>
                          <Button
                            icon
                            labelPosition="right"
                            secondary
                            onClick={() => {
                              history.push(`/orders/${order._id}`);
                            }}
                          >
                            {t('common:more')}
                            <Icon name="right chevron" />
                          </Button>
                        </Table.Cell>
                      </>
                    ) : (
                      <Table.Cell>
                        <Icon name="right chevron" color="blue" />
                      </Table.Cell>
                    )}
                    {showRefundButton(order) && screenWidth > 800 ? (
                      <Table.Cell>
                        <Button
                          icon
                          labelPosition="right"
                          primary
                          onClick={() => {
                            onRefundInitiated(order);
                          }}
                        >
                          {t('common:refund')}
                          <Icon name="undo" />
                        </Button>
                      </Table.Cell>
                    ) : (
                      <Table.Cell></Table.Cell>
                    )}
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
          <Grid.Column></Grid.Column>
          <Pagination
            activePage={page ? page : 1}
            boundaryRange={3}
            onPageChange={handlePaginationChange}
            size="mini"
            siblingRange={3}
            totalPages={totalPages ? totalPages : 0}
          />
          <Confirm
            open={confirmRefund}
            onCancel={() => setConfirmRefund(false)}
            onConfirm={onRefundConfirmed}
          />
        </Grid.Column>
      </Grid>
    );
  } else {
    return null;
  }
};

export default OrderTable;
