import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { Tab } from "semantic-ui-react";
import { status } from '../../constants/orderStatus';
import Table from '../WebOrderTable';
import "./index.css";

const Tabs = (props) => {
    const { allOrders } = props;
    const {
        pending,
        accepted,
        ready,
        enRoute,
    } = status;
    const [activeDropDown, setActiveDropDown] = useState(-1);

    const {t} = useTranslation('dashboard');

    const panes = [
        {
            menuItem: t('allOrders'),
            render: () => {
                return (
                <Table 
                    activeDropDown={activeDropDown}
                    orders={allOrders}
                    orderStatus ={"*"}
                    setActiveDropDown={setActiveDropDown}
                    emptyTitle=""
                    emptyMessage="There is no order waiting for your action"
                />
            )}
        },
        {
            menuItem: t('pendingOrders'),
            render: () => {
                return (
                    <Table
                        activeDropDown={activeDropDown}
                        orders={allOrders}
                        orderStatus={[pending]}
                        setActiveDropDown={setActiveDropDown}
                        emptyTitle=""
                        emptyMessage="There is no 'Pending' order waiting for your action."
                    />
                )
            }
        },
        {
            menuItem: t('activeOrders'),
            render: () => {
                return (
                <Table
                    activeDropDown={activeDropDown}
                    orders={allOrders}
                    orderStatus={[accepted, ready, enRoute]}
                    setActiveDropDown={setActiveDropDown}
                    emptyTitle=""
                    emptyMessage="All your 'Active' orders have been taken care of."
                />
            )}
        }
    ]

    return (
        <Tab
            panes={panes}
            className="web-tabs"
            onTabChange={() => {
                if(activeDropDown !== -1){
                    setActiveDropDown(-1);
                }
            }}
            renderActiveOnly={true}
        />
    );
}

export default Tabs;