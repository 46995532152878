import React, { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { GET_BULK_UPDATES } from "../../components/GraphQL";
import { Button, Modal, Table } from "semantic-ui-react";

const BulkUpdates = ({ open, setOpen }) => {
  const [items, setItems] = useState([]);
  const [status, setStatus] = useState([]);
  const [updatedAt, setUpdatedAt] = useState([]);
  const [getBulkUpdates, { loading, error }] = useLazyQuery(GET_BULK_UPDATES, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setItems(data.getBulkUpdatesBySeller.products);
      setStatus(data.getBulkUpdatesBySeller.status);
      setUpdatedAt(data.getBulkUpdatesBySeller.updatedAt);
      console.log(data);
    },
    onError: (err) => {
      console.log("Store error", err);
    },
  });

  useEffect(() => {
    if(open){
      getBulkUpdates();
    }
  }, [open]);

  if (loading || error) {
    return null;
  }

  const formattedDate = new Date(parseInt(updatedAt)).toLocaleString();
  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Modal.Header>Updates</Modal.Header>
      <Modal.Content>
        <p>Status: {status}</p>
        <p>Last update at: {formattedDate}</p>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Item Name</Table.HeaderCell>
              <Table.HeaderCell>Description</Table.HeaderCell>
              <Table.HeaderCell>Category</Table.HeaderCell>
              <Table.HeaderCell>Price</Table.HeaderCell>
              <Table.HeaderCell>Options</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {items && items.map((item) => ( // Add null check for items
              <Table.Row key={item._id}>
                <Table.Cell>{item.name}</Table.Cell>
                <Table.Cell>{item.description}</Table.Cell>
                <Table.Cell>{item.category}</Table.Cell>
                <Table.Cell>{item.price}</Table.Cell>
                <Table.Cell>
                  {item.options.length > 0 ? (
                    <ul>
                      {item.options.map((option) => (
                        <li key={option.category}>
                          <strong>{option.category}</strong>:
                          <ul>
                            {option.optionsList.map((opt) => (
                              <li key={opt._id}>
                                {opt.name} ({opt.price})
                              </li>
                            ))}
                          </ul>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    "N/A"
                  )}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Modal.Content>
      <Modal.Actions>
        <Button content="Close" labelPosition="right" icon="close" onClick={() => setOpen(false)} negative />
      </Modal.Actions>
    </Modal>
  );
};

export default BulkUpdates;
