import React, { useState, useEffect } from "react";
import PRing from "../../components/PRing";
import {GET_USER} from '../../components/GraphQL';
import {useLazyQuery} from '@apollo/react-hooks';
import { Placeholder } from 'semantic-ui-react';
import paymentMethods from '../../constants/paymentMethods';
import { useWindowSize } from "../../components/Helpers";
import {useTranslation} from 'react-i18next';

import "./index.css";
import i18next from "i18next";
import { delivery } from "../../constants/serviceType";

const OrderInfo = ({ order, isSideMenuOpen }) => {
  const [userInfo, setUserInfo] = useState(null);
  const [screenWidth] = useWindowSize();

  const [getUserInfo,{data}] = useLazyQuery(GET_USER,{variables:{userId:order.customerId}});

  useEffect(() => {
    if(userInfo?.uid !== order.customerId){
      setUserInfo(null);
      getUserInfo();
    }
    
    if(data){
      setUserInfo(data.getUserInfoFireBase);
    }
    // eslint-disable-next-line
  },[data,order]);

  const {userAddress} = order;
  
  
  const {t} = useTranslation(['common','ordersInfo','paymentMethods'])

  return (!(screenWidth <= 1000 && isSideMenuOpen)?
    <>
      <div className="order-info-container">
        <div className="order-info-header">
          <div className="order-num-timer-container">
            <span>
              <p>{t('order')} #</p>
              <p>{order.orderNumber}</p>
            </span>
            <div>
              {order.orderStatus === "Accepted" && (
                <PRing orderEta={order.timeStampEta} />
              )}
            </div>
          </div>

          <div className="order-customer-details">
            <span>
              <p>{t('ordersInfo:customerDetails')}</p>
              {userInfo ? (
                <>
                  <p>{userInfo.name}</p>
                  <p dir="ltr">{userInfo.phoneNumber}</p>
                </>
              ) : (
                <Placeholder>
                  <Placeholder.Line length="medium" />
                  <Placeholder.Line length="long" />
                </Placeholder>
              )}
            </span>
            <span>
              <p>{t('paymentMethod')}</p>
              <p>{t(`paymentMethods:${order.paymentMethod}`)}</p>
              
            </span>
          </div>
          {
            order.orderType === "Delivery"?
            <div className="order-customer-details">
              <AddressDetails address={userAddress}/>
              <DriverSegment order={order}/>
            </div>
            :null
          }
        </div>
        <div className="order-info-items">
          {order.items.map((item) => (
            <div key={item._id} className="order-items" >
            <span style={{flexDirection: i18next.dir() === 'rtl' ? 'row-reverse' : 'row'}}>
              <p>{item.quantity}x</p>
              <p>{item.name}</p>
              {item.quantity > 1 ? 
                  <p>{(item.price * item.quantity).toFixed(3) } KD <small> ({item.price} KD)</small></p>
                  : <p>{item.price} KD</p>}
            </span>

              <div className="dish-options-container">
                {item.options.map((option,index) => (
                  <div key={option.category} className="options">
                    <p className="options-category">{option.category}</p>
                    {option.optionsList.map((list) => (
                      <p key={list.name + list.price} className="options-selections">{list.name}</p>
                
                    ))}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
        <div className="order-info-footer">
        
            <span>
            {order.orderNotes !== "" && (
              <>
              <p>{t('notes')}</p>
              <p>{order.orderNotes}</p>
              </>
              )}
            </span>
          
          <span>
            <p>{t('total')}</p>
            <p>{order.total}</p>
          </span>

          <span>
          {order.orderType === delivery &&
              (parseFloat(order.additionalPricingInfo?.totalAddition || 0) > 0 ? (
                <div>
                  <span>
                    {t("orderDetails:deliveryCharge")}:{" "}
                    <p>{parseFloat(order.additionalPricingInfo?.totalAddition || 0).toFixed(3)} KD</p>
                  </span>
                </div>
              ) : (
                <div className="web-order-details-free-delivery">
                  <span>{t("orderDetails:freeDelivery")}</span>
                </div>
              ))}
          </span>
        </div>
        {order.paymentMethod === paymentMethods.CASH && parseFloat(order.total) !== 0 && <span className="collect-cash">{t('collectCash')}</span>}      </div>
    </>
    :
    null
  );
};

const AddressDetails = ({address}) => {
  const {t} = useTranslation(['common','ordersInfo'])
  const {addressType} = address;
  let AddressFragment = null;
  if(addressType === "Office"){
    AddressFragment = ()=><>
      <p dir="ltr">Office No : {address.office}</p>
      <p dir="ltr">Floor No : {address.floor}</p>
      <p dir="ltr">Block No : {address.block}</p>
      <p dir="ltr">Street : {address.street}</p>
      <p dir="ltr">Area : {address.area}</p>
    </>
  }
  
  if(addressType === "House"){
    AddressFragment = ()=><>
      <p dir="ltr">House No : {address.houseNo}</p>
      <p dir="ltr">Floor No : {address.floor}</p>
      <p dir="ltr">Block No : {address.block}</p>
      <p dir="ltr">Street : {address.street}</p>
      <p dir="ltr">Area : {address.area}</p>
    </>
  }

  if(addressType === "Apartment"){
    AddressFragment = ()=><>
      <p dir="ltr">Apartment No : {address.apartmentNo}</p>
      <p dir="ltr">Floor No : {address.floor}</p>
      <p dir="ltr">Block No : {address.block}</p>
      <p dir="ltr">Street : {address.street}</p>
      <p dir="ltr">Area : {address.area}</p>
    </>
   }
  return  <span>
    <p>{t('ordersInfo:customerAddress')}</p>
    <AddressFragment/>
  </span>;
}


const DriverSegment = ({ order }) => {
  const {t} = useTranslation(['common','ordersInfo']);
  return (
      <span>
      <p>{t('ordersInfo:driverDetails')}</p>
      <p dir="ltr">{order.driverName}</p>
      <p dir="ltr">{order.driverMobile}</p>
    </span>
  );
};
export default OrderInfo;
