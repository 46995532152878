import React, { useState, Fragment, useEffect, useContext } from "react";
import { Modal, Button, Icon, Radio } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import FullScreenToggle from "../../components/FullScreenToggle";
import { useQuery, useMutation } from "@apollo/react-hooks";
import {
  GET_STORE,
  TOGGLE_STORE,
  GET_SERVICES,
  TOGGLE_BUSY,
} from "../../components/GraphQL";
import serviceType from "../../constants/serviceType";
import "./index.css";
import { Context } from "../../context/updateNotication";
import moment from "moment";
import { Context as AppContext } from "../../context/appSettings";

const ToggleLang = () => {
  const { t, i18n } = useTranslation();
  const lang = t("changeTo") === "ar" ? "Arabic" : "English";
  const toggle = () => {
    const changeTo = t("changeTo");
    localStorage.setItem("language", changeTo);
    i18n.changeLanguage(changeTo);
  };

  return (
    <div className="lang-toggle">
      <p>{t("changeLang")}</p>
      <span>
        <p onClick={toggle}>{lang}</p>
      </span>
    </div>
  );
};

const Settings = (props) => {
  const appContext = useContext(AppContext);
  const { showSettings, toggleModal, signOut } = props;

  const { t } = useTranslation(["common", "menu", "accountSetting"]);
  const [store, setStore] = useState(null);
  const [services, setServices] = useState(null);

  const [isFullScreen, setIsFullScreen] = useState(false);

  const getServiceId = (serviceName) => {
    if (services) {
      const service = services.find((service) => service.name === serviceName);
      return service._id;
    }
    return null;
  };

  const doseHaveDlivery = () => {
    const delvieryId = getServiceId(serviceType.delivery);

    if (store.services && store.services.includes(serviceType.delivery)) {
      return true;
    } else if (
      store.disabledServices &&
      store.disabledServices.includes(delvieryId)
    ) {
      return true;
    }

    return false;
  };

  useQuery(GET_STORE, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setStore(data.getStore);
    },
    onError: (err) => {console.log("Store error", err)}
  });

  useQuery(GET_SERVICES, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setServices(data.getServices);
    },
  });

  const [storeToggle] = useMutation(TOGGLE_STORE);
  const [busyToggle] = useMutation(TOGGLE_BUSY);
  const [busyDuration, setBusyDuration] = useState(0);

  const isDeliveryEnabled = () => {
    return store.services.includes(serviceType.delivery);
  };

  const toggleStore = () => {
    storeToggle({ variables: { status: !store.isOpen } });
    setStore((state) => {
      state.isOpen = !state.isOpen;
      return { ...state };
    });
  };

  const toggleBusy = () => {
    busyToggle({ variables: { status: !store.busyMode, expiry: !store.busyMode
      ? moment()
          .add(busyDuration ?? 60, 'minutes')
          .toISOString()
      : "" } });
      if (store.busyMode) {
        setBusyDuration(0);
      }
    setStore((state) => {
      state.busyMode = !state.busyMode;
      return { ...state };
    });
  };

  const updateContext = React.useContext(Context);
  const [checkingForUpdate, setCheckingForUpdate] = useState(false);
  const busyModeDurations = [10, 15, 20, 30, 45, 60, 120, 180, 240];

  const [busyEnds, setBusymodeEnds] = useState("");

  useEffect(() => {
    setBusymodeEnds(moment(moment.utc(store?.busyModeExpiry).toDate()).local().format("HH:mm:ss - DD/MM/YYYY"));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store?.busyModeExpiry]);

  const checkUpdate = async () => {
    setCheckingForUpdate(true);
    await updateContext.checkNewversion(true);
    setCheckingForUpdate(false)
  };

  if (store) {
    appContext.setDeliveryStatus(doseHaveDlivery() && isDeliveryEnabled());
  }

  return (
    <Modal className="settings-modal" open={showSettings} onClose={() => toggleModal()} style={{ overflow: "hidden" }}>
      <Modal.Header id="modal-header">
        <p>{t("accountSetting:accountSettings")}</p>
        <Button id="close-button" icon={<Icon name="close" size="large" />} onClick={() => toggleModal()} />
      </Modal.Header>
      <Modal.Content>
        {store && (
          <Fragment>
            <div className="section-settings">
              <p>{t("accountSetting:storeName")}: </p>
              <div className="toggler-container">
                <span>{store.shopName}</span>
              </div>
            </div>

            <div className="section-settings">
              <p>{t("common:address")}: </p>
              <div className="toggler-container">
                <span>{store.address}</span>
              </div>
            </div>

            <div className="section-settings">
              <p>{t("accountSetting:store")}: </p>
              <div className="toggler-container">
                <span className={!store.isOpen ? "hide-text" : ""}>{t("open")}</span>
                <Radio toggle id="store-toggler" checked={!store.isOpen} onClick={toggleStore} />
                <span className={store.isOpen ? "hide-text" : ""}>{t("close")}</span>
              </div>
            </div>
            <div className="section-settings">
              <p>{t("accountSetting:busyMode")}: </p>
              <div className="toggler-container">
                <span className={store.busyMode ? "hide-text" : ""}>{t("available")}</span>
                <Radio
                  toggle
                  id="busy-toggler"
                  checked={store.busyMode}
                  disabled={!busyDuration && !store.busyMode}
                  onClick={toggleBusy}
                />
                <span className={!store.busyMode ? "hide-text" : ""}>{t("busy")}</span>
              </div>
            </div>
            <div style={{ flexDirection: "row", flexWrap: "wrap" }}>
                {!store.busyMode ? busyModeDurations.map((duration) => {
                  return (
                    <button
                      onClick={() => {
                        setBusymodeEnds(moment().add(duration, "minutes").format("HH:mm:ss - DD/MM/YYYY"))
                        setBusyDuration((curr) => (curr === duration ? null : duration))
                      }
                      }
                      style={{
                        margin: 5,
                        minWidth: 100,
                        alignItems: "center",
                        padding: 8,
                        borderColor: "#00B800",
                        borderWidth: 1,
                        borderRadius: 5,
                        backgroundColor: busyDuration === duration ? "#00B800" : "white",
                        color: busyDuration === duration ? "white" : "#00B800",
                      }}
                    >
                      {duration > 60 ? `${duration/60} hours` : `${duration} minutes`}
                    </button>
                  );
                }) : <p>Busy mode ends at: {busyEnds}</p>}
              </div>
              <br/>
              <br/>
            {/* {doseHaveDlivery() && (
              <div className="section-settings">
                <p>{t("accountSetting:delivery")}: </p>
                <div className="toggler-container">
                  {<span className={!isDeliveryEnabled() ? "hide-text" : ""}>{t("accountSetting:enabled")}</span>}
                  <Radio toggle id="delviery-toggler" checked={!isDeliveryEnabled()} onClick={toggleDeliveryService} />
                  {<span className={isDeliveryEnabled() ? "hide-text" : ""}>{t("accountSetting:disabled")}</span>}
                </div>
              </div>
            )} */}

            <div className="section-settings">
              <p>{t("accountSetting:fullScreen")}: </p>
              <div className="toggler-container">
                {<span className={!isFullScreen ? "hide-text" : ""}>{t("accountSetting:enabled")}</span>}
                <FullScreenToggle isFullScreen={isFullScreen} setIsFullScreen={setIsFullScreen} />
                {<span className={isFullScreen ? "hide-text" : ""}>{t("accountSetting:disabled")}</span>}
              </div>
            </div>

            <ToggleLang />
          </Fragment>
        )}
        <div className="section-settings">
          <Button fluid id="check-update-button" content={t("menu:checkUpdate")} color="grey" onClick={checkUpdate} disabled={checkingForUpdate} />
        </div>

        <Button fluid id="sign-out-button" icon="sign-out" content={t("menu:signOut")} primary onClick={signOut} />
      </Modal.Content>
    </Modal>
  );
};

export default Settings;
