import React, { useContext, memo, useEffect } from "react";

//3red party

//others
import OrderInfo from "../../containers/OrderInfo";
import MobileTab from "../../containers/MobileTab";
import WebTab from "../../containers/WebTab";
import { useWindowSize } from "../../components/Helpers";
import { Context as orderManagementContext } from "../../context/orderManagement";

import "./index.css";
import { Button, Select } from "semantic-ui-react";

const Home = (props) => {
  const [screenWidth] = useWindowSize();
  const { allOrders, viewOrder, actions, storeData, multipleOutlet } = useContext(
    orderManagementContext
  );

  useEffect(() => {
    if(multipleOutlet) {
      actions.setSelectedOutlets(storeData.linkedOutlets)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeData, multipleOutlet]);

  return (
    <>
      <div style={{display: 'flex', flexDirection: 'row-reverse'}}>
        {storeData?.mainOutlet && (
          <div className="right floated left aligned six wide column">
          <Select multiple 
          onChange={(e, data) => actions.setSelectedOutlets(data.value) }
          defaultValue={storeData.linkedOutlets}
          options={storeData.linkedOutletsName.map(x => ({key: x._id, value:x._id, text: x.shopName}))} />
          </div>
        )}
          <Button className="ui button positive" onClick={() => actions.setRefresh(Date.now)}>
            Refresh Orders
          </Button>
      </div>
      {screenWidth > 800 ? (
        <>
          <WebTab
            allOrders={allOrders}
            onChangeOrderStatus={actions.onChangeOrderStatus}
          />
          {viewOrder && (
            <OrderInfo
              order={viewOrder}
              isSideMenuOpen={props.isSideMenuOpen}
            />
          )}
        </>
      ) : (
        <MobileTab
          allOrders={allOrders}
          onChangeOrderStatus={actions.onChangeOrderStatus}
        />
      )}
    </>
  );
};



export default memo(Home);
