import { useLazyQuery } from "@apollo/react-hooks";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dimmer, Tab } from "semantic-ui-react";
import { GET_STORE_STATS } from "../../components/GraphQL";
import ProgressLoading from "../../components/Loading/ProgressLoading";
import StatTileList from "../../containers/StatTileList";
import "chart.js/auto";
import { Chart } from "react-chartjs-2";
import "./index.css";
import StatTile from "../../components/StatTile";


const orderDict = ['Completed', 'Declined', 'Incomplete'];
const sort = (a, b) => {
  const aKey = a[Object.keys(a)[0]];
  const bKey = b[Object.keys(b)[0]];
  return orderDict.indexOf(aKey) - orderDict.indexOf(bKey);
};
const DashboardTab = ({ error, statsData, statsloading, chartData, deliveryPickupData }) => {
  const { t } = useTranslation(["menu", "common"]);
  const [chartType, setChartType] = useState("bar");
  const filteredArray = statsData ? statsData.stats.filter((x) => orderDict.includes(x.status)).sort((a, b) => sort(a, b)) : null;

  return (
    <main className="stat-main">

      {error ? (
        <ErrorMessage message={error} />
      ) : statsData && filteredArray && !statsloading ? (
        <Fragment>
          <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
            <div>
              {/* <StatTile title={t("common:allTime")} count={statsData.totalNumOfOrder} /> */}
              <StatTileList list={[{ status: t("common:allTime"), count: statsData.totalNumOfOrder }, ...filteredArray]} />
            </div>

            <div>
              {chartData.labels && (
                <div className={chartType === "bar" ? "bar-chart" : "pie-chart"}>
                  <select onChange={(e) => setChartType(e.target.value)}>
                    <option value="pie">Pie</option>
                    <option value="bar" selected>
                      Bar
                    </option>
                  </select>
                  <Chart
                    type={chartType}
                    data={chartData}
                    options={{
                      plugins: {
                        legend: {
                          display: false,
                        },
                      },
                    }}
                  ></Chart>
                </div>
              )}
            </div>
          </div>

          {deliveryPickupData ? (
            <Fragment>
              {(deliveryPickupData.datasets.filter((x) => x.label === "Delivery")[0].data[0] > 0 || deliveryPickupData.datasets.filter((x) => x.label === "Pickup")[0].data[0] > 0) && 
              <div style={{ width: "600px", height: "100px" }}>
                <Chart
                  type="bar"
                  data={deliveryPickupData}
                  options={{
                    maintainAspectRatio: false,
                    indexAxis: "y",
                    scales: {
                      x: {
                        grid: {
                          display: false,
                        },
                        display: false,
                      },
                      y: {
                        grid: {
                          display: false,
                          drawBorder: false,
                        },
                      },
                    },
                    elements: {
                      bar: {
                        borderWidth: 0,
                      },
                    },
                    responsive: true,
                    plugins: {
                      legend: {
                        position: "right",
                      },
                    },
                  }}
                />
              </div>}
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <p style={{ margin: 12, fontSize: 16, fontWeight: 500 }}>
                  {t("salesStat:delivery")}: {deliveryPickupData.datasets.filter((x) => x.label === "Delivery")[0].data[0] || 0}
                </p>
                <p style={{ margin: 12, fontSize: 16, fontWeight: 500 }}>
                  {t("salesStat:pickup")}: {deliveryPickupData.datasets.filter((x) => x.label === "Pickup")[0].data[0] || 0}
                </p>
              </div>
            </Fragment>
          ) : (
            <p></p>
          )}
        </Fragment>
      ) : (
        <Dimmer active inverted>
          <ProgressLoading />
        </Dimmer>
      )}
    </main>
  );
};

const PeriodSelection = ({setStartDate, selectedPeriod, setSelectedPeriod}) => {
  const { t, i18n } = useTranslation(["salesStat"]);
  return (
    <div className="container">
      <div className="tabs">
        <label className="tab" style={{ color: selectedPeriod === "weekly" ? "white" : undefined }}>
          <input
            type="radio"
            id="radio-1"
            name="tabs"
            onChange={() => {
              setSelectedPeriod("weekly");
              setStartDate(moment().subtract(7, "days"));
            }}
          />
          {t("salesStat:weekly")}
        </label>
        <label className="tab" style={{ color: selectedPeriod === "monthly" ? "white" : undefined }}>
          <input
            type="radio"
            id="radio-2"
            name="tabs"
            onChange={() => {
              setSelectedPeriod("monthly");
              setStartDate(moment().subtract(1, "months"));
            }}
          />
          {t("salesStat:monthly")}
        </label>
        <label className="tab" style={{ color: selectedPeriod === "annually" ? "white" : undefined }}>
          <input
            type="radio"
            id="radio-3"
            name="tabs"
            onChange={() => {
              setSelectedPeriod("annually");
              setStartDate(moment().subtract(1, "years"));
            }}
          />
          {t("salesStat:annually")}
        </label>
        <span className={`glider ${selectedPeriod === "weekly" ? (i18n.dir() === "ltr" ? "first" : "firstAR") : selectedPeriod === "monthly" ? (i18n.dir() === "ltr" ? "second" : "secondAR") : i18n.dir() === "ltr" ? "third" : "thirdAR"}`}></span>
      </div>
    </div>
  );
}

const SalesTab = ({error, statsData, statsloading}) => {
  const { t } = useTranslation(["menu", "salesStat"]);
  const statDict = ['Declined', 'Completed'];

  return (
    <main className="stat-main">
      {error ? (
        <ErrorMessage message={error} />
      ) : (!statsloading &&
        <div>
          <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", maxWidth: "70vw" }}>
            {statsData?.salesStat?.filter((x) => statDict.includes(x.status)).map((x) => (
              <div style={{ marginTop: 10 }}>
                <StatTile title={x.status} count={parseFloat(x.total).toFixed(3) + " " + t('salesStat:KDCurrency')} altText={x.status === "Completed" ? "TSA" : x.status === "Declined" ? "TSM" : null} />
              </div>
            ))}
          </div>
          {(statsData?.salesStat.length === 0 || statsData?.salesStat?.filter(x => (statDict.includes(x.status))).length === 0) && <p style={{margin: 40}}>No Data</p>}
        </div>
      )}
    </main>
  );
};


const Stats = () => {
  const { t } = useTranslation(["menu", "salesStat"]);
  const [statsData, setStatsData] = useState(null);
  const [error, setError] = useState(null);
  const [chartData, setChartData] = useState({});
  // const [refreshing, setRefreshing] = useState(false);
  const [startDate, setStartDate] = useState(
    moment().utc().set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
  );
  const [endDate, setEndDate] = useState(moment().utc());
  const [selectedPeriod, setSelectedPeriod] = useState("weekly");
  const [deliveryPickupData, setDeliveryPickupData] = useState(null);

  useEffect(() => {
    onGetRestStats(startDate, endDate);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);


  const [getRestStats, { loading: statsloading }] = useLazyQuery(GET_STORE_STATS, {
    fetchPolicy: "network-only",
    onCompleted: ({ getRestaurantStats }) => {
      if (getRestaurantStats) {
        const { data, message, success } = getRestaurantStats;
        if (success) {
          setChartData({
            labels: data.stats.filter(x => orderDict.includes(x.status)).sort((a, b) => sort(a, b)).map((x) => x.status),
            datasets: [
              {
                data: data.stats.filter(x => orderDict.includes(x.status)).sort((a, b) => sort(a, b)).map((x) => x.count || 0),
                backgroundColor: [
                  "rgba(0, 183, 0, 0.2)",
                  "rgba(251, 70, 41, 0.2)",
                  "rgba(255, 195, 43, 0.2)",
                  "rgba(163, 51, 200, 0.2)",
                  "rgba(0, 181, 172, 0.2)",
                  "rgba(019, 184, 255, 0.2)",
                ],
                borderColor: [
                  "#00b701",
                  "#fb4629",
                  "#ffc32b",
                  "#a333c8",
                  "#00b5ad",
                  "#13b8ff",
                ],
                borderWidth: 1,
              },
            ],
          });
          setDeliveryPickupData({
              labels: [""],
              datasets: [
                {
                  label: 'Delivery',
                  data: [data.orderTypeStats?.filter(x => x.orderType === "Delivery")[0]?.total],
                  borderColor: 'rgba(0, 181, 172, 0.6)',
                  backgroundColor: 'rgba(0, 181, 172, 0.7)',
                  stack: 0,
                  barThickness: 50, 
                },
                {
                  label: 'Pickup',
                  data: [data.orderTypeStats?.filter(x => x.orderType === "Pickup")[0]?.total],
                  borderColor: 'rgba(0, 183, 0, 0.3)',
                  backgroundColor: "rgba(0, 183, 0, 0.3)",
                  stack: 0,
                  barThickness: 50, 
                }
              ]
          });
          setStatsData(data);
        } else {
          if (message) {
            setError(message);
          }
        }
      }
      // setRefreshing(false);
    },
    onError: () => {
      setError("Oops something went wrong");
    },
  });

  const onGetRestStats = (startDate, endDate, scrollViewLoading) => {
    // if (scrollViewLoading) setRefreshing(true);
    getRestStats({
      variables: {
        input: {
          startDate,
          endDate,
        },
      },
    });
  };

  useEffect(() => {
    onGetRestStats(startDate, endDate);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const panes = [
    {
      menuItem: t("menu:orderStatistics"),
      render: () => {
        return <DashboardTab error={error} statsData={statsData} statsloading={statsloading} chartData={chartData} deliveryPickupData={deliveryPickupData} />;
      },
    },
    {
      menuItem: t("salesStat:sales"),
      render: () => {
        return <SalesTab error={error} statsData={statsData} statsloading={statsloading} />;
      },
    },
  ];

  return (
    <div className="statsContainer">
      <h1 className="stat-h1">{t("salesStatistics")}</h1>
      <div
        style={{
          lineHeight: "2em",
          padding: "12px",
          justifyContent: "center",
          display: "flex",
          flex: 1,
          width: "100%",
          fontSize: "1.2em",
          gap: "20px",
          marginBottom: "20px",
        }}
      >
        {t("common:startDate")}: <input type="date" value={startDate.toISOString().split("T")[0]} onChange={(e) => setStartDate(moment(e.target.value))} defaultValue={new Date().toISOString().split("T")[0]} />
        {t("common:endDate")}: <input type="date" onChange={(e) => setEndDate(moment(e.target.value))} defaultValue={new Date().toISOString().split("T")[0]} />
      </div>
      <PeriodSelection setStartDate={setStartDate} selectedPeriod={selectedPeriod} setSelectedPeriod={setSelectedPeriod} />
      <br/>
      <Tab panes={panes} className="stat-tabs" renderActiveOnly={true} />
    </div>
  );
};

const ErrorMessage = (props) => {
  const { message } = props;
  return (
    <div style={{}}>
      <p style={{}}>{message}</p>
    </div>
  );
};

export default Stats;
