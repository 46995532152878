import React, { useContext, useState, useMemo, useEffect } from "react";
import { Context as KmartContext } from "../../../context/kmartcontext";
import { Button, Label, Table } from "semantic-ui-react";
import TableContainer from "../../Table/index";
import { useMutation } from "@apollo/react-hooks";
import { CHANGE_STATUS_KMART_ITEM } from "../../GraphQL";
import moment from "moment";

const KmartOrders = (props) => {
  const kmartContext = useContext(KmartContext);
  const [updateItemStatus] = useMutation(CHANGE_STATUS_KMART_ITEM);
  const status = {
    awaiting_confirmation: "Awaiting Confirmation",
    confirmed: "Confirmed",
    cancelled: "Cancelled",
    picked_up: "Picked Up",
  };
  const changeStatus = async (item, status) => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm(`${status} Order of item: ${item.name}`)) {
      const data = await updateItemStatus({ variables: { itemUpdateInput: {
        itemOrderId: item._id,
        status
      } } });
      kmartContext.getVendorOrder();


    }
  };

  useEffect(() => {
  },[kmartContext.orders]);

  const stringToColour = function(str) {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    var colour = '#';
    for (var i = 0; i < 3; i++) {
      var value = (hash >> (i * 8)) & 0xFF;
      colour += ('00' + value.toString(16)).substr(-2);
    }
    return colour;
  }

  const restColumns = useMemo(
    () => [
      {
        Header: "Order Id",
        disableFilters: true,
        accessor: (data) => {
          return <span style={{color: stringToColour(data.orderId), fontWeight: 'bold'}}>
            {data.orderId}
          </span>
        },
      },
      {
        Header: "Customer Name",
        disableFilters: true,
        accessor: (data) => <span>{data.order?.customerName}</span>,
      },{
        Header: "Order Date",
        disableFilters: true,
        accessor: (data) => moment(data.createdAt).format("MM/DD - hh:mm")
      },
      {
        Header: "Item Name",
        disableFilters: true,
        accessor: (d) => <b>{d.name}</b>,
      },
      {
        Header: "Item options",
        disableFilters: true,
        accessor: (d) => <div><b>{d.options.label}</b> <p>{d.options.price}</p></div>,
      },
      {
        Header: "notes",
        disableFilters: true,
        accessor: (d) => <p>{d.notes}</p>,
      },
      {
        Header: "Price",
        disableFilters: true,
        accessor: "price",
      },
      {
        Header: "Quantity",
        disableFilters: true,
        accessor: "quantity",
      },
      {
        Header: "Total",
        disableFilters: true,
        accessor: "total",
      },
      {
        id: "status",
        disableFilters: true,
        Header: "Status",
        accessor: (d) => (
          <Label
            color={
              d.status === "awaiting_confirmation"
                ? "orange"
                : d.status === "cancelled"
                ? "red"
                : d.status === "confirmed"
                ? "green"
                : "blue"
            }
          >
            {status[d.status] ? status[d.status] : <span style={{textTransform: 'capitalize'}}> {d.status?.replaceAll("_", " ")}</span>}
          </Label>
        ),
      },
      {
        // id: "Action",
        Header: "Action",
        disableFilters: true,

        accessor: (order) =>
          order.status === "awaiting_confirmation" ? (
            <>
              <Button
                color="green"
                onClick={() => changeStatus(order, "confirmed")}
              >
                Confirm This Item
              </Button>{" "}
              <Button
                color="orange"
                onClick={() => changeStatus(order, "cancelled")}
              >
                Cancel This Item
              </Button>
            </>
          ) : null,
      },
    ],
    []
  );
  return <>
  <div><Button color="blue" onClick={() => kmartContext.getVendorOrder()}> Refresh</Button>
  <Button color="yellow" disabled={kmartContext.showPending} onClick={() => kmartContext.togglePendingCompleted(true)}> Show Pending orders</Button>
  <Button color="green" disabled={!kmartContext.showPending} onClick={() => kmartContext.togglePendingCompleted(false)}> Show Completed orders</Button>
  
  
  </div> <hr/> 
  <TableContainer loading={kmartContext.orderLoading} data={kmartContext.orders} columns={restColumns} /></>;
  // return <><pre>{kmartContext.vendor ? JSON.stringify(kmartContext.vendor, null, 2) : ''}</pre>
  // <pre>{kmartContext.orders ? JSON.stringify(kmartContext.orders, null, 2) : ''}</pre></>
};

export default KmartOrders;
