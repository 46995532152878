import React from "react";
import moment from "moment";
import { Card, Grid, List, Segment, Divider, Header } from "semantic-ui-react";

import { getCellTheme } from "../../utils/table";
import { calculateTravelDistance, convertSeconds } from "../../utils/helpers";

const AddressTravelDetails = ({
  addressDetails,
  travelDetail,
  driver,
  statusHistory,
  tookanwebhookrequest,
}) => {
  return (
    <Card
      fluid
      style={{ border: "none", boxShadow: "none", background: "none" }}
    >
      <Card.Content>
        <Card.Header>Details</Card.Header>
        <Card.Description>
          <Grid>
            <Grid.Row>
              {(travelDetail || driver?.name) && (
                <Grid.Column width={5}>
                  <Segment>
                    <Header as={"h4"}>Travel Details:</Header>
                    {driver?.name && (
                      <Grid.Column width={5}>
                        <Segment>
                          <strong>Driver Information:</strong>
                          <List divided={true} relaxed={"very"}>
                            <List.Item>
                              <strong>Name:</strong> {driver.name}
                            </List.Item>
                            <List.Item>
                              <strong>Phone:</strong> {driver.phone}
                            </List.Item>
                            {driver?.trackingLink && (
                              <List.Item>
                                <strong>Tracking Link:</strong>{" "}
                                <a
                                  href={driver.trackingLink}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {driver.trackingLink}
                                </a>
                              </List.Item>
                            )}
                          </List>
                        </Segment>
                      </Grid.Column>
                    )}

                    {travelDetail && (
                      <List divided={true} relaxed={"very"}>
                        <List.Item>
                          <strong>Estimated Total Distance:</strong>{" "}
                          {calculateTravelDistance(travelDetail.totalDistance)}{" "}
                          Km
                        </List.Item>
                        <List.Item>
                          <strong>Estimated Time to deliver:</strong>{" "}
                          {convertSeconds(travelDetail.duration)}
                        </List.Item>
                        {/* <List.Item>
                        <strong>Duration in Traffic:</strong>{" "}
                        {convertSeconds(travelDetail.durationInTraffic)}
                      </List.Item> */}
                        {/* <List.Item>
                        <strong>Has Traffic:</strong>{" "}
                        {travelDetail.hasTraffic ? "Yes" : "No"}
                      </List.Item> */}
                        {/* <List.Item>
                        <strong>Estimated Delay by Traffic:</strong>{" "}
                        {convertSeconds(travelDetail.estimatedDelayByTraffic)}
                      </List.Item> */}
                      </List>
                    )}
                  </Segment>
                </Grid.Column>
              )}

              {statusHistory?.length > 0 && (
                <Grid.Column width={5}>
                  <Segment>
                    <Header as={"h4"}>Status History:</Header>
                    {statusHistory.map((logItem, index) => (
                      <div
                        key={logItem.timestamp}
                        style={{ marginBottom: "0.5em" }}
                      >
                        <p>
                          <span style={{ color: getCellTheme(logItem.status) }}>
                            {logItem.status}{" "}
                          </span>
                          {logItem.timestamp
                            ? `: ${moment(parseInt(logItem.timestamp)).format(
                                "YYYY-MM-DD HH:mm:ss",
                              )}`
                            : ""}
                        </p>
                        {index !== statusHistory.length - 1 && <Divider />}
                      </div>
                    ))}
                  </Segment>
                </Grid.Column>
              )}

              {tookanwebhookrequest && (
                <Grid.Column width={5}>
                  <Segment>
                    <Header as={"h4"}> Tookan Request Information:</Header>
                    <List divided={true} relaxed={"very"}>
                      <List.Item>
                        <strong>Arrived Date & Time:&nbsp;</strong>
                        {tookanwebhookrequest.arrived_datetime}
                      </List.Item>
                      <List.Item>
                        <strong>Started Date & Time:&nbsp;</strong>
                        {tookanwebhookrequest.started_datetime}
                      </List.Item>
                      <List.Item>
                        <strong>Completed Date & Time:&nbsp;</strong>
                        {tookanwebhookrequest.completed_datetime}
                      </List.Item>
                      <List.Item>
                        <strong>Acknowledged Date & Time:&nbsp;</strong>
                        {tookanwebhookrequest.acknowledged_datetime}
                      </List.Item>
                      <List.Item>
                        <strong>Is Merchant:&nbsp;</strong>
                        {tookanwebhookrequest.is_merchant ? "Yes" : "No"}
                      </List.Item>
                      <List.Item>
                        <strong>Fleet Name:&nbsp;</strong>
                        {tookanwebhookrequest.fleet_name}
                      </List.Item>
                      <List.Item>
                        <strong>Fleet Phone:&nbsp;</strong>
                        {tookanwebhookrequest.fleet_phone}
                      </List.Item>
                      <List.Item>
                        <strong>Fleet Location:&nbsp;</strong>
                        {`${tookanwebhookrequest.fleet_latitude}, ${tookanwebhookrequest.fleet_longitude}`}
                      </List.Item>
                      <List.Item>
                        <strong>Transport Type:&nbsp;</strong>
                        {tookanwebhookrequest.transport_type === 2
                          ? "Bike"
                          : "Other"}
                      </List.Item>
                      <List.Item>
                        <strong>Total Distance:&nbsp;</strong>
                        {tookanwebhookrequest.total_distance}
                      </List.Item>
                    </List>
                  </Segment>
                </Grid.Column>
              )}
            </Grid.Row>
          </Grid>
        </Card.Description>
      </Card.Content>
    </Card>
  );
};

export default AddressTravelDetails;
