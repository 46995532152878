import React from "react";
import PulseLoader from "react-spinners/PulseLoader";
import "./index.css";

export default ({ children, active = false }) => {
  return (
    <div className="spinner-container">
      {active && (
        <div className="spinner">
          <PulseLoader color={"#00b800"} loading={true} size={10}/>
        </div>
      )}
      {children}
    </div>
  );
};
