import React, { useState, useEffect } from "react";
import { Dropdown, Grid, Segment } from "semantic-ui-react";
import { useLazyQuery } from "@apollo/react-hooks";
import { FIND_ADDRESSES_QUERY } from "../GraphQL";
import INITIAL_REQUEST from "../../constants/driverRequest";

const KW_LEVELS = [
  { key: "governate", placeholder: "Select Governate" },
  { key: "neighborhood", placeholder: "Select Area" },
  { key: "block", placeholder: "Select Block", prefix: "Block" },
  { key: "street", placeholder: "Select Street", prefix: "" },
  { key: "house", placeholder: "Select House", prefix: "House" },
];

const encodeDropdownValue = (address) => {
  return address.split(/-(.+)/)[1];
};

const KwFinder = ({ onLocationSet, clearFilter }) => {
  const [values, setValues] = useState(Array(KW_LEVELS.length).fill(null));
  const [options, setOptions] = useState(KW_LEVELS.map(() => []));
  const [activeLevel, setActiveLevel] = useState(0);

  const [fetchAddresses, queryLoading] = useLazyQuery(FIND_ADDRESSES_QUERY, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (data?.findAddresses?.addresses) {
        const fetchedOptions = data.findAddresses.addresses.map(
          (address, index) => {
            const addressText = address[KW_LEVELS[activeLevel].key];
            const prefix = KW_LEVELS[activeLevel].prefix;

            return {
              text: prefix ? `${prefix}-${addressText || "N/A"}` : addressText,
              value:
                address.location.coordinates.join(",") + "-" + addressText ||
                "N/A",
              key: index,
            };
          },
        );

        const newOptions = [...options];
        newOptions[activeLevel] = fetchedOptions;
        setOptions(newOptions);
      }
    },
    onError: (error) => {
      console.error("Error fetching options:", error);
    },
  });

  useEffect(() => {
    loadOptions({
      level: KW_LEVELS[0].key,
    });
  }, []);

  useEffect(() => {
    if (clearFilter) {
      setValues(Array(KW_LEVELS.length).fill(null));
      setActiveLevel(0);
    }
  }, [clearFilter]);

  const constructFilterFromValues = (level, filters, newValue) => {
    const tempArray = [...Array(level + 1)].map((_, index) => level - index);
    tempArray.forEach((_, index) => {
      const val = newValue[index];
      if (val && index !== level)
        filters[KW_LEVELS[index].key] = encodeDropdownValue(val);
    });
  };

  const handleDropdownChange = (level, selectedValue) => {
    const newValues = [...values];
    newValues[level] = selectedValue;
    const tempOptions = [...options];
    for (let i = level + 1; i < newValues.length; i++) {
      newValues[i] = null;
      tempOptions[i] = [];
    }

    setValues(newValues);
    setActiveLevel(level + 1);
    setOptions(tempOptions);
    dispatchValue(newValues);
  };

  const dispatchValue = (newAddress) => {
    const lastNonNullValue = newAddress.filter((val) => !!val).pop();
    if (lastNonNullValue) {
      const address = lastNonNullValue.split(",");

      onLocationSet({
        latitude: parseFloat(address[1]),
        longitude: parseFloat(address[0]),
        fullAddress: newAddress
          .filter(Boolean)
          .map((val) => encodeDropdownValue(val)),
      });
    } else {
      onLocationSet({
        latitude: INITIAL_REQUEST.deliveryLocation.latitude,
        longitude: INITIAL_REQUEST.deliveryLocation.longitude,
        fullAddress: [],
      });
    }
  };

  const loadOptions = (filters) => {
    fetchAddresses({
      variables: {
        filters,
      },
    });
  };

  const handleDropdownInteraction = (level) => {
    setActiveLevel(level);

    if (options[level].length === 0) {
      const filters = {};
      constructFilterFromValues(level, filters, values);
      filters["level"] = KW_LEVELS[level].key;
      loadOptions(filters);
    }
  };

  return (
    <Segment
      padded
      basic
      style={{ border: "1px solid #ddd", borderRadius: "4px" }}
    >
      <Grid columns={5} divided>
        <Grid.Row>
          {KW_LEVELS.map((levelConfig, level) => (
            <Grid.Column key={level} width={4} style={{ padding: "5px" }}>
              <Dropdown
                fluid
                search
                selection
                scrolling
                clearable
                closeOnChange
                value={values[level]}
                options={options[level]}
                lazyLoad
                loading={queryLoading.loading && level === activeLevel}
                placeholder={levelConfig.placeholder}
                disabled={
                  (!values[level - 1] && values.length && level > 1) ||
                  (queryLoading.loading && activeLevel !== level)
                }
                onFocus={() => handleDropdownInteraction(level)}
                onOpen={() => {
                  setActiveLevel(level);
                }}
                onChange={(e, { value }) => {
                  handleDropdownChange(level, value);
                }}
                onSearchChange={(e, { searchQuery }) =>
                  handleDropdownInteraction(level)
                }
              />
            </Grid.Column>
          ))}
        </Grid.Row>
      </Grid>
    </Segment>
  );
};

export default KwFinder;
