import { auth } from "firebase";
import React, { useState } from "react";
import { IS_IN_COLLECTION } from "../components/GraphQL/queries";
import { useQuery } from "@apollo/react-hooks";

export const Context = React.createContext();

export const Provider = (props) => {
  const [deliveryStatus, setDeliveryStatus] = useState(null);
  const [isInCollection, setIsInCollection] = useState(false);
  const user = auth().currentUser;
  useQuery(IS_IN_COLLECTION, {
    variables: {
      storeId: user.uid,
    },
    onCompleted: (data) => {
      setIsInCollection(data.checkStoreInCollection);
    }
  });
  const value = {
      deliveryStatus,
      setDeliveryStatus,
      isInCollection,
    }
  return <Context.Provider value={value}>{props.children}</Context.Provider>;
}