import React, { useContext, useState, useMemo, useEffect } from "react";
import { Context as KmartContext } from "../../../context/kmartcontext";
import { Button, Input, Label, Table } from "semantic-ui-react";
import TableContainer from "../../Table/index";
import { useMutation } from "@apollo/react-hooks";
import { CHANGE_STATUS_KMART_ITEM, UPDATE_KMART_PRODUCT } from "../../GraphQL";
import moment from "moment";
import EditableInput from "../components/EditableInput";

const KmartItems = (props) => {
  const kmartContext = useContext(KmartContext);
  const [updateItemStatus] = useMutation(CHANGE_STATUS_KMART_ITEM);
  const [updateProduct] = useMutation(UPDATE_KMART_PRODUCT);
  const status = {
    awaiting_confirmation: "Awaiting Confirmation",
    confirmed: "Confirmed",
    cancelled: "Cancelled",
    picked_up: "Picked Up",
  };
  const modifyProduct = async (product, updateProductInput) => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm(`Update ${product.name}`)) {
      const data = await updateProduct({ variables: { 
          productId: product._id.toString(),
          updateProductInput,
       } });
       kmartContext.getVendorItems()
    }
  };

  useEffect(() => console.log(kmartContext.items), [kmartContext.items])
  const restColumns = useMemo(
    () => [
      {
        Header: "Name",
        disableFilters: true,
        accessor: "name",
      },{
        Header: "Price",
        disableFilters: true,
        accessor: "price",
      },{
        Header: "Description",
        disableFilters: true,
        accessor: "description",
      },{
        Header: "Categories",
        disableFilters: true,
        accessor: "categories",
      },{
        Header: "stock",
        disableFilters: true,
        accessor: (data) => {
          return <Table.Cell>
            <EditableInput 
              color={'teal'}
              icon={'cart'}
              type={'number'}
              content={'Update Stock'}
              min={0}
              max={100}
              defaultValue={data.stock}
              placeHolder={'Ex: 10'}
              size={'mini'}
              onUpdate={(x) => modifyProduct(data, {stock: x})}
              labelPosition={'right'}
            />
          </Table.Cell>
        },
      },
    ],
    []
  );
  return <><div><Button color="blue" onClick={() => kmartContext.getVendorItems()}> Refresh</Button></div> <hr/> 
  <TableContainer loading={kmartContext.itemsLoading} data={kmartContext.items} columns={restColumns} /></>;
  // return <><pre>{kmartContext.vendor ? JSON.stringify(kmartContext.vendor, null, 2) : ''}</pre>
  // <pre>{kmartContext.orders ? JSON.stringify(kmartContext.orders, null, 2) : ''}</pre></>
};

export default KmartItems;
