import React, { useState, useEffect } from "react";
import { Button, Form, Grid, Header, Segment } from "semantic-ui-react";
import { connect } from "react-firebase";
import { HOME, KMARTORDERS } from "../../constants/routes";
import { Redirect } from "react-router-dom";
import ProgressLoading from "../../components/Loading/ProgressLoading";
import {useTranslation} from 'react-i18next';
import './index.css';

const LoginForm = ({ login, currentUser, location, history, currentUserRecord }) => {
  const INITIAL_STATE = {
    email: "",
    password: "",
  };

  const [inputValue, setInputValue] = useState(INITIAL_STATE);
  
  const INITIAL_STATE_ERROR = {
    email: '',
    password: '',
  };

  const [errors, setErrors] = useState(INITIAL_STATE_ERROR);
  const [isProgressing, setIsProgressing] = useState(false);

  const onSubmit = async (event) => {
    event.preventDefault();
    setIsProgressing(true)
    setErrors(INITIAL_STATE_ERROR);
    const { email, password } = inputValue;

    login(email, password)
      .then(() => {
        const { from } = location.state || { from: { pathname: HOME } };
        history.push(from);
      })
      .catch((error) => {
        setErrors({ error });
        setIsProgressing(false)
        if(error.code === 'auth/invalid-email'){
          setErrors({
            email: 'The email address is badly formatted.'
          });
        }

        if(error.code === 'auth/user-not-found'){
          setErrors({
            email: "The email address that you've entered doesn't match any account."
          });
        }

        if(error.code === 'auth/wrong-password'){
          setErrors({
            password: 'The password is invalid'
          });
        }

        if(error.code === 'auth/too-many-requests'){
          setErrors({
            password: 'Contact support for help.'
          });
        }
      });
  };

  const onChange = (event) => {
    const values = { ...inputValue };
    const name = event.target.name;
    const text = event.target.value;
    values[name] = text;
    setInputValue(values);
  };

  const {t} = useTranslation();

  return !currentUser() ? (
    <>
      <Grid
        textAlign="center"
        style={{ height: "100vh" }}
        verticalAlign="middle"
      >
        <Grid.Column style={{ maxWidth: 450 }}>
          <Header as="h2" style={{ color: "#00b800" }} textAlign="center">
            {/* Log-in to your account */}
            {t('loginTitle')}
          </Header>
          <Form size="large">
            <Segment>
            <div>

              <Form.Input
                fluid
                name="email"
                type="text"
                icon="user"
                iconPosition="left"
                placeholder="E-mail address"
                value={inputValue.email}
                onChange={onChange}
              />
              <p className="error-message">{errors.email}</p>
            </div>
            <div>

              <Form.Input
                fluid
                name="password"
                value={inputValue.password}
                onChange={onChange}
                icon="lock"
                iconPosition="left"
                placeholder="Password"
                type="password"
              />

               <p className="error-message">{errors.password}</p>
              
               </div>

              <Button
                onClick={onSubmit}
                style={{ backgroundColor: "#00b800", color: "#fff" }}
                fluid
                size="large"
              >
                {t('login')}
              </Button>
            </Segment>
          </Form>
        </Grid.Column>
      </Grid>
      {isProgressing && <ProgressLoading />}
    </>
  ) : (
    <Redirect
      to={{
        pathname: HOME,
      }}
    />
  );
};

const mapFirebaseToProps = (_, __, firebase) => ({
  login: (email, password) =>
    firebase.auth().signInWithEmailAndPassword(email, password),
  currentUser: () => firebase.auth().currentUser,
  currentUserRecord: firebase.auth().currentUser,
});

export default connect(mapFirebaseToProps)(LoginForm);
