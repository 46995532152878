//react
import { onError } from '@apollo/client/link/error';
//3rd party
import { ApolloProvider as ApolloHooksProvider } from "@apollo/react-hooks";
import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import { ApolloLink, split } from "apollo-link";
import { setContext } from "apollo-link-context";
import { HttpLink } from "apollo-link-http";
import { WebSocketLink } from "apollo-link-ws";
import { getMainDefinition } from "apollo-utilities"; //others
import firebase from "firebase/app";
//css
import "normalize.css";
import React, { useEffect, useState } from "react";
import "react-circular-progressbar/dist/styles.css";
import ReactDOM from "react-dom";
import FirebaseProvider from "react-firebase/Provider";
import { I18nextProvider } from "react-i18next";
import "react-notifications-component/dist/theme.css";
import "semantic-ui-less/semantic.less";
import { SubscriptionClient } from "subscriptions-transport-ws";
import i18next from "../src/services/i18n";
import App from "./components/App/index.jsx";
//others
import { firebaseApp } from "./components/Firebase";
import Loading from './components/Loading/ProgressLoading';
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import typeDefs from "./components/GraphQL/typeDefs";


const __DEV__ = process.env.NODE_ENV === "development";

const {
  REACT_APP_BASE_URL_LOCAL,
  REACT_APP_WS_URL_LOCAL,
  REACT_APP_APIURL_ADMIN,
  // eslint-disable-next-line
  REACT_APP_BASE_URL,
  // eslint-disable-next-line
  REACT_APP_WS_URL,
  // eslint-disable-next-line
  REACT_APP_RAW_URL,
  // eslint-disable-next-line
  REACT_APP_RAW_WS,
  // eslint-disable-next-line
  REACT_APP_RAW_DEV_URL,
  // eslint-disable-next-line
  REACT_APP_RAW_DEV_WS,
} = process.env;

const getURL = (dev = __DEV__) => {
  switch (dev) {
    case true:
      // eslint-disable-next-line no-console
      console.log('STARTING LOCAL ENV DEV ======>>>>>>><<<<<==========', {
        httpUrl: REACT_APP_BASE_URL_LOCAL,
        wsUrl: REACT_APP_WS_URL_LOCAL,
      });
      // eslint-disable-next-line no-console
      return {
        httpUrl: REACT_APP_BASE_URL_LOCAL,
        wsUrl: REACT_APP_WS_URL_LOCAL,
        adminUrl: REACT_APP_APIURL_ADMIN,
      };
    default:
      return {
        httpUrl: REACT_APP_BASE_URL_LOCAL,
        wsUrl: REACT_APP_WS_URL_LOCAL,
        adminUrl: REACT_APP_APIURL_ADMIN,
      };
  }
};

const AppHOC = (props) => {
  const [loading, setLoading] = useState(true);
  const { httpUrl, wsUrl } = getURL(true);

  const subscriptionClient = new SubscriptionClient(wsUrl, {
    lazy: true,
    reconnect: true,
    connectionParams: async () => {
      const token = (await firebase.auth()?.currentUser?.getIdToken()) || null; //
      if(!token) throw new Error('Missing Auth')
      return {
        authorization: token,
      };
    },
  });
  const getSubscriptionLink = () => {
    return new WebSocketLink(subscriptionClient);
  };

  const httpLink = new HttpLink({ uri: httpUrl, credentials: 'same-origin' });
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      const errors = graphQLErrors.map(({ message, locations, path }) => {
        return `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
          locations,
        )}, Path: ${path}`;
      });

      if (!__DEV__) {
        const err = new Error(
          JSON.stringify({ isFatal: false, error: errors }),
        );
        console.log(err);
      } else {
        // eslint-disable-next-line no-console
        console.log('errorLink -> graphQLErrors', errors);
      }
    }

    if (networkError && !__DEV__) {
      const err = new Error(
        JSON.stringify({
          isFatal: false,
          error: `[Network error]: ${networkError}`,
        }),
      );
      console.log(err);
    } else if (networkError) {
      // eslint-disable-next-line no-console
      console.log(`[Network error]: ${networkError}`);
    }
  });

  const createSplitLink = () => {
    return split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        );
      },
      getSubscriptionLink(),
      // @ts-ignore
      ApolloLink.from([errorLink, httpLink]),
    );
  };


  const requestMiddleware = () => {
    return setContext(async (_, { headers }) => {
      // const appVersion = VersionCheck.getCurrentVersion();
      const token = firebase.auth().currentUser
        ? await firebase.auth().currentUser.getIdToken()
        : null;
      const customHeader = {
        headers: {
          ...headers,
          authorization: token,
        },
      };
      return customHeader;
    });
  };

  useEffect(() => {
    const listner = firebase.auth().onAuthStateChanged((authUser) => {
      if (!authUser) {
        subscriptionClient.close();
        setLoading(true);
      }
      setLoading(false);

    });
    return () => {
      listner();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createApolloClient = () => {
    const middleware = requestMiddleware();
    const splitLink = createSplitLink();
    return new ApolloClient({
      link: middleware.concat(splitLink),
      cache: new InMemoryCache({ addTypename: false }),
      typeDefs,
    });
  };



  if (!loading) {
    return (
      <FirebaseProvider firebaseApp={firebaseApp}>
      <ApolloHooksProvider client={createApolloClient()}>
        <I18nextProvider i18n={i18next}>
          <App client={createApolloClient()} firebase={firebase}/>
        </I18nextProvider>
      </ApolloHooksProvider>
    </FirebaseProvider>
    );
  } else {
    return <Loading />
    ;
  }
}

ReactDOM.render(<AppHOC />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
