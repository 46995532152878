import React, { Fragment } from "react";
import {
  useTable,
  usePagination,
  useSortBy,
  useFilters,
  useExpanded,
} from "react-table";
import { Dimmer, Loader, Segment, Table, Image } from "semantic-ui-react";
import { DefaultColumnFilter, Filter } from "./Filter";
import useDebounce from "./debounce";
import "./style.css";
import { Pagination } from "semantic-ui-react";
import { Form } from "semantic-ui-react";
import { BsArrowDownShort, BsArrowUpShort } from "react-icons/all";

const TableContainer = ({
  columns,
  data,
  fetchData = () => {},
  loading,
  pageCount: controlledPageCount,
  totalData,
  renderRowSubComponent = () => null,
  defaultPageSize,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    visibleColumns,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    // Get the state from the instance
    state: { pageIndex, pageSize, sortBy, filters },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 }, // Pass our hoisted table state
      manualPagination: true, // Tell the usePagination
      manualSortBy: true,
      manualFilters: true,

      autoResetSortBy: false,
      defaultColumn: { Filter: DefaultColumnFilter },
      // hook that we'll handle our own data fetching
      // This means we'll also have to provide our own
      // pageCount.
      pageCount: controlledPageCount,
    },
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  );
  const debounceFilter = useDebounce(filters, 1000);
  // Listen for changes in pagination and use the state to fetch our new data
  React.useEffect(() => {
    fetchData({ pageIndex, pageSize, sortBy, filters: debounceFilter });
  }, [fetchData, pageIndex, pageSize, sortBy, debounceFilter]);
  // Render the UI for your table
  return true ? (
    <>
      <div className={"tableFixHead table-striped"}>
        <Table
          celled
          selectable
          hover={true}
          {...getTableProps()}
          //   className={loading ? "table-loading" : null}
        >
          <Table.Header>
            {headerGroups.map((headerGroup, index) => {
              const headerKey = `${
                headerGroup.getHeaderGroupProps().key
              } + ${index}`;
              const trProps = {
                ...headerGroup.getHeaderGroupProps(),
                key: headerKey,
              };
              return (
                <Table.Row {...trProps}>
                  {headerGroup.headers.map((column) => (
                    <Table.HeaderCell {...column.getHeaderProps()}>
                      <div {...column.getSortByToggleProps()}>
                        {column.render("Header")}
                        {/* Add a sort direction indicator */}
                        <span>
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <BsArrowDownShort />
                            ) : (
                              <BsArrowUpShort />
                            )
                          ) : (
                            ""
                          )}
                        </span>
                      </div>
                      <Filter column={column} />
                    </Table.HeaderCell>
                  ))}
                </Table.Row>
              );
            })}
          </Table.Header>
          <Table.Body {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <Fragment key={row?._id}>
                  <Table.Row>
                    {row.cells.map((cell, i) => {
                      return (
                        <Table.Cell
                          {...cell.getCellProps()}
                          key={row.getRowProps().key + i}
                        >
                          {cell.render("Cell")}
                        </Table.Cell>
                      );
                    })}
                  </Table.Row>
                  {row.isExpanded && (
                    <Table.Row>
                      <Table.Cell colSpan={visibleColumns.length}>
                        {renderRowSubComponent(row)}
                      </Table.Cell>
                    </Table.Row>
                  )}
                </Fragment>
              );
            })}
          </Table.Body>
        </Table>
      </div>
      {loading ? (
        <Segment>
          <Dimmer active inverted>
            <Loader size="mini">Loading</Loader>
          </Dimmer>

          <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
        </Segment>
      ) : null}
      {/* 
      <span>
           Total {totalData}
      </span> */}

      {/* <div className="pagination">
        <Pagination>
          <Pagination.First onClick={() => gotoPage(0)}
                            disabled={!canPreviousPage}/>
          <Pagination.Prev  onClick={() => previousPage()}
                            disabled={!canPreviousPage}/>
          {
            [...Array(pageCount)].map((a, index) => {
              return (
              <Fragment key={index}>
                {
                  (index < pageIndex + 10) && (index > pageIndex - 10) ?
                    <Pagination.Item active={index === pageIndex} onClick={() => gotoPage(index)}>
                      {index + 1}
                    </Pagination.Item>
                  :
                    ""
                }
              </Fragment>
              );
            })
          }
          <Pagination.Next  onClick={() => nextPage()}
                            disabled={!canNextPage}/>
          <Pagination.Last onClick={() => gotoPage(pageCount - 1)}
                           disabled={!canNextPage}/>
        </Pagination>
        <Form.Group controlId="exampleForm.SelectCustom">
          <Form.Control as="select"
                        value={pageSize}
                        onChange={(e) => {
                          setPageSize(Number(e.target.value));
                        }}
                        custom>
            {[5, 10, 20, 30, 40, 50, 100].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
      </div> */}
    </>
  ) : (
    <h1>Test</h1>
  );
};

export default TableContainer;
