import React, { useState } from 'react';
import {Redirect,Route} from 'react-router-dom'
import {SIGN_IN} from '../../constants/routes';
import { connect } from 'react-firebase'

const PrivateRoute = ({ currentUser, signOut, component: Component, layout: Layout, ...rest }) => {
  const [isSignedOut, setIsSignedOut] = useState(false);

  const handleSignOut = () => {
    setIsSignedOut(true);
    signOut();
  };

  return (
    <Route
      {...rest}
      render={(props) =>
        currentUser() && !isSignedOut ? (
          <Layout signOut={handleSignOut}>
            <Component {...props} />
          </Layout>
        ) : (
          <Redirect to={SIGN_IN} push={false} />
        )
      }
    />
  );
};

const mapFirebaseToProps = (_,__,firebase) => ({
  currentUser: () => firebase.auth().currentUser,
  signOut: () => firebase.auth().signOut(),
});
 
export default connect(mapFirebaseToProps)(PrivateRoute);
