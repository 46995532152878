import React, { useRef, useState } from "react";
import { Button, Form, Modal ,Message} from "semantic-ui-react";

const ChangePriceModal = ({ open, setOpen, onPriceChange,loading }) => {
  const priceInput = useRef(null);
  const [error, setError] = useState(false);

  const onSave = () => {
    if (priceInput && priceInput.current) {
      if (!priceInput.current.value) {
        setError(true);
      } else {
        setError(false);
        onPriceChange(priceInput.current.value);
      }
    }
  };
  return (
    <Modal
      open={open}
    >
      <Modal.Header>Change Price</Modal.Header>
      <Modal.Content>
        <Form loading={loading}>
          <Form.Field error={error}>
            <label>New Price</label>
            <input placeholder="price" ref={priceInput} type="number" />
            <Message
              visible={error}
              error
              content="Price cannot be empty."
            />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          content="Cancel"
          labelPosition="right"
          icon="close"
          onClick={() => setOpen(false)}
          negative
          disabled={loading}
        />
        <Button
          content="Save"
          labelPosition="right"
          icon="checkmark"
          onClick={onSave}
          positive
          disabled={loading}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default ChangePriceModal;
