import React from "react";

import { Icon, Input, Table } from "semantic-ui-react";
import { DebounceInput } from "react-debounce-input";

/**
 * A table header cell component that can be sorted and includes a search input field.
 *
 * @param {Object} props - The properties object.
 * @param {string} props.column - The name of the column associated with this header cell.
 * @param {Object} props.sortColumn - The current sorting state containing the column and direction.
 * @param {function} props.handleSort - The function to handle sorting when the header cell is clicked.
 * @param {string} props.label - The label displayed in the header cell.
 * @param {function} [props.handelSearch] - Optional. The function to handle search input changes.
 *
 * @returns {JSX.Element} - The rendered header cell with sorting and optional search functionality.
 */
const SortableHeaderCell = ({
  className,
  column,
  sortColumn,
  handleSort,
  label,
  handelSearch,
  width,
  searchFilterValue,
  breakLabel,
}) => (
  <Table.HeaderCell
    width={width}
    className={className}
    sorted={sortColumn.column === column ? sortColumn.direction : null}
    onClick={(e) => handleSort(e, column)}
  >
    <span className={breakLabel ? "break-word " : ""}>{label}</span>
    <br />
    {handelSearch && (
      <DebounceInput
        element={Input}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
        icon={{ name: "search" }}
        value={searchFilterValue}
        onChange={handelSearch}
        placeholder="Search..."
        size="mini"
      />
    )}
  </Table.HeaderCell>
);

export default SortableHeaderCell;
