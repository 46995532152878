import React, { useEffect, useState } from 'react';
import { delivery, pickUp } from '../../constants/serviceType';
import { useTranslation } from 'react-i18next';
import './index.css';
import { Fragment } from 'react';
import NP from 'number-precision';

const MobileOrderDetails = (props) => {
  const {
    order
  } = props;

  const { t } = useTranslation(['common', 'orderDetails']);
  const [itemCategory, setItemCategory] = useState({});
  const [cartTotal, setCartTotal] = useState('');
  const calculateCartTotal = (order) => {
    let sum = 0;
    order.items.filter(item => !(item.available !== null && item.available === false)).forEach((x) => {
      sum = NP.plus(sum, NP.times(parseFloat(x.quantity), parseFloat(x.price)));
    });
    setCartTotal(`${sum.toFixed(3)}`);
  };

  useEffect(() => {
    if (order) {
      calculateCartTotal(order);
    }
    const _itemCategory = {};
    order.items.forEach(x => {
        (_itemCategory[x.category || ""] || (_itemCategory[x.category || ""] = [])).push(x)
    })
    setItemCategory(_itemCategory)
  }, [order.items, order])
  const createUserAddress = () => {
    if(!order.userAddress || order.orderType === pickUp){
      return null;
    }

    const address = order.userAddress;
    const addressType = order.userAddress.addressType.toLowerCase();
    const addressInfo = {
      house: `${address.area}, Block: ${address.block}, Street: ${address.street}, House No.: ${address.houseNo}`,
      apartment: `${address.area}, Block: ${address.block}, Street: ${address.street}, Build: ${address.building}\nFloor: ${address.floor}, Apartment No.: ${address.apartmentNo}`,
      office: `${address.area}, Block: ${address.block}, Street: ${address.street}, Build: ${address.building}\nFloor: ${address.floor}, Office: ${address.office}`,
    };
    return( <span>{t('address')}: <p>{addressInfo[addressType]}</p></span>);
  }

  return (
    <div className="mobile-order-details">
      <div className="mobile-order-details">
        <div className="mobile-order-details-active-drop">
          <span className="mobile-order-details-section-title">{t('orderDetails:customerDetails')}</span>
          <span>{t('name')}: <p>{order.userInfo.name}</p></span>
          <span>{t('phone')}: <p>{order.userInfo.phoneNumber?.replace(/\+965(.+)/, '$1')}</p></span>
          {createUserAddress()}
        {order.deliveryOrderID && order.orderType === delivery &&
        <div className="mobile-order-details-driver">
          <span className="mobile-order-details-section-title">{t('orderDetails:driverDetails')}</span>
          {order.deliveryOrderID && <span>{t('deliveryID')}: <p>{order.deliveryOrderID}</p></span>}
          {order.deliveryInfo.driverName && <span>{t('name')}: <p>{order.deliveryInfo.driverName}</p></span>}
          {order.deliveryInfo.driverMobile && <span>{t('phone')}: <p>{order.deliveryInfo.driverMobile?.replace(/\+965(.+)/, '$1')}</p></span>}
        </div>}
        {order.itemsCoupons && Object.keys(order.itemsCoupons).length > 0 && <span className="mobile-order-details-section-title">
            {t("orderDetails:coupons")}
          </span>}
          {order.itemsCoupons && order.itemsCoupons.map((item) => (
              <div key={item._id} className="order-items">
                <span>
                  <p>{item.quantity}x</p>
                  <p>{item.name}</p>
                  {item.quantity > 1 ? 
                    <p>{(item.price * item.quantity).toFixed(3) } KD <small> ({item.price} KD)</small></p>
                    : <p>{item.price} KD</p>}
                </span>
                <span>
                  <p>{item.description}</p>
                </span>
              </div>
          ))}
        {Object.keys(itemCategory).length > 0 &&<span className="mobile-order-details-section-title">{t('orderDetails:items')}</span>}
        {Object.keys(itemCategory).sort((a,b) => a === '').map((categoryName, index) => {
            return (
              <Fragment key={index}>
                <span>{categoryName}</span>
                {itemCategory[categoryName].map((item) => (
                  <div key={item._id} className="order-items">
                    <span>
                      <p>{item.quantity}x</p>
                      <div>
                        <p>{item.name}</p>
                        <p style={{ color: '#E2775F', fontWeight: 'bold' }}>({item.productId})</p>
                      </div>
                      <p>{item.price} KD</p>
                    </span>
                    <div className="dish-options-container">
                      {item.options.map((option, index) => (
                        <div key={option.category} className="options">
                          <span>
                            {option.category}:{" "}
                            <p className="options-selections-name">
                              {option.optionsList
                                .map((list) => list.name)
                                .join(", ")}
                            </p>
                          </span>
                        </div>
                      ))}
                    </div>
                    <div>
                      {item.available !== null && item.available === false ? <span style={{ color: 'red' }}>{t("orderDetails:itemCanceled")}</span> : null}
                    </div>
                  </div>
                ))}
                
              </Fragment>
            );
          })}
          <div>
          <div className="mobile-order-details-total-container">
          {order.promoCode && (
                <div>
                <span>
                  {t("orderDetails:promoCode")}: {" "}
                  <p>{order.promoCode}</p>
                </span>
                </div>
              )}
          {order.orderType && (
                <div>
                <span>
                  {t('ordersInfo:orderType')}: 
                  <p>{order.orderType.toUpperCase()}</p>
                </span>
                </div>
              )}
          {cartTotal && (
                <div>
                <span>
                  {t("orderDetails:cartTotal")}: {" "}
                  <p>{cartTotal} KD</p>
                </span>
                </div>
              )}
            {order.orderType === delivery && (
                parseFloat(order.additionalPricingInfo?.totalAddition || 0) > 0 ?
                <div>
                  <span>{t('orderDetails:deliveryCharge')}: 
                  <p>{parseFloat(order.additionalPricingInfo?.totalAddition || 0).toFixed(3)} KD</p>
                  </span>
                </div>:
                <div className="mobile-order-details-free-delivery">
                  <span>{t('orderDetails:freeDelivery')}</span>
                </div>
            )}
            {order.promoCode && (
                    <div>
                    <span>
                      {order.promoInfo.isCashback?  t("orderDetails:cashback") : t("orderDetails:promoCode")}({order.promoCode}): {" "}
                      <p>{order.promoInfo.value ? parseFloat(order.promoInfo.value).toFixed(3) : "0.000"} KD</p>
                    </span>
                    </div>
                  )}
                  {order.walletdec && (
                    <div>
                    <span>
                      {t('walletdec')}: {" "}
                      <p>{parseFloat(order.walletdec).toFixed(3)} KD</p>
                    </span>
                    </div>
                  )}
            <div>
              <span>{t('total')}: <p>{order.total} KD</p></span>
              </div>
            </div>          
          </div>
          {
          (order.orderNotes || order.promoCode) && 
            <div className="mobile-order-details-notes">
              <span>{t('orderDetails:orderNotes')}</span>
              <p>{order.orderNotes}</p>
            </div>
          }
        </div>
      </div>
    </div>
  );
}

export default MobileOrderDetails;