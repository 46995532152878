import React from "react";
import StatTile from "../../components/StatTile";
import './index.css';

const StatTileList = (props) => {
  const { list = [] } = props;
  return (
    <ul className="stat-list">
      {list.map((stat) => {
        return (
          <li className="stat-list-item" key={stat.status}>
            <StatTile title={stat.status} count={stat.count} />
          </li>
        );
      })}
    </ul>
  );
};

export default StatTileList;
