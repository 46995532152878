import React, { useContext, Fragment } from 'react';
import { Tab, Modal, Button } from "semantic-ui-react";
import Loading from '../../components/Loading/ProgressLoading';
import MobileOrderRow from '../../components/MobileOrderRow';
import EmptyMessage from './emptyMessage';
import { Context as orderManagementContext } from '../../context/orderManagement';
import './index.css';



const Table = (props) => {
    const {
        orders,
        activeDropDown,
        setActiveDropDown,
        orderStatus,
        emptyTitle,
        emptyMessage,
    } = props;

    const { orderModal, hideOrderModal } = useContext(orderManagementContext);

    if(orders === null){
        return <div className="mobile-order-table-loading-container">
            <Loading/>
        </div>
    }

    const ordersFiltered = orderStatus !== '*' ? orders.filter((order) => orderStatus.includes(order.orderStatus)) : orders;

    if(ordersFiltered.length < 1){
        return (
        <EmptyMessage
            title={emptyTitle}
            message={emptyMessage}
        />)
    }


    return (
        <Fragment>
            <Modal open={orderModal.show}>
                <Modal.Header>{orderModal.title}</Modal.Header>
                <Modal.Content>{orderModal.message}</Modal.Content>
                <Modal.Actions>
                    <Button onClick={hideOrderModal}>OK</Button>
                </Modal.Actions>
            </Modal>
            <Tab.Pane>
                <ul className="mobile-order-table">
                    {ordersFiltered.map((order, index) => {
                        return(
                        <MobileOrderRow 
                            order={order}
                            key={order._id}
                            activeDropDown={activeDropDown}
                            index={index}
                            setActiveDropDown={setActiveDropDown}
                        />
                        );
                    })}
                </ul>
            </Tab.Pane>
        </Fragment>
    );
}

export default Table;