import React, { useState } from "react";
import { Tab } from "semantic-ui-react";
import {useTranslation} from 'react-i18next';
import { status } from "../../constants/orderStatus";
import Table from '../MobileOrderTable';
import "./index.css";

const MobileTab = ({allOrders, onChangeOrderStatus}) => {
  const {
    pending,
    accepted,
    ready,
    enRoute,
    pickedUp,
} = status;
  const [activeDropDown, setActiveDropDown] = useState(0);
  const {t} = useTranslation('dashboard');

  const panes = [
    {
        menuItem: t('allOrders'),
        render: () => (
            <Table 
                activeDropDown={activeDropDown}
                orders={allOrders}
                orderStatus ={"*"}
                setActiveDropDown={setActiveDropDown}
                emptyTitle=""
                emptyMessage="There is no order waiting for your action"
            />
        )
    },
    {
        menuItem: t('pendingOrders'),
        render: () => (
            <Table
                activeDropDown={activeDropDown}
                orders={allOrders}
                orderStatus={[pending]}
                setActiveDropDown={setActiveDropDown}
                emptyTitle=""
                emptyMessage="There is no 'Pending' order waiting for your action."
            />
        )
    },
    {
        menuItem: t('activeOrders'),
        render: () => (
            <Table
                activeDropDown={activeDropDown}
                orders={allOrders}
                orderStatus={[accepted, ready, enRoute, pickedUp]}
                setActiveDropDown={setActiveDropDown}
                emptyTitle=""
                emptyMessage="All your 'Active' orders have been taken care of."
            />
        )
    }
]
  return <Tab panes={panes} className="mobile-tabs" />;
};

export default MobileTab;
