import React, { useState } from "react";
import { Icon } from "semantic-ui-react";
import { useTranslation } from 'react-i18next';
import SettingsScreen from '../../page/Settings';
import './index.css';

const SettingsButton = (props) => {
  const {
    setVisible,
    signOut
  } = props;
  const [showSettings, setShowSettings] = useState(false);
  const { t } =useTranslation('menu');
  
  const toggleModal = () => {
    setVisible(false);
    setShowSettings(state => !state);
  }
  
  return (
    <React.Fragment>
      <a onClick={toggleModal} className={"item"} href>
        <Icon name="setting" className="settings-icon" />
        <span>{t("settings")}</span>
      </a>

      <SettingsScreen
        signOut={signOut}
        showSettings={showSettings}
        toggleModal={toggleModal}
        setShowSettings={setShowSettings}
      />
    </React.Fragment>
  );
}

export default SettingsButton;