import React, { useEffect, useRef, useState } from "react";
import { useLazyQuery } from "@apollo/react-hooks";
import { GET_ORDER_BY_ID, GET_USER } from "../../components/GraphQL";
import {
  Dimmer,
  Segment,
  Header,
  Container,
  Grid,
  Button,
  Label,
  Divider,
} from "semantic-ui-react";
import { store } from "react-notifications-component";
import { Rating } from "semantic-ui-react";
import { useWindowSize } from "../../components/Helpers";
import { NavLink } from "react-router-dom";
import moment from "moment";
import ProgressLoading from "../../components/Loading/ProgressLoading";
import {useTranslation} from 'react-i18next';
import { delivery, pickUp } from '../../constants/serviceType';
import NP from 'number-precision';
import PrintOrder from '../../components/PrintOrderView';

const colorPallate = [
  "orange",
  "yellow",
  "olive",
  "green",
  "teal",
  "blue",
  "violet",
  "purple",
  "pink",
  "brown",
  "grey",
  "black",
];

const OrderDetails = ({ match }) => {
  const orderID = match.params["orderID"];
  const [screenWidth] = useWindowSize();
  const [getOrderByID, { loading, data, error }] = useLazyQuery(
    GET_ORDER_BY_ID
  );
  const [getUserInfoFireBase, userRecord] = useLazyQuery(GET_USER);
  const [cartTotal, setCartTotal] = useState('');
  const calculateCartTotal = (order) => {
    let sum = 0;
    order.items.forEach((x) => {
      sum = NP.plus(sum, NP.times(parseFloat(x.quantity), parseFloat(x.price)));
    });
    setCartTotal(`${sum.toFixed(3)}`);
  };

  const userData = userRecord.data;
  const userDataLoading = userRecord.loading;
  const colorArray = useRef([]);

  useEffect(() => {
    if (orderID) {
      getOrderByID({
        variables: { orderID: orderID },
      });
    }
  }, [getOrderByID, orderID]);

  //get Customer Details
  useEffect(() => {
    if (data && data.getOrderByID) {
      const { customerId } = data.getOrderByID;
      getUserInfoFireBase({
        variables: { userId: customerId },
      });
      if (data.getOrderByID) {
        calculateCartTotal(data.getOrderByID);
      }
     
    }
  }, [data, getUserInfoFireBase]);
  //Process Error
  useEffect(() => {
    if (error) {
      store.addNotification({
        title: "error",
        message: `${error}`,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
        },
      });
    }
  }, [error]);

  const {t} = useTranslation(['ordersInfo','orderDetails', 'common', 'paymentMethods', 'orderStatus']);

  if (loading) {
    return (
      <Dimmer active inverted>
        <ProgressLoading />
      </Dimmer>
    );
  }
  if (data && data.getOrderByID) {
    const {
      orderNumber,
      orderRating,
      orderStatus,
      paymentMethod,
      total,
      timeStamp,
      timeStampEta,
      orderNotes,
      items,
      itemsCoupons,
      orderType,
      customerDeliveryCharge,
      additionalPricingInfo,
      deliveryOrderID,
      promoCode,
      promoInfo,
      walletdec,
      deliveryVendor
    } = data.getOrderByID;

    return (
      <Container fluid style={{ marginTop: "2em" }}>
      <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
        <Button secondary as={NavLink} to="/orders" exact>
          {t("common:back")}
        </Button>
          <PrintOrder order={data.getOrderByID} />
          </div>
        <Header as="h4" dividing>
          {t("common:order")} - {orderNumber}
        </Header>
        <CustomerSegment
          data={userData}
          loading={userDataLoading}
          order={data.getOrderByID}
        />
        <Segment>
          <Label attached="top" size="large">
            {t("overview")}
          </Label>
          <Grid columns={screenWidth > 800 ? 4 : 2} celled doubling padded>
            <Grid.Row>
              <Grid.Column>
                <b>{t("orderType")} - </b>
                {orderType}
              </Grid.Column>
              <Grid.Column>
                <b>{t("orderRating")} -</b>
                <Rating
                  icon="star"
                  rating={orderRating}
                  maxRating={5}
                  disabled
                />
              </Grid.Column>
              <Grid.Column>
                <b>{t("common:status")} - </b>
                {t(`orderStatus:${orderStatus}`)}
              </Grid.Column>
              <Grid.Column>
                <b>{t("common:paymentMethod")} - </b>
                {t(`paymentMethods:${paymentMethod}`)}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
            {promoCode &&(
                  <Grid.Column>
                    <b>{promoInfo.isCashback ? t("orderDetails:cashback") : t("orderDetails:promoCode")} ({promoCode})- </b>
                     {promoInfo.value} KD
                  </Grid.Column>
                )}
              {cartTotal ? <Grid.Column>
                <b>{t("orderDetails:cartTotal")} - </b>
                {cartTotal} KD
              </Grid.Column>: null}
              {walletdec &&(
                  <Grid.Column>
                    <b>{t("common:walletdec")} - </b>
                     {walletdec} KD
                  </Grid.Column>
                )}
              <Grid.Column>
                <b>{t("common:total")} - </b>
                {total} KD
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              {moment(new Date(parseInt(timeStamp, 10))).isValid() ? <Grid.Column>
                <b>{t("common:time")} -</b>{" "}
                {moment(new Date(parseInt(timeStamp, 10))).format(
                  "DD/MM/YY hh:mm:ss A"
                )}
              </Grid.Column>: null}
              {moment(new Date(parseInt(timeStampEta, 10))).isValid() ?
              <Grid.Column>
                <b>{t("common:eta")} -</b>{" "}
                {moment(new Date(parseInt(timeStampEta, 10))).format("DD/MM/YY hh:mm:ss A")}
              </Grid.Column> : null}
                {orderNotes ?<Grid.Column>
                <b>{t("common:notes")} - </b>
                  orderNotes 
                </Grid.Column> : null}
            </Grid.Row>
            <Grid.Row>
            {orderType === delivery && deliveryOrderID &&
                (
                  <Grid.Column>
                    <b>{t("common:deliveryID")} - </b>
                    {deliveryOrderID}
                  </Grid.Column>
                )}
            {orderType === delivery &&
                (parseFloat(additionalPricingInfo?.totalAddition || 0).toFixed(3) > 0 ? (
                  <Grid.Column>
                    <b>{t("deliveryCharge")} - </b>
                    {parseFloat(additionalPricingInfo?.totalAddition || 0).toFixed(3)} KD
                  </Grid.Column>
                ) : (
                  <Grid.Column>
                    <b>{t("freeDelivery")} - </b>
                  </Grid.Column>
                ))}
            </Grid.Row>
            <Grid.Row>
            <Grid.Column>
              <b>{t("common:deliveryVendor")} - </b>
              {deliveryVendor}
            </Grid.Column>
          </Grid.Row>
          </Grid>
        </Segment>
        <Segment>
          <Label attached="top" size="large">
            {t("orderedItems")}
          </Label>
          <Grid columns={screenWidth > 800 ? 3 : 1} relaxed>
            {itemsCoupons && itemsCoupons.map((itemsCoupon, index) => {
              const { name, description, price, quantity } = itemsCoupon;
              if (!colorArray.current[index]) {
                colorArray.current[index] =
                  colorPallate[Math.floor(Math.random() * colorPallate.length)];
              }

              return (
                <Grid.Column key={index}>
                  <Segment raised>
                    <Label
                      attached="top"
                      size="large"
                      color={colorArray.current[index]}
                    >
                      {name}
                    </Label>
                    <Container>
                      <div>
                        <b>{t("common:price")} - </b>
                        {price} KD
                      </div>
                      <div>
                        <b>{t("common:qty")} - </b>
                        {quantity}
                      </div>
                      <div>
                        <b>{t("common:description")} - </b>
                        {description}
                      </div>
                    </Container>
                    <Divider />
                  </Segment>
                </Grid.Column>
              );
            })}
            
            {items.map((item, index) => {
              const { name, options, price, quantity } = item;
              if (!colorArray.current[index]) {
                colorArray.current[index] =
                  colorPallate[Math.floor(Math.random() * colorPallate.length)];
              }

              return (
                <Grid.Column key={index}>
                  <Segment raised>
                    <Label
                      attached="top"
                      size="large"
                      color={colorArray.current[index]}
                    >
                      {name}
                    </Label>
                    <Container>
                      <div>
                        <b>{t("common:price")} - </b>
                        {quantity > 1 ? 
                          <span>{(price * quantity).toFixed(3) } KD <small> ({price} KD)</small></span> : 
                          <span>{price} KD</span>}
                      </div>
                      <div>
                        <b>{t("common:qty")} - </b>
                        {quantity}
                      </div>
                    </Container>
                    <Divider />
                    <Container>
                      <Grid columns={1}>
                        {options.map((option, index) => {
                          return <ItemOptions options={option} key={index} />;
                        })}
                      </Grid>
                    </Container>
                  </Segment>
                </Grid.Column>
              );
            })}
          </Grid>
        </Segment>
      </Container>
    );
  } else {
    return null;
  }
};

export default OrderDetails;

const ItemOptions = ({ options }) => {
  if (options) {
    const { category, optionsList } = options;
    return (
      <Grid.Column key={category}>
        <Segment>
          <Label attached="top" size="medium">
            {category}
          </Label>
          <Divider />
          <Container>
            <Segment.Group piled>
              {optionsList.map((optionsList, index) => {
                return (
                  <ItemOptionsList optionsList={optionsList} key={index} />
                );
              })}
            </Segment.Group>
          </Container>
        </Segment>
      </Grid.Column>
    );
  } else {
    return null;
  }
};

const ItemOptionsList = ({ optionsList }) => {
  if (optionsList) {
    const { name, price } = optionsList;
    return (
      <Segment>
        <Label attached="top" size="medium">
          {name}
        </Label>
        <Container text>{price} KD</Container>
      </Segment>
    );
  } else {
    return null;
  }
};

const CustomerSegment = ({ data,order, loading }) => {
  const {t} = useTranslation(['common','ordersInfo']);
  const createUserAddress = () => {
    const address = order.userAddress;
    const addressType = order.userAddress.addressType.toLowerCase();
    const addressInfo = {
      house: `${address.area}, Block: ${address.block}, Street: ${address.street}, House No.: ${address.houseNo}`,
      apartment: `${address.area}, Block: ${address.block}, Street: ${address.street}, Build: ${address.building}\nFloor: ${address.floor}, Apartment No.: ${address.apartmentNo}`,
      office: `${address.area}, Block: ${address.block}, Street: ${address.street}, Build: ${address.building}\nFloor: ${address.floor}, Office: ${address.office}`,
    };
    return (
      <Grid.Column>
        <b>{t('address')} - </b>
        {addressInfo[addressType]}
      </Grid.Column>
    );
  };
  return (
    <Segment loading={loading}>
      <Label attached="top" size="large">
        {t('orderDetails:customerDetails')}
      </Label>
      <Grid columns={2} celled doubling padded>
        <Grid.Column>
          <b>{t('name')} - </b>
          {data && data.getUserInfoFireBase
            ? data.getUserInfoFireBase.name
            : ""}
        </Grid.Column>
        <Grid.Column>
          <b>{t('phone')} - </b>
          {data && data.getUserInfoFireBase
            ? data.getUserInfoFireBase.phoneNumber
            : ""}
        </Grid.Column>
        {!order.userAddress || order.orderType === pickUp ? "":createUserAddress()}
      </Grid>
    </Segment>
  );
};
