export const TASK_STATUS_COLOR_MAP = {
  pending: {
    color: "orange",
    label: "Pending",
  },
  requested: {
    color: "blue",
    label: "Requested",
  },
  accepted: {
    color: "green",
    label: "Accepted",
  },
  pickedup: {
    color: "purple",
    label: "Picked Up",
  },
  enroute: {
    color: "teal",
    label: "En Route",
  },
  delivered: {
    color: "black",
    label: "Delivered",
  },
  cancelled: {
    color: "black",
    label: "Cancelled",
  },
  declined: {
    color: "black",
    label: "Declined",
  },
  completed: {
    color: "black",
    label: "Completed",
  },
};
