import i18next from "i18next";
import { getSelectedLang } from "../../components/Helpers";
import ar from "./ar";
import en from "./en";

i18next.init({
  fallbackLng: "en",
  lng: getSelectedLang(),
  resources: {
    ar,
    en,
  },
  interpolation: { escapeValue: false },
});


i18next.on('languageChanged', () => {
  const html = document.querySelector('html');
  html.dir = i18next.dir();
  html.lang = i18next.language;
});


export default i18next;
