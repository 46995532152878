/**
 * Converts a duration given in seconds to a more human-readable format.
 * - If the duration is 1 hour or more, it is displayed in hours (rounded up to 1 decimal place).
 * - If the duration is 1 minute or more, it is displayed in minutes (rounded up to the nearest minute).
 * - If the duration is less than 1 minute, it is displayed in seconds.
 *
 * @param {number} seconds - The duration in seconds.
 * @returns {string} - The formatted duration as a string (e.g., "1.4 hr(s)", "8 min(s)", "45 sec(s)").
 */
export const convertSeconds = (seconds) => {
  if (seconds >= 3600) {
    const hours = Math.ceil((seconds / 3600) * 10) / 10;
    return `${hours.toFixed(1)} hr(s)`;
  } else if (seconds >= 60) {
    const minutes = Math.ceil(seconds / 60);
    return `${minutes} min(s)`;
  } else {
    return `${seconds} sec(s)`;
  }
};

/**
 * Calculates the travel distance by converting the total distance from meters to kilometers
 * and rounding up to one decimal place.
 *
 * @param {number|string} totalDistance - The total distance in meters, which can be provided as a number or string.
 * @returns {number} - The distance converted to kilometers, rounded up to one decimal place.
 */
export const calculateTravelDistance = (totalDistance) => {
  return Math.ceil((parseFloat(totalDistance).toFixed(2) / 1000) * 10) / 10;
};
