import React, { useState, useEffect } from 'react';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps';

const KuwaitCenter = { lat: 29.3759, lng: 47.9774 }; // Kuwait's coordinates

const GoogleMapPicker = ({
  onLocationUpdate,
  initialLocation,
  streetViewControl=false,
  mapTypeControl=false,
}) => {
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [mapCenter, setMapCenter] = useState(KuwaitCenter);

  useEffect(() => {
    setMapCenter({
      lat: 1 * initialLocation.latitude, lng: 1 * initialLocation.longitude
    });
  }, [initialLocation])
  const handleMapClick = (event) => {
    const { latLng } = event;
    const lat = latLng.lat();
    const lng = latLng.lng();
    setSelectedLocation({ lat, lng });
    if (onLocationUpdate) {
      onLocationUpdate({ latitude: lat, longitude: lng });
    }
  };

  return (
    <GoogleMap
      defaultZoom={14}
      defaultCenter={KuwaitCenter}
      center={mapCenter}
      onClick={handleMapClick}
      options={{
        streetViewControl: streetViewControl,
        mapTypeControl: mapTypeControl,
      }}
      // onCenterChanged={handleMapCenterChanged}
    >
      {initialLocation && <Marker position={{lat: 1 * initialLocation.latitude, lng: 1 * initialLocation.longitude}} />}
    </GoogleMap>
  );
};

export default withScriptjs(withGoogleMap(GoogleMapPicker));
